.wall-image {

  padding: 20px 0 20px 0;

  .swiper {
    width: 100%;
    height: calc(100svh - var(--nav-height, 0));
  }

  .overlay {
    position: fixed;
    width: 100%;
    height: 100svh;
    top: 0;
    left: 0;
  }

  .paired-image {
    width: 100%;
    height: 100%;
    border-radius: $border-radius;
    cursor: zoom-in;
    object-fit: cover;
  }
  .single-image {
    width: 100%;
    height: 100%;
    border-radius: $border-radius;
    cursor: zoom-in;
    object-fit: cover;
  }

  .image-container {
    overflow: hidden;
    border-radius: $border-radius;
    img {
      transition: all 60ms linear;
    }
  }

  img.single-image:hover,img.paired-image:hover {
    transform: scale(1.2);
  }

  .grid {
    width: 100%;
    max-width: 1110px;
    display: grid;
    grid-template-columns: 2.13fr 1fr;
    grid-template-rows: 1fr;
    grid-auto-flow: dense;
    gap: 20px;
    margin: auto;
    padding: 0;

    &--inner {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-rows: 1fr 1fr;
      gap: 20px;
    }
  }
  .rtl {
    direction: rtl;
  }
  @media screen and (max-width: 640px) {
    .grid {
      display: flex;
      flex-direction: column;
      padding: 0 20px 0 20px;

      &--inner {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 20px;
      }
    }
    .image-container {
      width: 100%;
      aspect-ratio: 3 / 4;
      position: relative;
      img {
        aspect-ratio: 3 / 4;
      }
    }
  }
  .bottom-grid {
    margin-top: 20px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    background-color: $color-primary-alt;
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    position: unset;
  }
  .swiper-button-prev i {
    font-size: 20px;
    margin: 2px 2.5px 0 0;
    color: white;
  }
  .swiper-button-next i {
    font-size: 20px;
    margin: 2px 0 0 4px;
    color: white;
  }

  .swiper-button-close {
    background-color: $color-primary-alt;
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    i {
      font-size: 20px;
      margin: 2px 0 0 -2px;
      color: white;
      transform: rotate(45deg);
    }
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    content: unset;
  }

  .gallery-controls {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .swiper-slide {
    width: 100%;
    height: 100%;
    overflow: hidden;
    .text-box {
      width: 436px;
    }
    @media screen and (max-width: 640px) {
      .text-box {
        width: 331px;
      }
    }
  }

  .swiper-pagination {
    transform: translateY(50px);
    text-align: center;
    position: absolute;
    bottom: 80px;
  }
  .swiper-pagination-bullet {
    background-color: white;
    opacity: 1;
    margin: 0 2px;
    width: 10px;
    height: 10px;
    &-active {
      background-color: $color-primary-alt;
    }
  }

  &-button-close {
    background-color: $color-primary-alt;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    font-size: 30px;
    cursor: pointer;
    border-radius: 100%;
    &__icon {
      color: #fff;
    }
  }

  .mobile-show-icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    height: 44px;
    width: 44px;
    padding: 10px;
    border-radius: 14px;
    background-color: rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      font-size: 20px;
      margin: 3px 1px 0 0;
      color: white;
    }
  }

  .title-and-description-container {
    position: absolute;
    bottom: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    z-index: 1000;
    .title {
      color: white;
      width: 436px;
      text-align: center;
      font-family: $font-heading-s-font-family;
      font-size: $font-heading-s-font-size;
      line-height: $font-heading-s-line-height;
      font-weight: $font-heading-s-font-weight;
      font-style: $font-heading-s-font-style;
    }
    .description {
      color: white;
      width: 436px;
      text-align: center;
      font-family: $font-body-s-font-family;
      font-size: $font-body-s-font-size;
      line-height: $font-body-s-line-height;
      font-weight: $font-body-s-font-weight;
      font-style: $font-body-s-font-style;
    }
    @media screen and (max-width: 640px) {
      .title {
        width: 80%;
      }
      .description {
        width: 80%;
      }
    }
  }

  .image-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .linear-shadow-mask {
    position: absolute;
    width: 100%;
    height: 110%;
    z-index: 999;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0.6) 60%, #000 100%);
  }

  .wi-swiper-hidden {
    z-index: 0;
    display: none;
  }
  .wi-swiper-show {
    z-index: 1000;
    display: block;
  }

}
