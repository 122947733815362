.section__container {
  &:not(.section__container--full) {
    padding: 0 !important;
  }
}

.card-promotion {
  border-radius: $card-promotion-border-radius;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  display: block;
  text-decoration: none;
  cursor: pointer;
  width: 100%;

  &.is-not-selected {
    border: rem(2) solid transparent;
  }

  &.is-selected {
    border: rem(2) solid $form-selected-true-color !important;
  }

  .card-promotion__img {
    position: relative;
    background-color: $color-surface;
    width: 100%;
    padding-bottom: 75%; // 4:3

    .image {
      position: absolute;
      object-fit: cover;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }

  .card-promotion__content {
    background-color: $card-promotion-content-bg;
    color: $card-promotion-content-color;
    min-height: rem(100);
    height: rem(120);
    @include  breakpoint(large) {
      height: auto;
    }

    > div {
      padding: rem(8) rem(10);
      position: relative;
    }
  }

  .card-promotion__duration {
    @include font-body-xs;
    text-align: $card-promotion-duration-text-align;
  }

  .card-promotion__title {
    @include font-heading-s;
    text-align: $card-promotion-title-text-align;
    font-size: rem(15) !important;
    line-height: rem(16) !important;
    width: 90%;
  }

  .card-promotion__text {
    @include font-body-s;
    display: none;
  }

  .card-promotion__cta {
    @include font-button-text-m;
    display: none;
  }

  .icon-plus {
    background-color: $card-promotion-read-more-bg;
    color: $card-promotion-read-more-color;
    position: absolute;
    padding: $card-promotion-read-more-padding;
    bottom: $card-promotion-read-more-bottom;
    right: $card-promotion-read-more-right;
    font-size: $card-promotion-read-more-font-size;
    line-height: 0;
  }

  &.card-promotion--smh {
    @include breakpoint(large) {
      display: flex;
      width: rem(333);
      align-items: stretch;

      .card-promotion__img {
        @include breakpoint(large) {
          width: rem(233);
        }
        padding-bottom: 36%; // 233:120
        picture {
          height: rem(360);
          display: block;
          @include breakpoint(large) {
            height: auto
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            @include breakpoint(large) {
              height: auto;
              width: auto;
              object-fit: unset;
            }
          }
        }
      }

      .card-promotion__content {
        width: 30%;
        align-items: center;
        display: flex;
      }

      .card-promotion__title {
        @include font-heading-xs;
      }
    }
  }

  @include breakpoint(medium) {
    &.card-promotion--lg {
      display: flex;

      .card-promotion__img {
        @include xy-cell(8, $gutters: 0, $gutter-type: padding);
        img {
          width: auto;
        }

        @if $card-promotion-img-after {
          &:after {
            content: "";
            width: 20%;
            height: 105%;
            display: block;
            position: absolute;
            top: 0;
            background: $color-secondary;
            right: -130px;
            transform: rotate(7deg);
          }
        }
      }

      .card-promotion__img {
        padding-bottom: 28.6%;
      }


      .card-promotion__content {
        @include xy-cell(4, $gutters: 0, $gutter-type: padding);

        & > div {
          padding: rem(12) rem(54) rem(15) rem(15);
          @include breakpoint (large) {
            padding: rem(20);
          }
          height: 100%;
        }
      }

      .card-promotion__title {
        @include font-heading-m;
        margin-bottom: rem(20);
        margin-top: rem(20);
      }

      .card-promotion__text {
        display: block;
        line-height: 1.5;
      }

      .card-promotion__cta {
        @if $card-promotion-cta {
          display: block;
          text-transform: uppercase;
          position: absolute;
          bottom: rem(20);
        }
      }

      &.card-promotion-aftersales{
        .card-promotion__img {
          @if $card-promotion-img-after {
            &:after {
              background: $card-promotion-content-bg-aftersale;
            }
          }
        }
      }
    }
    
  }

  .custom-control {
    position: absolute;
    top: $card-product-form-control-position-top;
    right: $card-product-form-control-position-right;
    z-index: 10;
  }

  &.card-promotion-aftersales{
    .card-promotion__content {
      background-color: $card-promotion-content-bg-aftersale;
      color: $card-promotion-content-color-aftersale;
      border: 1px solid $card-promotion-content-border-aftersale;
    }
  }
}

.promo-alert__link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  i {
    font-size: rem(25);
    margin-right: rem(10);
  }
}