.hero-heading-community {
  &__container {
    position:relative;
    width: 100%;
    height: 100%;
  }

  .back-button {
    position: absolute;
    left: rem(15);
    top: rem(25);
    z-index: 101;
    @include breakpoint(medium) {
      left: rem(40);
    }
    &.hero-active {
      top: rem(80);
    }
    .back-button-link {
      text-decoration: none;
      text-transform: uppercase;
      color: $text-color-white;
      @include font-button-text-s;
    }
  }

  &__picture {
    aspect-ratio: var(--aspect-ratio);
    width: 100%;
    height: 100%;
    position: relative;
    display: block;
    overflow: hidden;

    &.is-overlay::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      z-index: 10;
      display: block;

    }

    img {
      object-fit: cover;
      @include breakpoint(medium) {
        width: 100%;
        min-height: 100%;
      }
    }
  }

  &__section-logo {
    position: relative;
    z-index: 100;
    width: 100%;
    top: 0;
    left: 0;
    background-color: $hero-heading-community-background-color;

    .container-section-logo {
      display: flex;
      flex-direction: column;
      padding: rem(30) 0;
      text-align: center;
      width: 90%;
      margin: auto;

      .logo-section-img {
        width: auto;
        height: var(--logo-height);
      }
      .hero-heading-title {
        font-family: $font-heading;
        font-size: rem(24);
        color: $text-color-white;
        margin-top: rem(15);
      }
    }
  }

  &__info-container {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 100;
    text-align: left;
    &.align-center {
      text-align: center;
      .hero-heading-dates {
        margin: 0 auto;
      }
      .hero-heading-title {
        &.title-only {
          @include breakpoint(medium) {
            margin: auto;
          }
        }
      }
    }

    .hero-heading-info-container-flex {
      margin: 0 rem(15) rem(40);
      display: flex;
      flex-direction: column;
      @include breakpoint(medium) {
        margin: 0 rem(40) rem(40) var(--margin-left);
        flex-direction: row;
      }

      .hero-heading-info {
        order: 2;
        @include breakpoint(medium) {
          order: 1;
          width: 85%;
        }
        &.flex {
          @include breakpoint(medium) {
            display: flex;
          }
        }
        &.no-dates {
          @include breakpoint(medium) {
            width: 100%;
          }
        }
        .hero-heading-title {
          margin-bottom: rem(16);
          h1 {
            @include font-heading-l;
            color: $text-color-white;
          }
          h3 {
            @include font-heading-l;
            color: $text-color-white;
          }
        }
        .hero-heading-community-ctas-links {
          width: 100%;
          .hero-heading-cta-link {
            width: 100%;
            margin-bottom: rem(16);
            text-align: center;
            display: block;
            @include breakpoint(medium) {
              margin: 0 rem(32) 0 0;
              display: inline-block;
              width: fit-content;
            }
          }
        }
      }

      .hero-heading-dates {
        display: flex;
        margin-bottom: rem(16);
        order: 1;
        @include breakpoint(medium) {
          order: 2;
          width: fit-content;
          margin: auto 0 0 auto;
        }

        .date-separator {
          content: "";
          width: 12px;
          position: absolute;
          left: calc(100% + 14px);
          bottom: 50%;
          height: 1px;
          background-color: $white;
          transform: translateX(-50%);
        }

        .start-date {
          position: relative;
          margin-right: rem(28);

          &.separator::after {
            content: "";
            position: absolute;
            width: rem(12);
            height: rem(1);
            left: calc(100% + 14px);
            bottom: 50%;
            transform: translateX(-50%);
            background-color: $white;
          }
        }

        .start-date, .end-date {
          display: flex;
          flex-direction: column;
          width: rem(54);
          height: rem(54);
          background-color: $white;
          text-align: center;
          border: 1px solid $color-primary;
          @include breakpoint(medium) {
            width: rem(108);
            height: rem(108)
          }

          .container-start-date, .container-end-date {
            font-family: $font-body;
            font-weight: 700;
            font-style: normal;
            display: flex;
            flex-direction: column;
            margin: auto 0;

            .date-field-day {
              font-size: rem(28);
              line-height: 1.2;

              @include breakpoint(medium) {
                font-size: rem(44);
              }
            }
            .date-field-month {
              font-size: rem(13);
              color: $color-primary;
              font-weight: 700;
              text-transform: uppercase;
              margin-bottom: rem(5);

              @include breakpoint(medium) {
                font-size: rem(18);
              }
            }
          }
        }
      }
    }
  }

  &.no-media {
    aspect-ratio: unset;
      .back-button {
        position: relative;
        margin-bottom: rem(30);
      }
    .hero-heading-community__info-container {
      width: 100%;
      position: relative;
      text-align: left;
      &.align-center {
        text-align: center;
        .hero-heading-title {
          &.title-only {
            @include breakpoint(medium) {
              margin: auto;
            }
          }
        }
      }

      .hero-heading-info-container-flex {
        margin: 0 rem(15) rem(40);
        display: flex;
        flex-direction: column;
        @include breakpoint(medium) {
          margin: 0 rem(40) rem(40) var(--margin-left);
          flex-direction: row;
        }

        .hero-heading-info {
          order: 2;
          @include breakpoint(medium) {
            order: 1;
            width: 85%;
          }
          &.flex {
            @include breakpoint(medium) {
              display: flex;
            }
          }
          &.no-dates {
            @include breakpoint(medium) {
              width: 100%;
            }
          }
          .hero-heading-title {
            font-family: $font-heading;
            font-size: rem(24);
            color: $text-color-white;
            margin-top: rem(15);
            h1 {
              @include font-heading-l;
              color: $text-color-white;
            }
            h3 {
              @include font-heading-l;
              color: $text-color-white;
            }
          }
        }
      }
    }
  }
}