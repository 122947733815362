/**
 * Design System for Piaggio Group
*/

.button {
  background-color: transparent;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &[href] {
    text-decoration: none;
  }

  &.button--block {
    //display: block !important;
    width: 100% !important;
    margin-right: 0 !important;
  }

  &.align-center {
    margin-left: auto !important;
    margin-right: auto !important;
    display: block;
  }

  &.button--text,
  &.button--text-white {
    &.padding-left-right-0 {
      /*@include breakpoint(large) {
        margin-left: -$button-m-padding-v !important;
        margin-right: -$button-m-padding-v !important;
      }*/
    }

    &.button--small {
      @include font-button-text-s;
    }

    &.button--medium {
      @include font-button-text-m;
    }

    &.button--large {
      @include font-button-text-l;
    }

    @if $button-text-underline {
      position: relative;

      &::after {
        content: "";
        height: 0.1rem;
        position: absolute;
        bottom: 10px;
        transition: all 0.5s ease-in-out;
        background-color: currentColor;
      }

      &:hover,
      &:focus {
        &::after {
          left: 0 !important;
          right: 0 !important;
        }
      }
    }
  }

  &.button--outline,
  &.button--outline-white,
  &.button--contained-secondary,
  &.button--contained {
    &.button--medium {
      @include font-button-m;
    }

    &.button--large {
      @include font-button-l;
    }
  }

  &[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }

  &.is-loading {
    position: relative;

    &::before {
      border-top: 1.1em solid rgba($color-on-primary, 0.2);
      border-right: 1.1em solid rgba($color-on-primary, 0.2);
      border-bottom: 1.1em solid rgba($color-on-primary, 0.2);
      border-left: 1.1em solid $color-on-primary;
      content: "";
      border-radius: 50%;
      position: relative;
      text-indent: -9999em;

      transform: translateZ(0), translateY(-50%), translateX(-50%);
      animation: loading 1.1s infinite linear;
      position: absolute;
      width: 3rem;
      height: 3rem;
      font-size: 4px;
    }
  }

  &.button--outline,
  &.button--outline-white {
    border: 1px solid transparent;
  }

  &.button--text,
  &.button--text-white,
  &.button--outline,
  &.button--outline-white,
  &.button--contained,
  &.button--contained-secondary {

    &.button--small {
      border-radius: $button-m-radius;
      min-height: $button-m-height;
      padding: $button-s-padding-v $button-s-padding-h;
      text-transform: $button-m-text-transform;
      margin: 0 $button-m-margin $button-m-margin 0;
    }

    &.button--medium {
      border-radius: $button-m-radius;
      min-height: $button-m-height;
      padding: $button-m-padding-v $button-m-padding-h;
      text-transform: $button-m-text-transform;
      margin: 0 $button-m-margin $button-m-margin 0;
    }

    &.button--large {
      border-radius: $button-m-radius;
      min-height: $button-l-height;
      padding: $button-l-padding-v $button-l-padding-h;
      text-transform: $button-l-text-transform;
      margin: 0 $button-l-margin $button-l-margin 0;
    }

    &:only-child,
    &.margin-0 {
      margin: 0;
      height: auto;
    }

    i[class^="icon-"],
    i[class*=" icon-"] {
      font-size: 2em;
      //margin-left: rem(25);
    }
  }

  &.button--text {
    &.button--text-white {
      &.button--medium {
        padding: $button-m-padding-v 0;
      }
    }
  }

  &.button--text,
  &.button--text-white {
    padding: 0 !important;
    &.button--small {
      @if $button-text-underline {
        &::after {
          left: $button-s-padding-h;
          right: $button-s-padding-h;
        }
      }
    }

    &.button--medium {
      @if $button-text-underline {
        &::after {
          left: 0;
          right: 0;
        }
      }
    }

    &.button--large {
      @if $button-text-underline {
        &::after {
          left: $button-l-padding-h;
          right: $button-l-padding-h;
        }
      }
    }
  }

  ////////////////////////////////////////
  // Button primary
  &.button--primary {
    &.button--text {
      color: $button-primary-color;

      @if $button-text-underline {
        &::after {
          background-color: $button-primary-color;
        }
      }

      &.is-loading {
        &::before {
          border-top-color: rgba($font-button-text-color, 0.2);
          border-right-color: rgba($font-button-text-color, 0.2);
          border-bottom-color: rgba($font-button-text-color, 0.2);
          border-left-color: $font-button-text-color;
        }
      }

      &.secondary-bg {
        color: $font-button-text-color-section-secondary;

        &:after {
          background-color: $font-button-text-color-section-secondary;
        }
      }
    }

    &.button--text-white {
      color: $white;

      @if $button-text-underline {
        &::after {
          background-color: $white;
        }
      }

      &.is-loading {
        &::before {
          border-top-color: rgba($white, 0.2);
          border-right-color: rgba($white, 0.2);
          border-bottom-color: rgba($white, 0.2);
          border-left-color: $white;
        }
      }

      &.secondary-bg {
        color: $white;

        &:after {
          background-color: $white;
        }
      }
    }

    &.button--contained {
      background-color: $button-primary-color;
      color: $button-primary-text-color;
    }

    &.button--contained-secondary {
      background-color: $secondary-button-color;
      opacity: $secondary-opacity;
      color: $button-primary-color;

      &.is-loading {
        &::before {
          border-top-color: rgba($button-primary-color, 0.2);
          border-right-color: rgba($button-primary-color, 0.2);
          border-bottom-color: rgba($button-primary-color, 0.2);
          border-left-color: rgba($button-primary-color, 0.2);
        }
      }
    }

    &.button--go-to-top {
      position: fixed;
      text-transform: uppercase;
      bottom: 60px;
      right: 0;
      border-radius: $button-go-to-top-radius;
      z-index: 99;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 6px;
      margin-right: 0;
      transition: right;
      transition-duration: 1.5s;
      &.hidden {
        right: -500px;
      }

      @include breakpoint(medium down) {
        padding-right: 0;
      }

      .icon-chevron-up {
        position: relative;
        font-size: rem(14);
        padding: rem(12);
        margin-right: rem(12);

        //hack to make this icon look like centered... icon should be changed
        &:after {
          content: "";
          background-color: darken($color-primary, 15%);
          border-radius: 50%;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 1px;
          left: -1px;
          z-index: -1;
        }
      }

      span {
        @include breakpoint(medium down) {
          position: absolute !important;
          width: 1px !important;
          height: 1px !important;
          padding: 0 !important;
          overflow: hidden !important;
          clip: rect(0, 0, 0, 0) !important;
          white-space: nowrap !important;
          border: 0 !important;
        }
      }
    }

    &.button--outline {
      border-color: $button-outline-border-color;
      color: $button-outline-text-color;

      &.secondary-bg {
        color: $font-button-text-color-section-secondary;
        border-color: $font-button-text-color-section-secondary;
      }

      &.is-loading {
        &::before {
          border-top-color: rgba($button-outline-border-color, 0.2);
          border-right-color: rgba($button-outline-border-color, 0.2);
          border-bottom-color: rgba($button-outline-border-color, 0.2);
          border-left-color: $button-outline-border-color;
        }
      }
    }

    &.button--outline-white {
      border-color: $white;
      color: $white;

      &.is-loading {
        &::before {
          border-top-color: rgba($white, 0.2);
          border-right-color: rgba($white, 0.2);
          border-bottom-color: rgba($white, 0.2);
          border-left-color: $white;
        }
      }
    }
  }

  ////////////////////////////////////////
  // Button secondary
  &.button--secondary {
    &.button--text {
      color: $button-primary-color;

      @if $button-text-underline {
        &::after {
          background-color: $button-secondary-color;
        }
      }

      &.is-loading {
        &::before {
          border-top-color: rgba($button-secondary-color, 0.2);
          border-right-color: rgba($button-secondary-color, 0.2);
          border-bottom-color: rgba($button-secondary-color, 0.2);
          border-left-color: $button-secondary-color;
        }
      }
    }

    &.button--text-white {
      color: $white;

      @if $button-text-underline {
        &::after {
          background-color: $white;
        }
      }

      &.is-loading {
        &::before {
          border-top-color: rgba($white, 0.2);
          border-right-color: rgba($white, 0.2);
          border-bottom-color: rgba($white, 0.2);
          border-left-color: $white;
        }
      }
    }

    &.button--contained {
      background-color: $button-secondary-color;
      color: $button-secondary-text-color;

      &.is-loading {
        &::before {
          border-top-color: rgba($button-secondary-text-color, 0.2);
          border-right-color: rgba($button-secondary-text-color, 0.2);
          border-bottom-color: rgba($button-secondary-text-color, 0.2);
          border-left-color: $button-secondary-text-color;
        }
      }
    }

    &.button--contained-secondary {
      background-color: $secondary-button-color;
      opacity: 0.9;
      color: $button-primary-color;

      &.is-loading {
        &::before {
          border-top-color: rgba($button-primary-color, 0.2);
          border-right-color: rgba($button-primary-color, 0.2);
          border-bottom-color: rgba($button-primary-color, 0.2);
          border-left-color: rgba($button-primary-color, 0.2);
        }
      }
    }

    &.button--outline {
      border-color: $button-secondary-color;
      color: $button-secondary-color;

      &.is-loading {
        &::before {
          border-top-color: rgba($button-secondary-color, 0.2);
          border-right-color: rgba($button-secondary-color, 0.2);
          border-bottom-color: rgba($button-secondary-color, 0.2);
          border-left-color: $button-secondary-color;
        }
      }
    }

    &.button--outline-white {
      border-color: $white;
      color: $white;

      &.is-loading {
        &::before {
          border-top-color: rgba($white, 0.2);
          border-right-color: rgba($white, 0.2);
          border-bottom-color: rgba($white, 0.2);
          border-left-color: $white;
        }
      }
    }
  }

  ////////////////////////////////////////
  // Button white
  &.button--white {
    &.button--text {
      color: $white;

      @if $button-text-underline {
        &::after {
          background-color: $white;
        }
      }
    }
  }

  &.button--black {
    &.button--text {
      color: $black;

      @if $button-text-underline {
        &::after {
          background-color: $black;
        }
      }
    }
  }

  ////////////////////////////////////////
  // Button icon
  &.button--icon {
    /*width: 20px;
    min-height: 20px;*/
    font-size: 1.8rem;
    text-align: center;
    line-height: 20px;
    padding: $space-size-s;

    &.button--primary {
      color: $button-primary-color;
    }

    &.button--secondary {
      color: $button-secondary-color;
    }
  }

}

//override button style for non-pgButton cta links
span {
  .button {
    &.button--text {
      &.button--medium {
        padding: $button-cta-padding-v $button-cta-padding-h !important;
      }
    }
  }
}

@-webkit-keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
