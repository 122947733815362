/**
 * Design System for Piaggio Group
*/
@import "abstracts";

// Brand specific variables
@import "tokens/tokens-aprilia";

// Vendor specific variables
@import "vendors/vendors";

@import "html"; //temporary style depending on html data

.theme-aprilia {
  @import "typography/var_icons-aprilia";
  @import "typography/santander_custom_aprilia";
  @import "main";
}
