.you-tube-live {
  width: 100%;
  &__container {
    width: 100%;
    aspect-ratio: 4 / 3;
    position: relative;
    margin: 0 auto;
    @include breakpoint(large) {
      aspect-ratio: 16 / 9;
    }
    .youtube-live-video-player {
      width: 100% !important;
    }
  }
}