.show-for-sr-visible {
  @include font-heading-l;
  text-align: center;
  margin-bottom: rem(16);
  @include breakpoint(large) {
    margin-bottom: rem(24);
  }
}
.show-for-sr-visible::first-letter {
  text-transform: uppercase;
}
.hotspot {
  @include xy-grid;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .hotspot__content {
    width: 100%;
    position: relative;

    img {
      min-width: 100%;
      height: auto;
    }

    @include breakpoint(large) {
      @include xy-cell(10);
    }
  }

  .hotspot__choose-color {
    font-size: rem(15);
    line-height: 2;
    display: flex;
    align-items: center;

    button:first-of-type {
      margin-left: rem(6);
    }
  }

  .hotspot__choose-color-img {
    border-radius: 50%;
    cursor: pointer;
    width: rem(22);
    height: rem(22);
  }

  .hotspot__button-more {
    position: absolute;
    z-index: 2;
    padding: 0;
    font-size: rem(30);
    line-height: rem(30);
    height: rem(30);
    transform: translate(-50%, -50%);


    @include breakpoint(medium) {
      font-size: rem(48);
      line-height: rem(35);
      height: auto;
    }

    .icon-plus-fill {
      z-index: 2;

      &:before {
        background: $hotspot-icon-plus-bg;
        border-radius: 50%;
        color: $hotspot-icon-plus-color;
      }
    }

    &:focus {
      outline-color: $general-outline-color-on-medium;
    }
  }

  .hotspot__button-label {
    display: none;
  }

  @include breakpoint(large) {
    .hotspot__button-label {
      display: block;
      font-size: rem(14);
      background: #FFF;
      padding: 0 rem(15) 0 rem(30);
      border-radius: 0 rem(28) rem(28) 0;
      position: absolute;
      width: auto;
      line-height: 2.8;
      white-space: nowrap;
      box-shadow: 2px 2px rgba(0, 0, 0, .3);
      transform: translate(34px, 4px);;
      left: 0;
      top: 0;
      z-index: 1;
    }
  }
}

.modal .hotspot__modal-dialog {
  .slide {
    display: flex;
    height: 100%;
    flex-direction: column;

    & > img {
      width: 100%;
    }
  }

  @include breakpoint(medium down) {
    .modal-content {
      height: 100%;
    }

    .image.modal-hero {
      position: sticky;
      top: 0;
      z-index: 2;
    }
  }

  // PIAGGIO-9042: visibility with zoom 400%
  @media screen and (max-width: 320px) {
    .image.modal-hero {
      position: relative;
    }
  }

  @media screen and #{breakpoint(medium)} and #{breakpoint(xlarge down)} {
    .modal-content {
      height: auto;
    }
  }

  .hotspot__modal-content {
    @include font-body;
    position: relative;

    a {
      color: $color-on-background;
    }
  }

  .hotspot__modal-title {
    @include font-heading-m();
    margin-bottom: $hotspot-modal-title-margin-bottom;
    margin-top: rem(12);
  }

  /*.hotspot-gallery {
    margin: rem(-24);
    padding-bottom: 4px;
    margin-bottom: 60px;
    border-bottom: 1px solid $color-gray-lighter;
  }*/

  .hooper-navigation {
    button {
      top: initial;
      bottom: -75px;
    }
  }

  .hooper-pagination {
    bottom: -60px;

    .hooper-indicators {
      margin: 12px auto;
    }
  }

  @if $hotspot-modal-custom {
    .modal-content {
      border-radius: $hotspot-modal-custom-content-border-radius;
    }

    .close {
      background-color: $hotspot-modal-custom-close-bg;
      border-radius: $hotspot-modal-custom-border-radius;
      border: $hotspot-modal-custom-close-border;
      position: $hotspot-modal-custom-close-position;
      top: $hotspot-modal-custom-close-top;
      left: $hotspot-modal-custom-close-left;
      height: $hotspot-modal-custom-close-height;
      padding: $hotspot-modal-custom-padding;
    }

    .close__icon {
      font-size: $hotspot-modal-custom-close-icon-font-size;
      transform: $hotspot-modal-custom-close-icon-transform;
      display: $hotspot-modal-custom-close-icon-display;
      color: $hotspot-modal-custom-close-color;
    }

    .close__label {
      display: $hotspot-modal-custom-close-display;
    }
  }


}


////////////////////////////////////////
// Hotspot Carousel container
.hotspot-carousel {

  .hotspot-carousel__container {
    // background-color: $color-gray-lighter;
    overflow: hidden;
    position: relative;
    padding-right: 0 !important;
    padding-left: 0 !important;

    &:not(.full-width) {
      .hooper-list {
        @include breakpoint(large) {
          border-radius: $gallery-border-radius;
          -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
        }
      }
    }
  }

  figure {
    margin: 0 auto;
    width: 100%;
  }

  img {
    width: 100%;
  }
}

.hotspot-carousel__shape-nav {
  display: none;
  border-style: solid;
  border-width: 0 30rem 13rem 0;
  border-color: transparent $color-primary transparent transparent;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  @include breakpoint(large) {
    display: $gallery-shape-nav-display;
  }
}

.hotspot-carousel {
  overflow: hidden;

  .hooper-slide {
    &[aria-hidden="true"] {
      visibility: visible;
      opacity: .5;

      .hotspot__button-more {
        visibility: hidden;
      }
    }

    .hotspot-carousel__caption {
      text-align: center;
      margin: 10px;
    }
  }

  ////////////////////////////////////////
  // Hotspot Carousel carousel nav
  .hotspot-carousel__nav-button {
    @include center(y);
    display: none;
    border: none;
    color: $gallery-nav-button-color;
    cursor: pointer;
    font-size: rem(40);
    position: absolute;
    top: $hotspot-nav-button-top;
    //top: 0;
    //height: $gallery-nav-button-height;
    transition-duration: 0.3s;
    opacity: $gallery-nav-button-opacity;
    height: auto;
    width: $gallery-nav-button-width;
    z-index: 2;

    .icon {
      color: $hotspot-nav-button-color;
      display: inline-flex;
      width: 100%;
      align-items: center;
      border-radius: $gallery-nav-button-icon-radius;
      height: $gallery-nav-button-icon-height;
    }

    @include breakpoint(large) {
      display: block;
    }
  }

  .hotspot-carousel__nav-button:hover {
    opacity: 1;
  }

  .hotspot-carousel__nav-button[disabled] {
    opacity: 0;
  }

  .hotspot-carousel__nav-button--prev {
    // left: $gallery-nav-button-prev-left;
    right: $gallery-nav-button-prev-right;
    //transform: $gallery-nav-button-prev-transform;
    left: 21%;

    .icon {
      justify-content: flex-end;
      transition-duration: 0.3s;
      padding-right: $gallery-nav-button-icon-padding-right;
    }

    &:hover {
      // transform: $gallery-nav-button-prev-hover-transform;
    }

    &:hover .icon {
      // padding-right: $gallery-nav-button-icon-padding-right-hover;
    }
  }

  .hotspot-carousel__nav-button--next {
    right: 21%;
    // transform: $gallery-nav-button-next-transform;

    .icon {
      transition-duration: 0.3s;
      padding-left: $gallery-nav-button-icon-padding-left;
    }

    &:hover {
      // transform: $gallery-nav-button-next-hover-transform;
    }

    &:hover .icon {
      // padding-left: $gallery-nav-button-icon-padding-left-hover;
    }
  }
}

