.piaggio-select-container {
  margin-bottom: 8px;
}
.piaggio-select-label {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 2px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.piaggio-select__error-icon {
  position: absolute;
  right: 14px;
  font-size: 24px;
  color: #A22028;
  margin-top: 2px; /* to align not centered image !! */
}
.piaggio-select__success-icon {
  position: absolute;
  right: 14px;
  font-size: 24px;
  margin-top: 2px; /* to align not centered image !! */
}
.piaggio-select {
  border-radius: $select-border-radius;
  border: 1px solid black !important;
  height: 44px !important;
  padding: 8px !important;
  font-size: $select-text-font-size !important;
  font-weight: $select-text-font-weight !important;
  line-height: $select-text-line-height !important;
  font-style: $select-text-font-style !important;
  caret-color: green;
  background-color: $color-background;
}
.piaggio-select-error {
  padding: 8px calc(8px + 24px + 8px) 8px 8px !important;
  border: 1px solid #A22028 !important;
}
.piaggio-select-success {
  padding: 8px calc(8px + 24px + 8px) 8px 8px !important;
}
.piaggio-select:focus-within {
  box-shadow: 0 2px $color-primary !important;
}
