.form-upload {
  .form-upload-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 2.5rem;
    text-align: center;
  }

  .vin-title {
    font-size: 34px;
    line-height: 52px;
  }

  .vin-message {
    font-size: 18px;
    line-height: 27px;
    margin: 1.5rem 0 2.5rem;
    text-align: center;
    &-bold {
      font-weight: bold;
    }
  }

  .file-upload, .file-preview, .file-preview-error {
    align-items: center;
    background-color: $white;
    display: flex;
    font-size: 18px;
    margin-bottom: 2.5rem;
  }

  .file-preview-error {
    border: 1px solid $color-primary;
    flex-direction: column;
    padding: 1.25rem;
  }

  .file-upload {
    border: 2px dashed $color-gray-darker;
    flex-direction: column;
    padding: 1.25rem 5rem;
  }

  .file-preview {
    border: 1px solid $color-gray-darker;
    padding: 1.25rem;
  }

  .file-preview-box, .file-preview-box-error {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;
    max-width: 34rem;
    min-width: 26.5rem;
    padding: 1rem;
    width: 100%;
  }

  .file-preview-box {
    background-color: $form-accordion-content-background-color;  //#F1F1F1
  }

  .file-preview-box-error {
    background-color: #A2202833;
  }

  .file-preview-error-label {
    align-items: center;
    color: $color-error;
    display: flex;
    flex-direction: row;
    font-weight: 700;
    justify-content: center;
    max-width: 34rem;
    min-width: 26.5rem;
    width: 100%;
  }

  .file-preview-divisor {
    border-top: 1px solid $color-gray-darker;
    margin: 1.25rem 0;
    width: 100%;
  }

  .file-preview-item, .file-preview-item-error {
    align-items: center;
    display: flex;
    gap: 0.5rem;
  }

  .file-preview-label {
    line-height: 20px;
    max-width: 30rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .form-upload-icon {
    line-height: inherit;
  }

  .file-icon i:before, .warning-icon i:before, .success-icon i:before {
    color: $color-primary;
  }

  .file-preview-error {
    .warning-icon i, .close-icon i {
      font-size: 3rem;
      margin: 0;
    }
  }

  .garbage-icon, .close-icon {
    cursor: pointer;
  }

  .icon-text__icon {
    line-height: 0;
    margin: 0;
  }

  .upload-divider {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 1.25rem 0;
    text-align: center;

    span {
      color: $color-gray-darker;
      font-size: 13px;
      font-weight: 400;
      min-width: 90px;
    }
  }

  .upload-divider:before, .upload-divider:after {
    background-color: $color-gray-lighter;
    content: "";
    display: inline-block;
    height: 1px;
    width: 115px;
  }

  .info-icon-container {
    align-items: center;
    display: flex;
    gap: 0.5rem;
    margin-bottom: 2.5rem;

    .form-info-icon {
      i {
        font-size: 2rem;
        margin: 0;
      }
    }

    .info-icon-label {
      font-size: 16px;
    }
  }

  .hide-file-input {
    height: 1px;
    overflow: hidden;
    position: absolute;
    width: 1px;
  }

  .file-input-label {
    background-color: $color-primary;
    border: 2px solid $color-primary;
    color: $white;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.2rem;
    padding: 1.25rem 2.25rem;
    text-transform: uppercase;
    &:hover {
      background: $white;
      color: $black;
    }
  }

  .error-message-vin {
    color: $color-error;
    font-size: 13px;
  }

  .actions-cta {
    display: flex;
    gap: 1rem;
    justify-content: center;
  }

  button {
    border: 2px solid $color-primary;
    cursor: pointer;
    @include font-button-m;
    min-width: 66px;
    padding: 1.25rem 2.25rem;
    transition: .3s;
    text-transform: uppercase;
    width: 100%;
  }

  button[disabled] {
    cursor: not-allowed;
    opacity: 0.6;
  }

  .submit-btn button {
    background: $color-primary;
    color: $white;
    &:hover {
      background: inherit;
      color: $black;
    }
  }

  .submit-btn a:hover {
    background: inherit;
    border: 2px solid $color-primary;
    color: $black;
  }

  .delete-btn button {
    background: inherit;
    border: 2px solid $color-primary;
    color: $color-primary;
    &:hover {
      background: $color-primary;
      color: $white;
    }
  }

  .button--loading::after {
    animation: button-loading-spinner 1s ease infinite;
    border: 4px solid transparent;
    border-radius: 50%;
    border-top-color: $white;
    bottom: 0;
    content: "";
    cursor: not-allowed;
    height: 16px;
    margin: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 16px;
  }
  @keyframes button-loading-spinner {
    from {
      transform: rotate(0turn);
    }
    to {
      transform: rotate(1turn);
    }
  }

  .mobile {
    display: none;
  }

  // MOBILE
  @media (max-width: 767px) {
    .desktop {
      display: none;
    }

    .mobile {
      display: initial;
    }

    .vin-message-bold {
      margin-bottom: 1.25rem;
      width: 200px;
    }

    .file-preview-label {
      max-width: 15rem;
    }

    .actions-cta {
      flex-direction: column-reverse;
      margin-top: 2.5rem;
    }
  }
}