.section.contact-form {
  &.overlay::before {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 10;
    content: '';
    left: 0;
    top: 0;
  }
  .modal-open {
    display: flex;
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    .modal-popup {
      position: relative;
      width: 30%;
      margin: auto;
      background-color: rgba(255, 255, 255, 1);
      padding: rem(30);
      min-height: rem(300);

      .icon-close {
        position: absolute;
        top: 0;
        right: 0;
        font-size: rem(15);
        padding: rem(10);
        background-color: $color-primary;
        margin: rem(5);
        color: $color-background;
      }

      .popup-container {
        display: flex;
        flex-direction: column;

        h1 {
          padding: rem(20) 0;
          text-align: center;
          font-family: $font-heading;
          font-size: $font-heading-m-font-size-small;
          font-weight: $font-body-xs-font-weight;
        }

        .opt-text-input {
          padding-bottom: rem(25);
        }

        button {
          margin: 0;
        }
      }
    }
  }
}