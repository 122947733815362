.dealer-services [class^='icon-'] {
  color: $color-primary;
}

.icon-accessori {
  @extend .icon-certification;
}

.icon-assicurazione {
  @extend .icon-insurance;
  color: $color-primary;
}

.icon-cortesia {
  @extend .icon-motorbike
}

.icon-elettrica {
  @extend .icon-electric;
}

.icon-gomme {
  @extend .icon-tire;
}

.icon-carrozzeria {
  @extend .icon-body-shop;
}

.icon-tagliando {
  @extend .icon-scheduled-maintenance;
}

.icon-hours {
  @extend .icon-hours;
}

.icon-incidenti {
  @extend .icon-warning;
}

.icon-lavaggio {
  @extend .icon-wash;
}

.icon-lounge {
  @extend .icon-lounge;
}

.icon-merchandising {
  @extend .icon-merchandising;
}

.icon-mobile {
  @extend .icon-mobile;
}

.icon-recupero {
  @extend .icon-tow;
}

.icon-serviziassicurativifinanziari {
  @extend .icon-serviziassicurativifinanziari
}

.icon-revisione {
  @extend .icon-checkup;
}

.icon-ricambi {
  @extend .icon-spart-parts;
}

.icon-testride {
  @extend .icon-helmet;
}

.icon-verniciatura {
  @extend .icon-paint;
}

.icon-wifi {
  @extend .icon-wifi;
}

.icon-certified-assistance {
  @extend .icon-certified-assistance
}

.icon-certified-assistance-small {
  @extend .icon-certified-assistance-small
}

.vue-map,
.vue-map-container {
  height: 500px;
}

.dealer-contact button {
  width: 100%;

  @include breakpoint(large) {
    width: auto;
  }
}

.dealer-services .listing-header {
  justify-content: space-evenly;
}

.dealer-locator-detail {

  .custom-dealer-label {
    font-style: italic;
    font-size: 1.5rem;
    text-align: center;
    transform: translateY(-4rem);
    line-height: 2rem;
  }

  .categories-navigation li {
    max-width: unset;
    margin: 0 auto;
    word-break: break-word;

    @include breakpoint(large) {
      margin: 0;
    }
  }
}

.dealer-detail__extra-availabilities {
  margin-top: rem(40);
}

// PIAGGIO-9043: visibility with zoom 400%
@media screen and (max-width: 320px) {
  &.t-dealer-locator-omnichannel {
    iframe {
      min-height: 40rem;
    }
  }
}