.product-presentation {
  overflow: hidden;
  position: relative;

  &.horizontal {
    @if ($product-presentation-background-decoration) {
      &:before {
        @extend .icon-background-decoration:before;
        color: $color-gray-lightest;
        opacity: 0.5;
        position: absolute;
        font-family: $font-family-icons;
        top: 0;
        font-size: rem(190);
        left: -120px;
        display: none;
        @include breakpoint(large) {
          display: block;
        }
      }
    }
  }

  &.has-hero {
    padding-top: $header-height-large;
  }

  .hero {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-bottom: 75%; // 640 : 480
    overflow: hidden;

    @include breakpoint(large) {
      padding-bottom: 41.66667%; // 1920 : 800
    }

    @include breakpoint(xlarge) {
      padding-bottom: 41.66667%; // 1920 :  533
    }

    @include breakpoint(xxlarge) {
      padding-bottom: 41.66667%;
    }

    img {
      object-fit: cover;
      position: absolute;
      width: 100%;
      height: 100%;

      @include breakpoint(xxlarge) {
        max-height: 600px;
      }
    }

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba($color: #000000, $alpha: .2);

      @include breakpoint(xxlarge) {
        max-height: 600px;
      }
    }
  }

  .product-presentation__container {
    text-align: center;
    position: relative;
    color: $color-on-background;
    @include xy-grid();

    & > div {
      width: 100%;
      //@include xy-cell($gutter-position: left right top);
    }

    .product-presentation_colors {
      display: flex;
      flex-direction: column;

      .product-presentation_colors__current-color {
        order: 1;
        @include font-heading-s;
        text-transform: uppercase;
        margin: rem(5) 0;
      }
    }

    &.product-presentation--vertical {
      & > div {
        @include breakpoint(large) {
          @include xy-cell(8 of 12);
          @include xy-cell-offset(2);
        }
      }
    }

    &.product-presentation--horizontal {
      @include breakpoint(large) {
        //padding-left: 50%;
        text-align: left;
        @include flex-align(right);
      }

      & > div {
        @include breakpoint(large) {
          @include xy-cell($size: 50%, $gutter-position: left right top bottom);
        }
      }

      .product-presentation__abstract--large {
        display: none;
        @include breakpoint(large) {
          display: block;
        }
      }

      .product-presentation__abstract--small {
        display: block;
        @include breakpoint(large) {
          display: none;
        }
      }


      .product-presentation_colors {
        .product-presentation_colors__current-color {
          @include breakpoint(large) {
            order: 0;
          }
        }
      }

      .product-presentation__colors__images {
        @include breakpoint(large) {
          position: absolute;
          left: 0;
          top: 0;
          @include xy-cell(
                  $size: 50%,
                  $gutter-type: padding,
                  $gutter-position: left right top bottom
          );
        }
      }

      .product-presentation_colors__swatches {
        @include breakpoint(medium down) {
          margin-top: 12px;
        }
      }

      .nav-link {
        @include breakpoint(medium down) {
          margin-top: 12px;
          display: block;
        }
      }
    }

    ////////////////////////////////////////
    // title
    .product-presentation__title {
      @include font-heading-xl;
      color: $product-presentation-color;
      text-transform: $product-presentation-title-transform;
      line-height: 1 !important;
      margin: $product-presentation-title-spacing;

      &.is-white {
        color: $text-color-white;
      }
    }

    ////////////////////////////////////////
    // code
    .product-presentation__code {
      @include font-heading-xs;
      text-transform: uppercase;
      margin: $product-presentation-code-spacing;
    }

    ////////////////////////////////////////
    // abstract
    .product-presentation__abstract {
      @include font-body;
      margin: $product-presentation-abstract-spacing;
      display: none;
    }

    .product-presentation_colors__size {
      margin-bottom: $space-size-m;

      .product-presentation_colors__size__title {
        @include font-heading-s;
        text-transform: uppercase;
        margin-bottom: rem(5);
      }

      .product-presentation_colors__size__list {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          display: inline-block;
        }

        .button--size {
          @include font-button-text-m();
          padding: rem(10);
          color: $black;

          &.active {
            border-bottom: 1px solid $black;
            //margin-right: 0;
          }
        }

      }
    }

    ////////////////////////////////////////
    // tabs

    ////////////////////////////////////////
    // carousel product
    .carousel-product {
      .carousel-product__item {
        display: none;
      }
    }

    ////////////////////////////////////////
    // swatches
    .product-presentation__swatches {
      margin: $space-size-l;
    }

    ////////////////////////////////////////
    // price
    .product-presentation__price {
      margin: $product-presentation-price-margin-m;
      position: relative;
      width: fit-content;

      @include breakpoint(medium) {
        margin: $product-presentation-price-margin;
      }

      @include breakpoint(large) {
        margin-bottom: 0;
      }
    }

    .product-price {
      .product-price__discounted {
        @include font-heading-l;
        white-space: nowrap;
        display: block;

        @include breakpoint(large) {
          position: relative;
          display: inline-block;
        }
      }

      .product-price__list {
        position: relative;
        display: inline-block;
        @include font-heading-l;
        white-space: nowrap;

        .icon-divider {
          display: none;
        }
      }

      .product-presentation-price__tooltip {
        position: absolute;
        transform: translateX(-50%);
        bottom: auto;
        left: auto;
        right: $product-presentation-tooltip-right-m;
        top: $product-presentation-tooltip-top-m;

        i[class^="icon-"] {
          color: $color-primary;
        }

        span {
          z-index: 1000;
        }

        @include breakpoint(medium) {
          right: $product-presentation-tooltip-right;
          top: $product-presentation-tooltip-top;
          transform: none;
        }

        .tooltip__content {
          font-size: $product-presentation-immersive-font-size !important;
          font-weight: $product-presentation-immersive-font-weight !important;
          line-height: $product-presentation-immersive-font-line-height !important;
          top: $product-presentation-tooltip-content-top;
          max-width: rem(175);
          width: rem(175);
          color: $white;
          background-color: $color-background-gray;
          backdrop-filter: blur(8px);

          &::after {
            border-left: 9px solid transparent;
            border-right: 9px solid transparent;
            border-top: 9px solid $color-background-gray;
            left: 50.5%;
            bottom: $product-presentation-tooltip-content-bottom;
            transform: rotate(180deg) translateX(50%);
          }
        }
      }

      .product-price__discounted + .product-price__list {
        span {
          text-decoration: line-through;
        }

        @include font-body;
        margin-right: $space-size-xs * 2;
        position: relative;
        display: inline-block;

        @include breakpoint(large) {
          @include font-heading-s;
          margin-left: $space-size-xs * 2;
          margin-right: 0;
        }

        .icon-divider {
          display: inline-block;
          @include center(y);
          right: $product-presentation-price-divider-right;

          color: $color-primary;
          font-size: $product-presentation-price-divider-size;
          line-height: $product-presentation-price-divider-size;

          @include breakpoint(large) {
            position: absolute;
            left: $product-presentation-price-divider-right;
            right: auto;
          }
        }
      }

      .product-price__planned {
        @include font-body;
        white-space: nowrap;

        @include breakpoint(large) {
          display: block;
        }
      }
    }

    ////////////////////////////////////////
    // ctas
    .product-presentation__ctas {
      margin-top: $space-size-xl;

      .button {
        @include breakpoint(small only) {
          margin-right: 0;
          margin-bottom: $button-m-margin;
          display: block;
          width: 100%;
        }
      }
    }
  }


  .hooper-slide img {
    width: 100%;
  }

  .carouselV4__arrows {
    .carouselV4__arrows__prev,
    .carouselV4__arrows__next {
      left: rem(-34);
      @include center(y);
      z-index: 5;

      color: inherit; // from section
      font-size: 4rem;

      @include breakpoint(large) {
        left: rem(-70);
      }
    }

    .carouselV4__arrows__next {
      left: auto;
      right: rem(-34);

      @include breakpoint(large) {
        right: rem(-70);
      }
    }
  }
}

//Remove t-model-version borders from the body - PIAGGIO:9291
&:not(.sb-show-main) {
  @if $body-effect {
    &.t-model-version.pdp-page {
      &::before, &::after {
        border-color: transparent transparent transparent transparent !important;
      }

      //Set navbar background visible
      &:not(.main-navigation-open) {
        .header:not(.header--sticky) {
          nav {
            &::before,
            &::after {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}