.hero-single-content {
  aspect-ratio: var(--aspect-ratio);
  font-family: $font-body;
  margin: 0;
  padding: 0;
  width: 100%;

  &__container, .container__wrapper, &__items, &__overlay,
  &__background-image, &__background-image picture {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  &__items {
    background: rgba(0,0,0, 1);
    position: relative;
  }
  &__overlay, &__overlay.disabled {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    height: 100%;
    &.light {
      background:rgba(0, 0, 0, 0.3);
    }
    &.strong {
      background:rgba(0, 0, 0, 0.6);
    }
  }

  .toggle-play, .toggle-mute {
    left: 0;
    bottom: rem(10);
    @include breakpoint(medium) {
      bottom: rem(20);
      left: rem(20);
    }
    button {
      background: transparent;
      width: rem(48);
      height: rem(48);
    }
  }

  &__background-image {
    width: 100%;
    height: 100%;
    overflow: hidden;
    picture {
      display: block;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__background-video {
    position: relative;
    width: 100%;
    height: 100%;
    .mgnl-video {
      min-width: 100%;
      max-width: unset;
      height: 100%;
      object-fit: cover;
    }
    .vue-vimeo-player {
      display: block;
      @include breakpoint(medium) {
        aspect-ratio: var(--aspect-ratio);
        position: absolute;
        left: 0;
        min-width: 100%;
        height: 100%;
      }
      div {
        @include breakpoint(xlarge) {
          height: 100%;
          width: calc(100% + 55px);
        }
        @include breakpoint(medium) {
          height: 100%;
          width: calc((100% * var(--aspect-ratio)) * 2) ;
          transform: translateX(-50%);
          left: 50%;
        }
      }
      iframe {
        width: 100%;
        height: 100%;
        object-fit: cover;
        @include breakpoint(medium) {
          object-fit: contain;
        }
      }
    }

    &.vimeo-controls {
      width: 100%;
      height: 100%;
      transform: scale(1.2);
      transform-origin: center bottom;
      @include breakpoint(medium) {
        transform: scale(1);
      }
      div {
        height: 100%;
      }
    }
  }

  &__flex-info {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: rem(10);
    z-index: 1;
    @include breakpoint(medium) {
      &.video-controls {
        bottom: rem(90);
      }
      bottom: rem(40);
    }
    .info-container {
      width: 100%;
      margin: 0 auto;
      padding: 0 rem(15);
      @include breakpoint(medium) {
        padding: 0;
      }
      .title {
        width: 100%;
        color: $white;
        line-height: 44px;
        @include breakpoint(medium) {
          line-height: 88px;
        }
        @include font-heading-hero-content-xl();
      }
      .hero-text {
        width: 100%;
        color: $white;
        margin-top: rem(8);
        @include font-body();
      }
      .cta-links {
        width: 100%;
        margin-top: rem(32);
        button {
          width: auto;
          display: block;
          margin: rem(10) 0;
          @include breakpoint(medium) {
            width: auto;
            display: inline-flex;
            margin: 0 rem(15);
          }
        }
        a {
          display: flex;
          @include breakpoint(medium) {
            display: inline-flex;
          }
        }

        .button {
          width: fit-content;
        }
        .button.full-width {
          width: 100%;
        }
      }
    }
    &.left {
      text-align: left;
      @include breakpoint(medium) {
        width: fit-content;
        margin: 0;
        left: rem(40);
      }
      .info-container {
        @include breakpoint (medium) {
          width: 100%;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
        }
        .cta-links {
          @include breakpoint (medium) {
            width: 100%;
          }
          .button {
            @include breakpoint (medium) {
              margin: 0 rem(32) 0 0;
            }
          }
        }
      }
    }
    &.center {
      text-align: center;
      .info-container {
        .cta-links {
          .button {
            margin: rem(10) auto;
            @include breakpoint(medium) {
              margin: 0 rem(15);
            }
          }
        }
      }
    }

    .center {
      .title, .hero-text {
        margin: 0 auto;
      }
    }
  }

  .toggle-play {
    right: 2rem;
    left: unset;
  }
}

.hero-single-content-100 {
  height: 100svh;
  .vimeo-player-content-100 {
    display: contents;
    aspect-ratio: var(--aspect-ratio);
    position: absolute;
    left: 0;
    min-width: 100%;
    height: 100svh;
    div {
      height: 100svh;
      width: calc((100% * var(--aspect-ratio)) * 2);
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
    }
    iframe {
      width: 100%;
      height: 100svh;
    }
  }
  .video-controls {
    @include breakpoint(medium) {
      bottom: rem(280);
    }
  }
}

.hero-single-content-75 {
  height: 75svh;
  .vimeo-player-content-75 {
    display: contents;
    aspect-ratio: var(--aspect-ratio);
    position: absolute;
    left: 0;
    min-width: 100%;
    height: 75svh;
    div {
      height: 75svh;
      width: calc((100% * var(--aspect-ratio)) * 2);
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
    }
    iframe {
      width: 100%;
      height: 75svh;
    }
  }
  .video-controls {
    @include breakpoint(medium) {
      bottom: rem(150);
    }
  }
}