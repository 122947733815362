.carouselV3 {
  padding-top: 0;
  padding-bottom: 0;
  position: relative;

  *:focus {
    outline-color: $carouselV3-outline-color;
  }

  .hooper {
    height: auto;
    &:focus {
      outline-offset: $general-outline-width * -1;
    }

    &:before {
      padding: 0 !important;
    }

    @include breakpoint(large) {
      height: auto;
    }

    .hooper-track {
      height: auto;
    }

    .hooper-list {
      background-color: $black;
    }

    .content-slide {
      &.controls-on {
        height: fit-content;
      }
    }

    /**/
    .hooper-slide {
      @include aspect-ratio(1080, 1920, ".content-slide");

      @include breakpoint(large) {
        @include aspect-ratio(1920, 1080, ".content-slide");
      }
    }

    .content-slide__link {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 3;
    }

    img {
      width: 100%;
    }

    .hooper-slide {
      position: relative;
      height: auto;

      &[aria-hidden="true"] {
        visibility: hidden; // to prevent focus in elements outside the visible viewport
      }
    }

    .section__container {
      position: relative;
      z-index: 2;
    }

    .carouselV3__title {
      @include font-heading-xl();
      margin-bottom: $space-size-xl;
      color: $carouselV3-title-color;
    }

    .carouselV3__text {
      display: none;
      @include breakpoint(large) {
        display: block;
        margin-bottom: $space-size-xl;
      }
    }

    .slide-img__image:after {
      background: $black;
      background: linear-gradient(
        0deg,
        rgba($black, 0.5) 0%,
        rgba($black, 0.2) 30%,
        rgba($black, 0.2) 100%
      );
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    .slide-img__image--no-gradient:after {
      background: unset;
    }

    .cta-links {
      margin-bottom: rem(12);
    }
  }

  .slide-img__video iframe {
    // hack for Firefox on Windows with zoom
    top: 1px;
    position: relative;
  }
}

.carouselV3__navigation li {
  button {
    opacity: 0.6;
    position: relative;

    &:hover,
    &:focus {
      opacity: 1;
    }
  }

  .slide-loader {
    content: "";
    height: 2px;
    background: $carouselV3-loader-bg;
    width: 0;
    position: absolute;
    bottom: -2px;
    left: 0;
    opacity: 0;
  }

  &.active {
    button {
      opacity: 1;
    }

    .slide-loader {
      opacity: 1;
      animation-name: loader;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
    }
  }
}

/*section.hooper:hover + .carouselV3__container .carouselV3__navigation li.active .loader,
.carouselV3__navigation:hover li.active .loader {*/

.carouselV3:hover,
.carouselV3:focus {
  .carouselV3__navigation li.active .slide-loader {
    animation-play-state: paused;
  }
}

.carouselV3__container {
  position: absolute;
  max-width: $grid-container;
  z-index: 2;
  top: 0;
  margin: 0 auto;
  left: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  &--align-center {
    justify-content: center;
    align-items: flex-end;
    text-align: center;
    padding-bottom: 20%;
  }

  @include breakpoint(large) {
    justify-content: flex-start;
    text-align: left;

    &--align-right {
      justify-content: flex-end;
    }

    &--align-center {
      justify-content: center;
      align-items: flex-end;
      text-align: center;
      padding-bottom: rem(150);
      .carouselV3__content {
        width: 80%;
      }
    }

  }
  &.controls-on {
    height: fit-content;
    top: 70vh;
    @include breakpoint(large) {
      top: 80vh;
    }
  }
}

.carouselV3__container--bottom {
  display: block;
  height: auto !important;
  top: auto !important;
  bottom: 0;
  width: 90%;

  @include breakpoint(large) {
    bottom: rem(10);
  }

  &.controls-on {
    bottom: rem(40);
    @include breakpoint(large) {
      bottom: rem(60);
    }
  }
}

.carouselV3__content {
  @include font-body();
  color: $carouselV3-content-color;
  padding-left: rem(8);
  padding-right: rem(8);
  @include breakpoint(large) {
    width: 40%;
  }
}

.carouselV3__navigation {
  display: flex;
  justify-content: center;

  li {
    display: flex;
    width: auto;

    [class^="icon-"] {
      font-size: rem(33);
      margin-bottom: rem(8);
      line-height: 1;
    }

    @include breakpoint(large) {
      width: 25%;
      [class^="icon-"] {
        margin-right: rem(10);
        margin-bottom: 0;
      }
    }
  }

  li button {
    @include font-body-xs();
    color: $carouselV3-li-button-color;
    cursor: pointer;
    text-align: left;
    display: inline-flex;
    align-items: center;
    width: 100%;
    border-bottom: 2px solid $color-gray;
    margin-right: 15px;
    padding: 4px 10px;

    @include breakpoint(large) {
      margin-right: 20px;
    }
  }

  li:last-child button {
    margin-right: 0;
  }
}

@keyframes loader {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  50% {
    width: 50%;
  }
  100% {
    width: 100%;
  }
}
