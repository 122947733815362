.alert {
  @include font-heading-xs();
  padding: 10px;
  position: relative;
  text-align: center;
  min-height: 44px;
  margin-bottom: 15px;
  max-width: rem(1920);
  
  a {
    color: $color-on-primary;
    text-decoration: none;
  }

  &.extended{
    margin-right: -2.4rem;
    margin-left: -2.4rem;
  }

  &.alert--sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  &.alert--primary {
    background-color: rgba($color-primary, 1);
    color: $color-on-primary;
  }

  &.alert--secondary {
    background-color: rgba($color-secondary, 1);
    color: $color-on-secondary;
  }

  &.alert--error {
    background-color: rgba($color-error, 1);
    color: $color-on-error;
  }

  &.alert--confirmation {
    background-color: rgba(green, 1);
    color: white;
  }

  .alert__close {
    @include center(y);
    right: 0;
    color: inherit;
    font-size: 2.8rem;
  }
}
