.navigation {
  position: fixed;
  top: $header-height-small;
  display: none;
  right: 0;
  left: 0;
  background-color: $navigation-level1-background-small;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - #{$header-height-large});
  color: $navigation-item-color;
  padding-bottom: 24px;
  align-items: center;
  justify-content: center;
  @include breakpoint(xxxlarge) {
    max-width: rem(1920);
    margin: 0 auto;
  }

  .main-navigation-index {
    .show-on-focus {
      &:focus {
        @include font-body-s;
        position: absolute !important;
        top: 10%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10;
        color: #000;
        pointer-events: none;
      }
    }
  }

  .close-main-navigation {
    .show-on-focus {
      &:focus {
        @include font-body-s;
        font-size: rem(20);
        position: absolute !important;
        top: 20%;
        right: rem(35);
        z-index: 10;
        color: #000;
        pointer-events: none;
      }
      &[aria-expanded="true"] {
        i {
          @extend .icon-close;
        }
      }
    }
  }

  .graphic-line {
    margin: 0 auto;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 111rem;
    overflow-y: visible;
    height: auto;
    @include breakpoint(large) {
      @if $navigation-background-gradient-large {
        &:before {
          content: "";
          position: absolute;
          top: -14vh;
          height: 2000px;
          width: 1000px;
          background: $navigation-background-gradient-large;
          transform: translateY(-50%);
          right: calc(79rem);
        }
      }
    }
  }

  .navigation__structure-div {
    width: 100vw;
    overflow-y: scroll;
    display: flex;
    @include breakpoint(large) {
      height: calc(100vh - 70px);
      padding: 80px 0;
    }
  }

  .navigation__content-wrapper {
    margin: 0 auto;
    display: flex;
    width: 111rem;
    max-width: 100vw;
  }

  @if ($navigation-background-decoration) {
    &:before {
      @extend .icon-background-decoration:before;
      color: $navigation-background-decoration-color;
      opacity: 0.5;
      position: absolute;
      font-family: $font-family-icons;
      top: 0;
      font-size: $navigation-background-decoration-font-size;
      left: $navigation-background-decoration-left-position;
      display: none;
      @include breakpoint(large) {
        display: block;
      }
    }
  }

  &.is-visible {
    display: block;
    padding-bottom: rem(120);
    z-index: 100;
  }

  /* &.has-promo {
    top: rem(114);
  }*/

  @include breakpoint(large) {
    top: $header-height-large;
    align-items: center;
    padding: 0;
    box-sizing: content-box;
    height: 0;
    transition: all 1s ease;
    // overflow: hidden;
    overflow-y: auto;
    background-color: $navigation-background-large;

    display: none;

    &.is-visible {
      display: flex !important;
      animation: slide 0.2s forwards;
      overflow: hidden;
      z-index: 1000;

      @media screen and (max-height: 800px) {
        // overflow-y: auto;
      }
    }

    @keyframes slide {
      0% {
        height: 0;
        padding: 0;
      }
      100% {
        height: calc(100vh - #{$header-height-large});
        padding: 0;
      }
    }
  }

  ////////////////////////////////////////
  // LAYOUT

  .navigation__content {
    width: 100%;

    @include breakpoint(large) {
      width: rem(1110px);
      max-width: 100vw;
      margin: auto;
      display: flex;
      align-items: $navigation-layout-align-items;
      justify-content: space-between;
      height: $navigation-content-height-large;
      position: relative;
    }
  }

  .navigation__change-lan-cou {
    text-align: center;
    @include breakpoint(large) {
      position: absolute;
      bottom: auto;
      right: 20px;
      top: 20px;
      left: auto;
      transform: none;
      z-index: 10;
    }
  }

  .navigation__menu {
    @include breakpoint(large) {
      @include xy-cell(3); //$gutters: 0
      display: flex;
      align-items: $navigation-menu-alignment;
      position: relative;

      ul {
        width: 100%;
      }
    }
  }

  .navigation__highlights {
    @include breakpoint(large) {
      position: relative;
      left: auto;
      top: 0;
      bottom: 0;
      z-index: 1;
      width: 75%;
      height: $navigation-content-height-large;
      display: none;
      justify-content: space-between;
      align-items: center;

      &.is-active {
        display: flex;
      }

      & > * {
        height: $navigation-highlight-height-large;
      }
    }
  }

  .navigation__menu-nested__menu + .navigation__highlights {
    @include breakpoint(large) {
      display: flex;
    }
  }

  .navigation__background {
    @include breakpoint(large) {
      position: relative;
      height: $navigation-content-height-large;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      & > * {
        height: $navigation-highlight-height-large;
      }
    }
  }

  .navigation__menu + .navigation__highlights,
  .navigation__menu + .navigation__background {
    @include breakpoint(large) {
      @include xy-cell(9); //$gutters: 0
    }
  }

  .navigation__menu-nested__menu + .navigation__highlights,
  .navigation__menu-nested__menu + .navigation__background {
    @include breakpoint(large) {
      flex-grow: 1;
      flex-basis: 0;
    }
  }

  .navigation__menu-nested {
    visibility: hidden;
    max-height: 100%;
    background-color: $navigation-level2-background-small;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 100%;
    // padding-bottom: rem(50);
    width: 100%;
    transition: transform 0.15s linear;
    transform: translateX(0);
    overflow-y: auto;
    z-index: 1;

    &.is-visible {
      visibility: visible;
      transform: translateX(-100%);
      padding-bottom: 120px;

      @include breakpoint(large) {
        display: flex !important;
        position: absolute;
        right: 0;
        top: 0;
        transform: none;
      }
    }

    @include breakpoint(large) {
      display: none;
      position: relative;
      left: auto;
      top: 0;
      bottom: 0;
      z-index: 1;
      overflow-y: initial;
      max-height: initial;
      background-color: $navigation-background-large;
      height: $navigation-content-height-large;
      @include xy-cell(9, $gutters: 0);
      // PIAGGIO-6951 hack for windows 150% zoom
      width: calc(74% - 1.5rem);
      margin-right: 1.5rem;
    }

    // PIAGGIO-8774: content visibility with zoom 400%
    @media screen and (max-width: 320px) {
      position: fixed;
      & > div {
        position: fixed;
        top: 5em;
      }
    }

    & > div {
      display: flex;
      width: 100%;
      @include breakpoint(medium down) {
        flex-direction: column;
      }
    }

    .navigation__menu-nested__menu {
      padding-bottom: $navigation-space-v;
      @include breakpoint(large) {
        flex-grow: 1;
        flex-basis: 0;
        display: flex;
        flex-direction: column;
        justify-content: $navigation-menu-nested-justify;
        align-items: $navigation-menu-nested-align;
        max-width: 50%;
        padding-bottom: 0;

        &.navigation__menu-nested__menu--product {
          max-width: initial;

          ul {
            &::before {
              content: initial;
            }
          }

          .navigation__menu-nested__brand-icon {
            display: none;
          }
        }

        @if $navigation-editorial-divider {
          ul {
            @if $navigation-editorial-divider-parent == "list" {
              position: relative;
            }

            &::before {
              content: "";
              width: 2px;
              position: absolute;
              left: -2px;
              top: 0;
              bottom: 0;
              background-color: $color-primary;
            }
          }
        }
      }

      @if $navigation-nested-brand-icon {
        .navigation__menu-nested__brand-icon {
          @include show-for(large);
          position: absolute;
          color: $color-gray-lightest;
          opacity: 0.5;
          transform: translate(-36%, -24%);
          font-size: 20rem;
        }
      } @else {
        .navigation__menu-nested__brand-icon {
          display: none;
        }
      }
    }

    .navigation-tabs {
      flex-direction: column;
    }

    .navigation__menu-nested__nav-tabs {
      display: inline-flex;
      @include breakpoint(medium down) {
        width: 100%;
        justify-content: center;
        padding: 10px 0 20px;
      }

      .nav-tabs__nav-item {
        margin: 0 20px;
      }

      .nav-tabs__nav-link {
        color: inherit;
        text-decoration: none;
        position: relative;

        &.is-active {
          &:after {
            content: "";
            position: absolute;
            bottom: -8px;
            left: 0;
            right: 0;
            background-color: $button-primary-color;
            height: 2px;
          }
        }
      }
    }
  }

  ////////////////////////////////////////
  // TYPOGRAPHY
  .navigation__menu__item {
    @include font-body();
    font-weight: $navigation-item-font-weight-small;
    text-transform: $navigation-item-font-transform-small;
    padding: $navigation-space-v $navigation-space-h;

    @include breakpoint(large) {
      font-weight: $navigation-item-font-weight-large;
      text-transform: $navigation-item-font-transform-large;
      font-style: $navigation-item-font-style-large;
      padding: 18px 10px;
    }

    &:focus {
      outline-offset: $general-outline-width * -1;
    }
  }

  .navigation__menu-nested__item {
    @include font-body();
    font-weight: $navigation-item-nested-font-weight-small;
    text-transform: $navigation-item-nested-font-transform-small;
    padding: $navigation-space-v $navigation-space-h;

    @include breakpoint(large) {
      @include font-body-s();
      font-weight: $navigation-item-nested-font-weight-large;
      text-transform: $navigation-item-nested-font-transform-large;
      font-style: $navigation-item-nested-font-style-large;
      padding: 13px $navigation-space-h;
    }
  }

  .navigation__menu-nested__back {
    @include font-button-text-l;
    text-transform: uppercase;
    padding: rem(14) $navigation-space-h;
    height: rem(70);
  }

  .navigation__menu-nested__title {
    @include font-heading-l;
    padding: $navigation-space-h;
  }

  ////////////////////////////////////////
  // OTHER STYLES
  .navigation__menu {
    padding: $navigation-space-v 0;

    @include breakpoint(large) {
      padding: 0;
    }
  }

  li {
    &:not(:last-child) {
      .navigation__menu__item,
      .navigation__menu-nested__item {
        @if $navigation-item-divider {
          &::after {
            content: "";
            height: 1px;
            width: 20px;
            @include center(x);
            bottom: 0;
            background-color: $navigation-item-divider;
          }
        }
      }

      .navigation__menu-nested__item {
        &::after {
          @include breakpoint(large) {
            @if ($navigation-nested-divider-large) {
              left: $navigation-nested-divider-large;
            }
          }
        }
      }
    }
  }

  .navigation__menu__item {
    text-align: $navigation-item-align;
    color: $navigation-item-color;
  }

  .navigation__menu-nested__item {
    text-align: $navigation-item-align;
    @include breakpoint(large) {
      text-align: left;
      margin: $navigation-item-nested-margin;
    }
  }

  .navigation__menu__item,
  .navigation__menu-nested__item,
  .navigation__menu-nested__back {
    display: block;
    position: relative;
    text-decoration: none;
    width: 100%;

    i[class^="icon-"] {
      @include center(y);
      right: $navigation-space-h;
      color: $navigation-item-arrow-color;
      font-size: 1.8rem;
      line-height: 1.8rem;
    }
  }

  .navigation__menu__item,
  .navigation__menu-nested__item {
    i[class^="icon-"] {
      @if ($navigation-item-arrow) {
        display: inline-block;
      } @else {
        display: none;
      }

      @include breakpoint(large) {
        display: none;
      }
    }
  }

  .navigation__menu__item {
    &[aria-expanded="true"] {
      @include breakpoint(large) {
        color: $navigation-item-active-color;
        font-weight: $navigation-item-active-font-weight;

        @if $navigation-item-active-text-decoration == underline {
          width: auto;
          &::after {
            content: "";
            display: inline-block;
            height: 2px;
            background-color: $color-primary;
            position: absolute;
            bottom: 2px;
            left: 10px;
            right: 10px;
          }
        }
        @if $navigation-item-active-close {
        }
      }
    }
  }

  .navigation__menu-nested__item {
    color: $navigation-item-nested-color;
  }

  .navigation__menu-nested__back {
    @include hide-for(large);
    text-align: $navigation-item-align;
    padding-left: $navigation-space-h * 2;
    padding-right: $navigation-space-h * 2;
    background: $navigation-item-back-background;
    color: $navigation-item-back-color;
    margin-bottom: $navigation-space-v;

    i[class^="icon-"] {
      color: $navigation-item-back-arrow-color;
      left: $navigation-space-h;
      right: initial;
    }
  }

  .navigation__menu-nested__title {
    @include show-for(large);
    position: relative;

    @if $navigation-nested-title {
      display: inline-block;
    } @else {
      display: none;
    }

    color: $navigation-nested-title-color;
    text-align: $navigation-nested-align-large;
  }

  .navigation__highlights {
    @include breakpoint(large) {
      margin: 0 map-get($grid-column-gutter, medium);
    }

    .card-highlight {
      margin: 0 $navigation-space-h $navigation-space-h;

      @include breakpoint(large) {
        margin: 0 $navigation-space-h 0 0;
        flex-grow: 1;
        flex-basis: 0;
        //max-width: 48%;

        > div {
          padding-top: 0;
        }

        &:last-child {
          margin: 0;
        }
      }
    }
  }

  .navigation__background {
    margin-bottom: $navigation-space-h;

    @include breakpoint(large) {
      margin: 0 map-get($grid-column-gutter, medium);
    }

    div {
      padding-top: 75%; /* 4:3 Aspect Ratio */
      position: relative;

      @include breakpoint(large) {
        padding-top: 0;
        text-align: right;
      }
    }

    img {
      position: absolute;
      top: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: $card-highlight-border-radius;

      @include breakpoint(large) {
        position: static;
        height: 100%;
        width: 100%;
      }
    }
  }

  *:focus {
    outline-color: $navigation-outline-color;
  }

  .change-language-country {
    color: inherit;

    @include breakpoint(large) {
      color: $navigation-item-nested-color;
    }
  }
}

&.t-model-version.has-promo .navigation {
  top: rem(114);
}