.container-animated-media {
  .animated-vimeo-player div {
    iframe {
      pointer-events: none;
    }
  }
  .animated-vimeo-player.controls-on div {
    iframe {
      pointer-events: all;
    }
  }
}