// HOOPER EXTERNAL LIBRARY CUSTOM STYLES
.hooper {
  height: auto;
  position: relative;
  //border: 1px solid red;

  .hooper-list {
    height: initial;
  }

  .hooper-slide {
    &[aria-hidden="true"] {
      visibility: hidden; // to prevent focus in elements outside the visible viewport
    }
  }

  &.hooper--partial-slide{
    .hooper-slide { 
      &.is-next,
      &.is-prev {
        @include breakpoint(small only) {
          opacity: 0.2;
          visibility: initial;
        }
      }
    }
  }

  .hooper-track {
    align-items: center;
    height: auto;
  }

  &.centered {
    .hooper-track {
      justify-content: center;
      transform: none !important;
    }
  }
}

// .hooper-navigation {
//   .hooper-prev,
//   .hooper-next {
//     color: $color-primary;

//     svg {
//       display: none;
//     }

//     &.is-disabled {
//       opacity: 0.5;
//     }

//     font-family: $font-family-icons;
//     font-style: normal;
//     font-weight: normal !important;
//     font-variant: normal;
//     text-transform: none;
//     line-height: 1;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;

//     font-size: 4rem;
//     padding: 2px;
//   }

//   .hooper-prev {
//     @extend .icon-arrow-small-sx;

//     /*&::after {
//       content: "Previous"; // temporary since hooper does not support templating
//       @extend .show-for-sr;
//     }*/
//   }

//   .hooper-next {
//     @extend .icon-arrow-small-dx;

//     /*&::after {
//       content: "Next"; // temporary since hooper does not support templating
//       @extend .show-for-sr;
//     }*/
//   }
// } 

.hooper-pagination {
  //background-color: blanchedalmond;
  padding: 0;

  &.controls-on {
    bottom: rem(60);
  }

  .hooper-indicator {
    height: initial;
    width: initial;
    border-radius: 100%;
    background-color: transparent;
    margin: 0;

    &::after {
      // safe touch area
      background-color: $white;
      content: "";
      display: inline-block;
      height: rem(14);
      width: rem(14);
      border-radius: 100%;
      margin: rem(10);
    }

    &.is-active {
      background-color: transparent;

      &::after {
        background-color: $color-primary;
      }
    }
  }
}
