.carousel-product {

  .hooper {
    .hooper-list {
      @include xy-grid;
      padding-top: $general-outline-width;
      padding-bottom: $general-outline-width;
      height: auto;
    }
    .hooper-slide {
      @include xy-gutters($gutter-type: padding);
    }

    .hooper-track {
      align-items: flex-start;
    }
  }

  .carousel-product__carousel {
    @if ($carousel-product-arrows-align-with-title == false) {
      position: relative;
      overflow: hidden;

      @include breakpoint(xlarge) {
        overflow: visible;
      }
    }
  }

  .carousel-product__navigation {
    @include xy-gutters($gutter-type: padding);

    .carousel-product__viewall {
      display: inline-block;
      color: $carousel-product-viewall-color;
    }
  }

  .carousel__arrows {

    *{
      color: inherit;
    }
    

    @if ($carousel-product-arrows-align-with-title) {
      @include breakpoint(medium) {
        position: absolute;
        right: 0;
        top: -8.6rem;

        .carousel__arrows__prev,
        .carousel__arrows__next {
          position: initial;
          left: initial;
          top: initial;
          z-index: initial;
          bottom: initial;
          font-size: 3.4rem;
          transform: none;
          padding: rem(12);

          i {
            top: initial;
          }
        }
      }
    }
  }
}

#uuid-52135e29-dcd6-401d-a941-6156512d342c {
  .carousel-product{
    .listing-header__heading {
      text-align: center !important;
    }
    .listing-header__note {
      text-align: center !important;
    }
  }
}
