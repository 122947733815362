.mvs__section {
  //background-color: #E9E9E9;
  .editorial__title {
    margin-bottom: 20px;
  }
}

.mvs__facets-form {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  @include breakpoint(medium down) {
    margin: 14px 0;
  }
}

.mvs__facet-fieldset {
  //width: 255px;
  width: calc(25% - 32px);
  margin: 16px;
  @include breakpoint(medium down) {
    width: 100%;
    margin: 0;
  }

  &[disabled] {
    opacity: 0.3;
  }

  &.checked {
    .mvs__facet-legend {
      background-color: $button-primary-color;
      border-color: $button-primary-color;
    }
  }
}

.mvs__facet-options-container {
  padding: 18px 24px 10px;
  border: 1px solid #979797;
  border-top: none;
  background-color: white;
  height: 180px;
  overflow-y: auto;
  @include breakpoint(medium down) {
    border: none;
    height: auto;
  }
}

.mvs__facet-legend {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 70px;
  background-color: #747D86;
  color: $button-primary-text-color;
  padding: 14px;
  border: 1px solid #747D86;
  @include breakpoint(medium down) {
    min-height: 44px;
    border: none;
  }

  .icon-text {
    display: flex;
    align-items: center;

    .icon-text__icon {
      margin: 0 12px 0 0;
    }
  }
}

.mvs__facet-option {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  @include breakpoint(medium down) {
    margin-bottom: 20px;
  }
}

.mvs__facet-input {
  appearance: auto;
  min-width: 20px;
  height: 20px;
  margin-right: 24px;

  &[disabled] + .mvs__facet-input__label {
    opacity: 0.3;
  }
}

.mvs__facet-input__label {
  text-align: left;
}

.modal {
  .modal-dialog {
    &.mvs__modal-dialog {
      margin: 0;
    }
  }

  .modal-body {
    &.mvs__modal-body {
      padding: 0 12px;
      margin: 0 0 70px;
    }
  }
}

.mvs__bg-block {
  position: relative;
  text-align: center;
  background-color: #E9E9E9;
  @include breakpoint(large up) {
    padding: 78px 0 110px;
  }
  @include breakpoint(medium down) {
    min-height: 260px;
    &:last-child {
      min-height: auto;
      padding: 20px 0;
    }
  }
}

.mvs__no-vehicle__placeholder {
  position: relative;
  top: -74px;
}

.mvs__selected-version__section {
  text-align: center;
  display: flex;
  margin: -140px auto 0;

  .product-price__list {
    position: relative;
    display: inline-block;
  }

  .product-price__tooltip {
    position: absolute;
    right: -20px;
    top: -20px;
    bottom: auto;
    left: auto;
    transform: none;
    @include breakpoint(medium down) {
      bottom: -30px;
      transform: translateX(-50%);
      left: 50%;
      top: auto;
      right: auto;
    }

    i[class^="icon-"] {
      color: $color-primary;
    }
  }

  .product-presentation_colors {
    margin-bottom: 30px;
    @include breakpoint(medium down) {
      margin-bottom: 20px;
    }
  }

  .product-presentation__price {
    margin-bottom: 20px;
    @include breakpoint(medium down) {
      margin-bottom: 40px;
    }
  }

  .product-presentation_colors__current-color {
    margin-bottom: 12px;
  }
}

.mvs__vehicle-tab {
  width: 254px;
  min-height: 190px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  img {
    min-height: 170px;
  }
}

.modal {
  .modal-header {
    .mvs__modal-header {
      padding-right: 12px;
      padding-left: 12px;
    }
  }
}

.mvs__modal__footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  button {
    width: 100%;
  }

  .mvs__modal__ok-button {

  }
}

.mvs__modal-button {
  color: $primary-color;
}