.vehicle-premium-carousel {
  background-color: $white;

  .vehicle-premium-title {
    letter-spacing: 0;
    text-align: center;
    padding: 28px 16px 40px 16px;
    font-family: $font-heading-l-font-family;
    font-size: $font-heading-l-font-size-small;
    line-height: normal;
    font-weight: $font-heading-l-font-weight;
    letter-spacing: $font-heading-l-letter-spacing;

    @include breakpoint(large) {
      padding: 40px 40px 64px 40px;
      font-family: $font-heading-l-font-family;
      font-size: $font-heading-l-font-size-large;
      line-height: normal;
      font-weight: $font-heading-l-font-weight;
      letter-spacing: $font-heading-l-letter-spacing;
    }
  }

  .vehicle-premium-legal-notice {
    font-size: $font-body-ms-font-size;
    line-height: $font-body-ms-line-height;
    letter-spacing: $font-body-ms-letter-spacing;
    text-align: center;
    padding: 40px 16px 24px 16px;
  }

  .hooper-slide.is-current {
    .vehicle-premium-name,
    .vehicle-premium-price {
      display: block !important;
    }
    .vehicle-premium-description-displacements {
      display: flex !important;
    }
  }
}

////////////////////////////////////////
// Hotspot Carousel container
.vehicle-premium-carousel {

  .vehicle-premium-carousel__container {
    // background-color: $color-gray-lighter;
    overflow: hidden;
    position: relative;
    padding-right: 0 !important;
    padding-left: 0 !important;

    .hooper-track {
      -webkit-box-align: start;
      align-items: start;
      -ms-flex-align: start;
      flex-align: flex-start;
    }

    .hooper-track-1-vehicle {
      width: 55%;
      margin: auto;
      transform: none !important;
      .hooper-slide {
        width: 100% !important;
      }
    }

    .hooper-track-2-vehicles {
      width: 80%;
      margin: auto;
      transform: none !important;
      .hooper-slide {
        width: 50% !important;
      }
    }

    &:not(.full-width) {
      .hooper-list {
        @include breakpoint(large) {
          border-radius: $gallery-border-radius;
          -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
        }
      }
    }
  }

  figure {
    margin: 0 auto;
    width: 100%;
  }

  img {
    width: 100%;
  }
}

.vehicle-premium-carousel__shape-nav {
  display: none;
  border-style: solid;
  border-width: 0 30rem 13rem 0;
  border-color: transparent $color-primary transparent transparent;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  @include breakpoint(large) {
    display: $gallery-shape-nav-display;
  }
}

.vehicle-premium-carousel {
  overflow: hidden;

  .hooper-slide {
    &[aria-hidden="true"] {
      visibility: visible;
      opacity: .5;

      .vehicle-premium__button-more {
        visibility: hidden;
      }
    }

    .vehicle-premium-carousel__caption {
      text-align: center;
      margin: 10px;
    }
  }

  ////////////////////////////////////////
  // Hotspot Carousel carousel nav
  .vehicle-premium-carousel__nav-button {
    @include center(y);
    display: none;
    border: none;
    color: $gallery-nav-button-color;
    cursor: pointer;
    font-size: rem(40);
    position: absolute;
    top: 300px;
    @include breakpoint(large) {
      top: 350px;
    }
    @include breakpoint(xlarge) {
      top: 400px;
    }
    @include breakpoint(xxlarge) {
      top: 450px;
    }
    //top: 0;
    //height: $gallery-nav-button-height;
    transition-duration: 0.3s;
    opacity: $gallery-nav-button-opacity;
    height: auto;
    width: $gallery-nav-button-width;
    z-index: 2;

    .icon {
      color: $vehicle-premium-nav-button-color;
      display: inline-flex;
      width: 100%;
      align-items: center;
      border-radius: $gallery-nav-button-icon-radius;
      height: $gallery-nav-button-icon-height;
    }

    @media screen and (min-width: 900px) {
      display: block;
    }
  }

  .vehicle-premium-carousel__nav-button:hover {
    opacity: 1;
  }

  .vehicle-premium-carousel__nav-button[disabled] {
    opacity: 0;
  }

  .vehicle-premium-carousel__nav-button--prev {
    // left: $gallery-nav-button-prev-left;
    right: $gallery-nav-button-prev-right;
    //transform: $gallery-nav-button-prev-transform;
    @media screen and (min-width: 900px) {
      left: 15%;
    }
    @media screen and (min-width: 1150px) {
      left: 18%;
    }
    @media screen and (min-width: 1500px) {
      left: 21%;
    }

    .icon {
      justify-content: flex-end;
      transition-duration: 0.3s;
      padding-right: $gallery-nav-button-icon-padding-right;
    }

    &:hover {
      // transform: $gallery-nav-button-prev-hover-transform;
    }

    &:hover .icon {
      // padding-right: $gallery-nav-button-icon-padding-right-hover;
    }
  }

  .vehicle-premium-carousel__nav-button--next {
    @media screen and (min-width: 900px) {
      right: 15%;
    }
    @media screen and (min-width: 1150px) {
      right: 18%;
    }
    @media screen and (min-width: 1500px) {
      right: 21%;
    }
    // transform: $gallery-nav-button-next-transform;

    .icon {
      transition-duration: 0.3s;
      padding-left: $gallery-nav-button-icon-padding-left;
    }

    &:hover {
      // transform: $gallery-nav-button-next-hover-transform;
    }

    &:hover .icon {
      // padding-left: $gallery-nav-button-icon-padding-left-hover;
    }
  }
}

.vehicle-premium {
  @include xy-grid;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .vehicle-premium__content {
    width: 100%;
    position: relative;

    img {
      min-width: 100%;
      height: auto;
    }

    @include breakpoint(large) {
      @include xy-cell(10);
    }

    .vehicle-premium-name {
      display: none;
      text-align: center;
      color: $black;

      padding: 20px 8px 0px 8px;
      font-family: $font-heading-m-font-family;
      font-size: $font-heading-m-font-size-small;
      line-height: normal;
      font-weight: $font-heading-m-font-weight;
      letter-spacing: $font-heading-m-letter-spacing;

      @include breakpoint(large) {
        padding: 20px 16px 0px 16px;
        font-family: $font-heading-l-font-family;
        font-size: $font-heading-l-font-size-large;
        line-height: normal;
        font-weight: $font-heading-l-font-weight;
        letter-spacing: $font-heading-l-letter-spacing;
      }
    }

    .vehicle-premium-price {
      display: none;
      font-family: $font-body-font-family;
      font-size: $font-heading-xs-font-size;
      line-height: $font-heading-xs-line-height;
      letter-spacing: $font-heading-xs-letter-spacing;
      weight: $font-body-font-weight;
      padding: 8px;
      text-align: center;
      color: $black;
    }

    .vehicle-premium-description-displacements {
      display: none;
      flex-wrap: wrap;
      flex-direction: row;
      margin: 0 auto;
      justify-content: center;
      padding-top: 28px;
      gap: 16px;

      .vehicle-premium-displacement {
        color: $color-primary;
        font-family: $font-button-s-font-family;
        font-size: $font-displacements-font-size;
        line-height: $font-displacements-line-height;
        padding: $displacement-btn-padding;
        weight: $displacement-btn-weight;
        border: 1px solid $color-primary;
        border-radius: $button-m-radius;
        letter-spacing: $font-button-s-letter-spacing;
        background-color: $white;
        text-decoration: none;

        i {
          display: inline-block;
          font-size: $font-displacements-icon-size;
          width: 10px;
        }
      }
      .vehicle-premium-displacement:hover {
        color: $white;
        background-color: $color-primary;
      }
    }
  }
}

