/**
 * Debug classes only
*/

.debug-panel {
  padding: 20px;
  border: 1px darkblue dashed;

  .close {
    display: none;
  }
}
