$form-field-checkbox-size: rem(21);
$form-field-radio-checkmark-size: rem(9);
$form-field-checkbox-select-size: rem(30);


.custom-checkbox {

  input[type="checkbox"] {
    opacity: 0;
  }

  label {
    position: relative;
    display: inline-block;
    @include font-body-s;
    font-weight: 600;
    line-height: 2;
    cursor: pointer;

    /*16px width of fake checkbox + 6px distance between fake checkbox and text*/
    padding-left: 30px;

    &::before,
    &::after {
      position: absolute;
      content: "";

      /*Needed for the line-height to take effect*/
      display: inline-block;
    }

    /*Outer box of the fake checkbox*/
    &::before {
      height: $form-field-checkbox-size;
      width: $form-field-checkbox-size;

      border: $form-field-border-bottom-width solid $form-field-border-color;
      left: 0;

      /*(24px line-height - 16px height of fake checkbox) / 2 - 1px for the border
       *to vertically center it.
       */
      top: 3px;
    }

    /*Checkmark of the fake checkbox*/
    &::after {
      height: 5px;
      width: 9px;
      border-left: 2px solid;
      border-bottom: 2px solid;
      border-color: $color-primary;

      transform: rotate(-45deg);

      left: 7px;
      top: 10px;
    }
  }

  /*Hide the checkmark by default*/
  input[type="checkbox"] + label::after {
    content: none;
  }

  /*Unhide on the checked state*/
  input[type="checkbox"]:checked + label::after {
    content: "";
  }

  /*Adding focus styles on the outer-box of the fake checkbox*/
  input[type="checkbox"]:focus + label::before {
    [data-whatintent="keyboard"] & {
      outline: $general-outline-width solid $general-outline-color-on-light;
      outline-offset: 1px;
    }
  }

  input[type="checkbox"].is-invalid + label::before{
    border-color: $color-error;
    border-width: .2rem;
  }

  &.custom-checkbox--select{
    label {
      width: $form-field-checkbox-select-size;
      height: $form-field-checkbox-select-size;
      padding: 0;
      margin: 0;

      &::after {
        @include font-icon();
        height: auto;
        width: auto;
        background: transparent;
        color: $color-on-primary;
        left: 4px;
        top: 4px;
        font-size: 2rem;
        transform:none;
        border: none;
      }

      @extend .icon-plus;

      &:before{
        @include font-icon();
        color: $color-on-primary;
        top: 0;
        background-color: $color-primary;
        border:none;
        width: $form-field-checkbox-select-size;
        height: $form-field-checkbox-select-size;
        left: 0;
        font-size: 2rem;
        transform:none;
        border: none;
        padding-top: 4px;
        text-align: center;
      }
    }

    input[type="checkbox"]:checked + label::before {
      background-color: $color-primary;
    }

    input[type="checkbox"]:checked + label {
      @extend .icon-close;
    }

    input[type="checkbox"]:checked + label::before {
      background-color: $form-selected-true-color;
      color: $white;
    }

  }
}
