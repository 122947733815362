// to do refactor global modal
.modal--dialog {
  .modal-dialog {
    width: 100%;
    margin: auto;

    &.modal-sm {
      @include breakpoint(medium) {
        width: 350px;
      }
    }

    &.modal-md {
      @include breakpoint(medium) {
        width: 600px;
      }
    }

    &.modal-xl {
      @include breakpoint(large) {
        width: 1000px;
      }
    }
  }

  .modal-header--visually-hidden {
    /* BOOTSTRAP STYLES OVERIDE */
    height: 0;
    padding: 0;

    .modal-title {
      /* BOOTSTRAP STYLES OVERIDE */
      @extend .show-for-sr;
    }

    .close {
      z-index: 9;
      margin-top: 0;
      margin-right: 0;
    }
  }
}
