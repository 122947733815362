// BCalendar style + some adjustments
.b-calendar {
  display: inline-flex;
  border: solid 1px $color-gray-calendar;

  .b-calendar-header {
    display: flex;
    margin-bottom: 0.25rem;
  }
  // to hide calendar-header
  .sr-only {
    @extend .show-for-sr;
  }

  .b-calendar-nav {
    height: 0;
    svg {
      height: 0;
    }
  }

  .form-control {
    background-color: inherit;
    border-top: 0.1rem solid $color-gray-lighter;
    display: flex;
    flex-direction: column;
    padding: 0;
    border-bottom: 0 !important;
    header {
      align-items: center;
      background-color: $color-icon-benefit-community-calendar;
      color: $white;
      display: flex;
      font-family: $font-heading-m-font-family;
      font-size: 1.8rem;
      font-weight: 700;
      justify-content: space-between;
      line-height: 2.8rem;
      padding: 2rem 2.4rem;
      text-align: start;
      text-transform: capitalize;
    }
    .b-calendar-nav {
      display: flex;
      gap: 1.6rem;
      height: auto;
      line-height: 1.5rem;
      .btn {
        color: $white;
        cursor: pointer;
        flex-basis: 0;
        flex-grow: 1;
        line-height: 1px;
        &[aria-disabled="true"] {
          color: $white;
          cursor: default;
          opacity: 0.6;
          pointer-events: none;
        }
        svg {
          font-size: 2.4rem;
          height: auto;
        }
      }
    }
    .col {
      flex-basis: 0;
      flex-grow: 1;
      font-size: 1.2rem;
      text-transform: uppercase;
      &.text-truncate {
        color: $color-gray;
        padding: 0.4rem;
      }
    }
    footer {
      display: none;
    }
  }

  .text-center {
    text-align: center;
  }

  .row {
    font-weight: 400;
    margin: 0;
    padding: 1.6rem 1.6rem 1.1rem;
  }

  output {
    padding: 0.25rem;
    font-size: 80%;
    &.readonly {
      background-color: $color-gray;
      opacity: 1;
    }
  }

  .b-calendar-footer {
    margin-top: 0.5rem;
  }

  .b-calendar-grid {
    padding: 0;
    margin: 0;
    overflow: hidden;
    .row {
      flex-wrap: nowrap;
    }
  }

  .b-calendar-grid-caption {
    padding: 0.25rem;
  }

  .b-calendar-grid-body {
    display: flex;
    flex-direction: column;
    font-weight: 400;
    gap: 1rem;
    padding: 0 1.6rem 1.6rem;
    .row {
      margin: 0;
      padding: 0;
    }
    .col[data-date] {
      .btn {
        align-items: center;
        color: $black;
        cursor: pointer;
        display: flex;
        font-size: 1.3rem;
        height: 4rem;
        justify-content: center;
        line-height: 1;
        padding: 0.9rem 0;
        width: 4rem;
        &:disabled,
        &.disabled,
        &[aria-disabled="true"] {
          color: $color-gray;
          cursor: default;
          pointer-events: none;
        }
        // other month days
        &.text-muted {
          color: $color-gray;
        }
        // month days
        &.text-dark, &.font-weight-bold, &.btn-light {
          color: $black;
        }
        // today
        &.btn-outline-info {
          border: 0.1rem solid #575A5A;
          color: $black;
        }
        // selected date
        &.active {
          background-color: $color-icon-benefit-community-calendar;
          color: $white;
        }
      }
      &.table-info {
        .btn {
          border: 0.2rem solid $color-icon-benefit-community-calendar;
          &:hover {
            background-color: $color-icon-benefit-community-calendar;
            color: $white;
          }
        }
      }
      // days before today
      &.to-be-disabled {
        .btn {
          color: $color-gray;
          cursor: default;
          pointer-events: none;
        }
      }
    }
  }

  .btn {
    &:disabled,
    &.disabled,
    &[aria-disabled="true"] {
      color: $color-gray;
      cursor: default;
      pointer-events: none;
    }
  }
}

// events-calendar style
.events-calendar-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 2.4rem auto 0;
  max-width: 1110px;
  padding: 0;
  width: 100%;

  .calendar-title {
    font-family: $font-heading-l-font-family;
    font-size: $font-heading-l-font-size-large;
    font-style: $font-heading-l-font-style;
    font-weight: $font-heading-l-font-weight;
    line-height: $font-heading-l-line-height-large;
  }

  .calendar-select {
    width: 100%;
    @include breakpoint(medium) {
      width: calc(30% - 30px);
    }

    .custom-select {
      @include font-body;
      border: $form-select-promo-border-width solid $form-select-promo-border-color;
      border-radius: rem(4);
      padding: $form-select-promo-padding-vertical $form-select-promo-padding-horizontal;
    }

    &::after {
      border-right: rem(1) solid $black;
      border-top: rem(1) solid $black;
      height: rem(10);
      right: rem(12);
      top: rem(52);
      width: rem(10);
    }
  }

  .calendar-select-label {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.4;
  }
}

.event-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem 4rem;
  width: 100%;
  .separator {
    border: solid 1px $color-gray-calendar;
    height: rem(16);
    margin: auto rem(20);
    width: 0;
  }
}

.events-calendar {
  background-color: inherit;
  display: flex;
  margin: 6.4rem auto;
  max-width: 1110px;
  padding: 0;
  width: 100%;
  .calendar-container {
    background-color: $white;
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .calendar-text-container {
    padding: 40px 20px;
  }
  .calendar-event-title {
    text-transform: lowercase;
    font-size: $font-heading-s-font-size;
    font-weight: $font-heading-m-font-weight;
    font-family: $font-heading;
    line-height: $font-heading-m-line-height-large;
    margin-bottom: 8px;
  }
  .calendar-img-text {
    display: flex;
    margin-top: 2.4rem;
    img {
      width: 222px;
      height: 166.5px;
      margin-right: 16px;
    }
  }
  .calendar-header {
    border-bottom: solid 1px $color-gray-calendar;
    width: 100%;
  }
  .calendar-event-title::first-letter {
    text-transform: capitalize;
  }
  .calendar-event-text {
    font-family: $font-body;
    font-weight: $font-body-font-weight;
    font-size: $font-heading-s-font-size;
    line-height: $font-heading-ms-line-height;
  }
  .icon-text .icon-text__title {
    font-family: $font-body;
    font-size: $font-body-xs-font-size;
    font-weight: $font-heading-xxs-font-weight;
    line-height: $font-body-xs-line-height;
    text-transform: uppercase;
  }
  .icon-text .icon-text__icon.icon-motorbike,
  .icon-text .icon-text__icon.icon-pin {
    font-size: rem(24);
    margin-bottom: 0;
  }
  .icon-text {
    align-items: center;
    display: flex;
    gap: rem(8);
    min-height: 3.2rem;
    padding: 0 rem(8);
  }
  .calendar-benefit {
    align-items: center;
    margin-bottom: rem(16);
    margin-top: rem(12);
    display: flex;
    width: fit-content;
    .redText {
      background-color: $color-background-benefit-community-calendar;
      display: flex;
      margin-right: rem(4);
      .icon-text .icon-text__title {
        color: $color-primary-alt;
      }
      i, .icon-info-fill {
        color: $color-icon-benefit-community-calendar;
      }
    }
    .whiteText {
      background-color: $black;
      display: flex;
      margin-right: rem(4);
      .icon-text .icon-text__title,
      i, .icon-info-fill,
      {
        color: $white;
      }
    }
    .icon-info-fill {
      font-size: rem(20);
      padding-top: 2px;
    }
    .tooltip {
      height: 3.2rem;
      width: 3.2rem;
      .tooltip__content {
        background-color: rgba(0, 0, 0, 0.6);
        transform: translate3d(-121px, -30px, 10px);
        color: $white;
        font-size: $font-body-xs-font-size;
        text-align: center;
        line-height: 15px;
        font-weight: $font-quote-font-weight;
        font-family: $font-body;
        text-transform: unset;
        padding: 8px;
        height: fit-content;
        width: 240px;
        &:after {
          border-top: 9px solid rgba(0, 0, 0, 0.6);
        }
      }
    }
    i {
      font-size: rem(24);
      margin-bottom: 0;
      color: $color-icon-benefit-community-calendar;
      height: rem(24);
    }
  }
  .calendar-info-header {
    display: flex;
    margin-bottom: rem(12.5);
    .calendar-date,
    .calendar-location {
      text-transform: uppercase;
      font-size: $font-body-xs-font-size;
      font-weight: $font-heading-xxs-font-weight;
      font-family: $font-body;
      line-height: $font-body-xs-line-height;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .calendar-date {
      margin-left: rem(4);
      .icon-calendar {
        display: flex;
        font-size: 20px;
        color: $color-icon-header-community-calendar;
        margin-right: 8px;
      }
    }
    .calendar-location {
      .icon-pin {
        display: flex;
        font-size: 20px;
        color: $color-icon-header-community-calendar;
        margin-left: 4px;
        margin-right: 8px;
      }
    }
  }
  .end-date-space {
    margin-left: 3px;
  }
}
.calendar-footer {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  min-height: rem(45);
}
.calendar-cta {
  display: flex;
  flex-direction: column;
  font-family: $font-body;
  font-size: $font-heading-xs-font-size;
  font-weight: $font-heading-xs-font-weight;
  line-height: $font-heading-xs-line-height;
  text-transform: uppercase;
  a.button.button--medium.button--text {
    margin: 0;
    min-height: rem(40);
    padding: 0;
  }
  .calendar-ext-link {
    align-items: center;
    display: flex;
    .ext-link-text {
      letter-spacing: 0;
      text-decoration: underline;
      text-transform: none;
    }
    .ext-link-icon {
      color: $color-primary;
      font-size: 2.4rem;
      margin-left: 4px;
    }
  }
  .calendar-event-link {
    display: flex;
    .separator {
      display: inline-flex;
    }
  }
}

// no events
.calendar-no-event {
  padding-top: 3rem;
  .no-event-header {
    border-bottom: solid 1px $color-gray-calendar;
    display: flex;
    justify-content: center;
    .icon-hours-fill {
      font-size: 60px;
      color: $color-gray-calendar;
      margin-bottom: 16px;
    }
  }
  .calendar-no-event__container {
    text-align: center;
    .calendar-no-event__title {
      font-size: $font-heading-m-font-size-large;
      font-weight: $font-heading-m-font-weight;
      font-family: $font-heading;
      line-height: $font-heading-m-line-height-large;
      margin-top: 16px;
      margin-bottom: 16px;
    }
    .calendar-no-event__text {
      font-family: $font-body;
      font-weight: $font-body-font-weight;
      font-size: $font-heading-s-font-size;
      line-height: $font-heading-ms-line-height;
    }
  }
  .calendar-no-event-mail {
    font-weight: bold;
  }
}
.calendar-no-event-mobile {
  padding: 2rem 4rem;
  .end-date-space {
    word-break: break-word;
  }
}
.calendar-number-page {
  font-size: 1.3rem;
  margin-right: 24px;
  margin-left: 24px;
}
.calendar-arrow {
  align-items: center;
  display: flex;
  font-size: $font-body-xs-font-size;
  font-family: $font-body;
  font-weight: $font-button-m-font-weight;
  justify-content: flex-end;
  line-height: $font-heading-xs-line-height;
  min-height: rem(40);
  padding: 0 rem(8);
  width: fit-content;
  a {
    cursor: pointer;
  }
  .i-disabled {
    color: rgba(0, 0, 0, 0.3);
  }
  i:before {
    font-size: 2.4rem;
    line-height: 1.5;
  }
}

//OVER 1440px
@media screen and (min-width: 1440px) and (max-width: 1779px) {
  .event-container {
    border-right: solid 1px $color-gray-calendar;
    border-top: solid 1px $color-gray-calendar;
    border-bottom: solid 1px $color-gray-calendar;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1339px) {
  .event-container {
    border-right: solid 1px $color-gray-calendar;
    border-top: solid 1px $color-gray-calendar;
    border-bottom: solid 1px $color-gray-calendar;
  }
}

// range where events-calendar has no margin
@media screen and (min-width: 1023px) and (max-width: 1142px) {
  .events-calendar-header {
    margin: 2.4rem 1.6rem;
    width: auto;
  }
  .events-calendar {
    margin: 6.4rem 1.6rem;
    width: auto;
  }
}

//TABLET
@media screen and (min-width: 641px) and (max-width: 1023px) {
  .event-container-desktop {
    display: none;
  }
  .events-calendar-header {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: rem(24) rem(20) rem(20);
    width: auto;
  }
  .calendar-title {
    text-align: center;
  }
  .event-container {
    padding: 2rem 8rem 0;
  }
  .b-calendar-inner.d-inline-block {
    width: 100% !important;
    padding: 15px;
    padding-left: 80px;
    padding-right: 80px;
  }
  .events-calendar {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0 2rem 1.5rem;
    .calendar-container {
      display: flex;
      flex-direction: column;
      width: auto;
    }
  }
}

//MOBILE
@media screen and (max-width: 678px) {
  .event-container-desktop {
    display: none;
  }
  .events-calendar-header {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: rem(24) rem(20) rem(20);
    width: auto;
  }
  .calendar-title {
    text-align: center;
  }
  .b-calendar-inner.d-inline-block {
    width: 100% !important;
  }
  .events-calendar {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0 2rem 1.5rem;
    .calendar-container {
      display: flex;
      flex-direction: column;
      width: auto;
    }
  }
}

// PIAGGIO-8774: content visibility with zoom 400%
@media screen and (max-width: 320px) {
  .b-calendar-grid-body {
    .col[data-date] {
      .btn {
        width: 3.4rem !important;
      }
    }
  }
}

//modal
.calendar-modal-header {
  background-color: $white !important;
  padding-bottom: 0;
  height: 35px;
  .close {
    background-color: $white !important;
    i {
      color: $black;
    }
  }
}
.calendar-modal-body--no-padding {
  .c-cta {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 16px;
    margin-bottom: 16px;
    .calendar-cta {
      padding-top: 0;
      padding-bottom: 0;
      .calendar-event-link {
        flex-direction: column;
      }
    }
  }
  .calendar-header {
    border-bottom: solid 1px $color-gray-calendar;
  }
  .calendar-footer {
    flex-direction: column;
    align-items: flex-start
  }
  .calendar-arrow {
    background-color: #FFFFFFE5;
    bottom: 1.5rem;
    padding: 0.4rem 1rem;
    position: fixed;
    right: 1.5rem;
    z-index: 1;
  }
  overscroll-behavior: auto;
  padding-top: 16px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  background-color: $white !important;
  .hotspot__modal-content {
    max-height: calc(100vh - 5rem);  // header height
    overflow-y: auto;
  }
  .event-container {
    padding: 0 !important;
  }
  .calendar-no-event {
    padding: 1.5rem;
  }
  .event-detail {
    padding-bottom: 16px;
  }
  .calendar-event-title {
    margin-bottom: 16px;
    margin-top: 16px;
  }
  .calendar-img-text {
    flex-direction: column;
    img {
      width: 100%;
    }
  }
  .calendar-benefit {
    width: 100%;
  }
  .calendar-info-header {
    display: flex;
    flex-direction: column;
    margin-top: 0 !important;
  }
  .separator {
    display: none;
  }
  .calendar-info-header {
    text-transform: uppercase;
    font-size: $font-body-xs-font-size;
    font-weight: $font-heading-xxs-font-weight;
    font-family: $font-body;
    line-height: 15px;
    i {
      font-size: 20px;
      color: $color-icon-header-community-calendar;
      margin-right: 8px;
    }
    .calendar-date,
    .calendar-location {
      margin-bottom: rem(12);
      display: flex;
      align-items: center;
    }
  }
  .calendar-benefit {
    padding-bottom: rem(16);
    display: flex;
    flex-direction: column;
    gap: 4px;
    .redText {
      background-color: $color-background-benefit-community-calendar;
      display: flex;
      margin-right: rem(4);
      justify-content: space-between;
      .icon-text .icon-text__title {
        color: $color-primary-alt;
        text-transform: uppercase;
        font-size: $font-body-xs-font-size;
        font-weight: $font-heading-xxs-font-weight;
        font-family: $font-body;
        line-height: 15px;
      }
      i, .icon-info-fill {
        color: $color-icon-benefit-community-calendar;
      }
      .icon-text {
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }
    .whiteText {
      background-color: $black;
      display: flex;
      margin-right: rem(4);
      justify-content: space-between;
      .icon-text .icon-text__title,
      i, .icon-info-fill,
      {
        color: $white;
      }
      .icon-text .icon-text__title {
        text-transform: uppercase;
        font-size: $font-body-xs-font-size;
        font-weight: $font-heading-xxs-font-weight;
        font-family: $font-body;
        line-height: 15px;
      }
      .icon-text {
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }
    .icon-info-fill {
      font-size: rem(20);
      padding-top: 2px;
    }
    .tooltip {
      height: 3.2rem;
      width: 3.2rem;
      .tooltip__content {
        background-color: rgba(0, 0, 0, 0.6);
        color: $white;
        font-family: $font-body;
        font-size: $font-body-xs-font-size;
        font-weight: $font-quote-font-weight;
        height: fit-content;
        left: 0;
        line-height: 15px;
        min-width: 10rem;
        padding: 8px;
        text-align: center;
        text-transform: unset;
        transform: translate3d(-117.5px, -45px, 10px);
        top: 4.5rem;
        width: 112px;
        word-wrap: break-word;
        &:after {
          border-bottom: 9px solid transparent;
          border-left: 9px solid rgba(0, 0, 0, 0.6);
          border-top: 9px solid transparent;
          right: -23%;
          left: auto;
          top: 25%;
        }
      }
    }
    i {
      font-size: rem(24);
      margin-bottom: 0;
      color: $color-icon-benefit-community-calendar;
      height: rem(24);
    }
  }
  .calendar-img-text {
    img {
      margin-top: 16px;
    }
  }
  .calendar-event-title {
    //text-transform: lowercase;
    font-size: $font-heading-s-font-size;
    font-weight: $font-heading-m-font-weight;
    font-family: $font-heading;
    line-height: $font-heading-m-line-height-large;
  }
  .calendar-event-text {
    font-family: $font-body;
    font-weight: $font-body-font-weight;
    font-size: $font-heading-s-font-size;
    line-height: $font-heading-ms-line-height;
  }
}

.calendar__modal-dialog {
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;

  .modal-body {
    overflow: unset !important;
    padding: 2.4rem;
  }
}
