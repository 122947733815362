// N.B.: some classes added inside the html template
// are inherited from send-vin-keyboard.scs

.membership-card-object {
  display: flex;
  flex-direction: row;
  gap: 30px;

}

.membership-card-img {
  //background-color: $black !important; // temporary, to be removed
  height: 155px;
  min-width: 255px !important; // temporary
}

.membership-card-desc-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .membership-card-desc {
    margin-bottom: 21px;
  }
  .membership-card-cta {
    background-color: $color-primary;
    border: 2px solid $color-primary;
    color: $white;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    height: 50px;
    letter-spacing: .1em;
    text-transform: uppercase;
    transition: .3s;
    width: 220px;
    &:hover {
      color: $color-primary !important;
      background-color: inherit !important;
    }
  }
}


.modal {
  .membership__modal-dialog {
    .modal-content {
      background-color: rgba(0, 0, 0, 0.7);
    }
  }
}


// MOBILE
@media screen and (max-width: 640px)  {
  .membership-card-img {
    height: unset;
  }
  .membership-card-object {
    align-items: center;
    flex-direction: column;
    gap: 16px;
  }
  .membership-card-desc-container {
    align-items: center;
    .membership-card-desc {
      margin-bottom: 16px;
    }
  }
  .modal {
    .modal-body.membership-modal-body--no-padding {
      margin: 116px auto;
    }
  }
}

//TABLET
@media screen and (min-width: 641px) and (max-width: 1023px) {
  .modal {
    .modal-body.membership-modal-body--no-padding {
      margin: 163px auto;
    }
    .membership__modal-dialog {
      .modal-content {
        height: 100vh;
      }
    }
  }
}

//over 1024px
@media screen and (min-width: 1025px) {
  .modal {
    .modal-body.membership-modal-body--no-padding {
      margin: 10% auto;
    }
    .membership__modal-dialog {
      .modal-content {
        min-width: 127%;
        height: 100vh;
      }
    }
  }
}

//1024px
@media screen and (width: 1024px) {
  .modal {
    .modal-body.membership-modal-body--no-padding {
      margin: 163px auto;
    }
    .membership__modal-dialog {
      .modal-content {
        width: 102%;
        height: 100vh;
      }
    }
  }
}
