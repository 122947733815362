.floating-shortcuts {
  &.not-visible {
    display: none;
  }
  .menu {
    opacity: 0;
    transition: 500ms;
    border-radius: $floating-shortcuts-menu-border-radius;
    background-color: $floating-shortcuts-menu-background-color;
    box-shadow: $floating-shortcuts-menu-box-shadow;
    backdrop-filter: blur($floating-shortcuts-menu-backdrop-filter);
    position: fixed;
    min-width: rem(280);
    display: flex;
    flex-direction: column;
    z-index: 1001;
    bottom: rem(25);
    left: 50%;
    transform: translateX(-50%);
    padding: rem(6);
    -webkit-tap-highlight-color: transparent;
    pointer-events: none;
    @include breakpoint(medium) {
      width: fit-content;
      flex-direction: row;
      min-width: unset;
    }

    &.menu-active {
      opacity: 1;
      transition: 500ms;
      pointer-events: all;
    }

    &.open {
      @media screen and (max-width: 640px) {
        animation: row-to-column forwards;
        animation-duration: 0ms;
        animation-delay: 200ms;
      }
    }

    &:not(.open) {
      @media screen and (max-width: 640px) {
        animation: column-to-row forwards;
        animation-duration: 0ms;
        animation-delay: 0ms;
      }
    }

    .link {
      cursor: pointer;
      border-radius: $floating-shortcuts-button-border-radius;
      background-color: $floating-shortcuts-button-background-color;
      width: 100%;
      height: rem(50);
      opacity: 1;
      transition: 600ms;
      @include breakpoint(medium) {
        width: rem(180);
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 rem(6) 0 0;
      }

      button, a {
        width: 100%;
      }

      .menu-title {
        max-width: rem(130);
        color: $floating-shortcuts-button-title-color;
        font-family: $floating-shortcuts-button-title-font-family !important;
        font-size: $floating-shortcuts-button-title-font-size;
        font-weight: $floating-shortcuts-button-title-font-weight;
        line-height: $floating-shortcuts-button-title-line-height;
        letter-spacing: $floating-shortcuts-button-title-letter-spacing;
        text-transform: $floating-shortcuts-button-title-text-transform !important;
        font-style: $floating-shortcuts-button-title-font-style;
      }

      &__row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &--left {
          display: flex;
          gap: rem(5);
          align-items: center;
          margin-left: rem(16);

          i {
            color: $floating-shortcuts-button-title-color;
            font-size: rem(24);
          }
        }

        .icon-arrow-dx {
          margin-right: rem(16);

          &:before {
            color: $floating-shortcuts-button-icon-arrow-color;
            font-size: $floating-shortcuts-button-icon-arrow-font-size;
            font-weight: $floating-shortcuts-button-icon-arrow-font-weight;
          }
        }
      }

      i {
        &::before {
          display: block;
        }
      }

      &:hover {
        //button Style on hover
        background-color: $floating-shortcuts-button-background-color-hover;

        i {
          &::before {
            color: $floating-shortcuts-button-title-color-hover;
          }
        }

        .menu-title {
          color: $floating-shortcuts-button-title-color-hover;
        }

        .icon-arrow-dx {
          &:before {
            color: $floating-shortcuts-button-title-color-hover;
          }
        }
      }

      &.collapse {
        @media screen and (max-width: 640px) {
          width: 0;
          opacity: 0;
        }
        animation: floating-menu-close-mobile forwards;
        animation-duration: 0ms;
        animation-delay: 0ms;
        @include breakpoint(medium) {
          animation: floating-menu-close forwards;
          animation-duration: 300ms;
          animation-delay: 0ms;
        }
      }

      &:not(.collapse) {
        animation: floating-menu-open-mobile forwards;
        animation-duration: 0ms;
        animation-delay: 0ms;
        @include breakpoint(medium) {
          animation: floating-menu-open forwards;
          animation-duration: 300ms;
          animation-delay: 0ms;
        }
      }

      @media screen and (max-width: 640px) {
        &.flex-row-btn {
          margin: 0 0.6rem 0 0 !important
        }
      }
    }

    .action-menu-button {
      cursor: pointer;
      display: flex;
      width: 100%;
      height: rem(50);
      margin-left: auto;
      border-radius: $floating-shortcuts-action-menu-button-border-radius;
      background-color: $floating-shortcuts-action-menu-button-background-color;
      @include breakpoint(medium) {
        width: rem(50);
      }

      .close-icon, .icon-action-menu {
        font-size: $floating-shortcuts-close-icon-font-size;
        margin: rem(13);
        color: $floating-shortcuts-close-icon-color;

        ::before {
          font-weight: $floating-shortcuts-close-icon-font-weight !important;
        }
      }

      .close-icon {
        transform: rotate(45deg);
      }

      @media screen and (max-width: 640px) {
        &.close {
          width: 100%;

          .close-button-text {
            font-size: $floating-shortcuts-close-button-font-size;
            color: $floating-shortcuts-close-button-color;
            font-family: $floating-shortcuts-button-title-font-family !important;
            font-weight: $floating-shortcuts-button-title-font-weight;
            line-height: $floating-shortcuts-button-title-line-height;
            letter-spacing: $floating-shortcuts-button-title-letter-spacing;
            text-transform: $floating-shortcuts-button-title-text-transform !important;
            font-style: $floating-shortcuts-button-title-font-style;
            height: min-content;
            margin: auto 0 auto rem(24);
          }

          .close-icon {
            margin-left: auto;
            margin-right: rem(13);
          }
        }
        &.open {
          animation: action-menu-button-open forwards;
          animation-duration: 0ms;
          animation-delay: 0ms;
          margin-left: auto;
        }
      }
    }
  }
  // PIAGGIO-9042: content visibility with zoom 400%
  &.floating-zoomed {
    display: none;
  }
}

//////// DESKTOP ANIMATIONS ////////
@keyframes floating-menu-close {
   0%    { opacity: 1; }
   100%  { opacity: 0; width: 0; display: none; pointer-events: none; margin: 0 }
 }

@keyframes floating-menu-open {
  0%    { opacity: 0; width: 0; display: none; }
  100%  { opacity: 1; }
}

//////// MOBILE ANIMATIONS ////////
@keyframes floating-menu-close-mobile {
  0%    { opacity: 1; }
  100%  { opacity: 0; display: none; }
}

@keyframes floating-menu-open-mobile {
  0%    { opacity: 0; display: none; }
  100%  { opacity: 1; width: 100%; margin: 0 0 rem(6) 0; }
}

@keyframes action-menu-button-open {
  0%    { width: 100%; opacity: 0;}
  50%   {width: 0; display: none;}
  100%  { display: flex; opacity: 1; width: rem(50); }
}

@keyframes column-to-row {
  0%    { flex-direction: column; }
  100%  { flex-direction: row; }
}

@keyframes row-to-column {
  0%    { flex-direction: row; }
  100%  { flex-direction: column; }
}