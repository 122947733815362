.carouselV3 + .section,
.hero-heading + .section,
.hero-heading--title-below + .section,
.editorial-cover + .section,
.gallery--full + .section {
  padding-top: rem(20);

  @include breakpoint(large) {
    padding-top: rem(30);
  }
}


.section.section-editorial {
  padding-top: rem(60) !important;
  padding-bottom: rem(60) !important;
  @include breakpoint(large) {
    padding-top: rem(60) !important;
    padding-bottom: rem(60) !important;
  }
}

.section-group > .section {
  padding-top: 0;
  padding-bottom: 0;
}

.section-group,
.section {
  position: relative;
  z-index: 1;
  //border: 1px solid paleturquoise;

  padding-top: rem(10);
  padding-bottom: rem(10);


  @include breakpoint(large) {
    padding-top: rem(15);
    padding-bottom: rem(15);
  }

  &.section--tertiary-1-accessories { // TODO: CAPIRE SE METTERE IL PADDING-BOTTOM DENTRO HOOPER
    padding-bottom: rem(20);
    @include breakpoint(large) {
      padding-bottom: rem(30);
    }
  }

  background-color: transparent; //$color-background
  color: $color-on-background;

  &.section--primary {
    background-color: $section-wrapper-primary-background-color;
    color: $section-wrapper-primary-color;
    /*@include breakpoint(large) {
      border-radius: $section-border-radius;
      margin-left: $general-body-padding;
      margin-right: $general-body-padding;
    }*/
  }

  &.section--secondary {
    background-color: $section-wrapper-secondary-background-color;
    color: $section-wrapper-secondary-color;

    &.section--horizontal-right, &.section--horizontal-left {
      border-radius: $editorial-border-radius;

      & + .section--horizontal-right, & + .section--horizontal-left {
        border-radius: 0;
      }
    }

    /*@include breakpoint(large) {
      border-radius: $section-border-radius;
      margin-left: $general-body-padding;
      margin-right: $general-body-padding;
    }*/
  }

  &.section--bgwhite {
    background-color: $color-surface;
  }
  &.section--bggray {
    background-color: #e9e9e9;
  }

  &.cookiebot-table {
    padding: 0;
  }

  &.section--tertiary-1 {
    background-color: $section-wrapper-tertiary-1-background-color;
    color: $section-wrapper-tertiary-1-color;
    /*@include breakpoint(large) {
      border-radius: $section-border-radius;
      margin-left: $general-body-padding;
      margin-right: $general-body-padding;
    }*/
  }

  &.section--tertiary-4 {
    background-color: $section-wrapper-accessories-background-color;
    color: $section-wrapper-accessories-color;
    /*@include breakpoint(large) {
      border-radius: $section-border-radius;
      margin-left: $general-body-padding;
      margin-right: $general-body-padding;
    }*/
  }

  &.section--tertiary-1-accessories {
    @if $section-wrapper-accessories-background-size {
      background-image: linear-gradient(
              $section-wrapper-accessories-background-color,
              $section-wrapper-accessories-background-color
      );
      background-position: top;
      background-size: 100% 50%;
      background-repeat: no-repeat;
      color: $section-wrapper-accessories-color;
    } @else {
      background-color: $section-wrapper-accessories-background-color;
      color: $section-wrapper-accessories-color;
      /*@include breakpoint(large) {
        border-radius: $section-border-radius;
        margin-left: $general-body-padding;
        margin-right: $general-body-padding;
      }*/
    }
  }

  @if ($editorial-background-decoration) {
    &.section--secondary {
      &.section--horizontal-left, &.section--horizontal-right {
        overflow-x: hidden;

        .section__container {
          position: relative;

          &:before {
            @extend .icon-background-decoration:before;
            color: $editorial-background-decoration-color;
            position: relative;
            padding-bottom: 42px;
            font-family: $font-family-icons;
            font-size: $editorial-background-decoration-font-size;
            transform: translateX(-50%);
            left: 50%;
            @if ($editorial-background-decoration-hide-mobile) {
            display: none;
          } @else {
              display: inline-block;
            }
            @include breakpoint(large) {
              position: absolute;
              display: block;
              top: -16px;
              transform: none;
            }
          }
        }

        & + .section--horizontal-right, & + .section--horizontal-left {
          .section__container {
            &:before {
              display: none;
            }
          }
        }
      }

      &.section--horizontal-right {
        .section__container {
          &:before {
            @include breakpoint(large) {
              right: auto;
              left: $editorial-background-decoration-right-position;
            }
          }
        }
      }

      &.section--horizontal-left {
        .section__container {
          &:before {
            @include breakpoint(large) {
              right: $editorial-background-decoration-right-position;
              left: auto;
            }
          }
        }
      }
    }
  }

  .section__container {
    //background-color: cornsilk;

    &:not(.section__container--full) {
      @include xy-grid-container();
    }

    &.section__container--full-small {
      padding: 0;
    }

    &.section__container--extra-margin {
      padding: $section-margin-small;

      @include breakpoint(large) {
        padding: $section-margin-large;
      }
    }
  }
}

// custom two-columns-community-area
.section-community {
  padding: 1.5rem;
  @include breakpoint(medium) {
    padding: 4rem;
  }
  @include breakpoint(large) {
    padding: 4rem 6rem;
  }
  @include breakpoint(xlarge) {
    padding: 4rem 12.8rem;
  }
  .grid-x {
    @include breakpoint(xlarge) {
      justify-content: space-around;
    }
  }
  .section, .section__container {
    padding: 0 !important;
  }
}
