#fieldset--appointmentDate{
  .tooltip {
    width: auto;
    height: auto;
    line-height: 1;
    margin-left: 1rem;
  }

  label {
    display: flex;
  }
}

.customDatepicker {

  -webkit-appearance: none;
  font-size: 1.3rem;
  line-height: 1.5;

  font-family: Open Sans, Arial, Helvetica, sans-serif;
  -moz-appearance: none;
  appearance: none;
  background-color: white;
  border-bottom: none !important;
  /*border-left: 0rem solid rgba(104, 102, 98, 0.3);
  border-right: 0rem solid rgba(104, 102, 98, 0.3);
  border-top: 0rem solid rgba(104, 102, 98, 0.3);*/
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  min-height: 3.4rem;
  /*padding: 0.3rem 3.5rem 0.3rem 0.3rem;*/
  width: 100%;

  #appointment-date-cd1 {
    font-size: 1.3rem;
    font-weight: bold;
    color: black !important;
  }

  .datepicker {
    padding: 1rem;
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 0;
    max-width: 200px;
    -webkit-box-shadow: 0px 0px 7px 0px lightgrey;
    box-shadow: 0px 0px 7px 0px lightgrey;


  }

  .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover, .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover, .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
    border: 1px solid $color-primary !important;
  }

  .vdp-datepicker__calendar .cell {
    display: inline-block;
    padding: 0 !important;
    width: 14.285714285714286%;
    height: 30px;
    line-height: 30px;
    text-align: center;
    vertical-align: middle;
    border: 1px solid transparent;
  }

  .wrapper::after {
    content: "\f116";
    position: absolute;
    top: 1rem;
    right: 0.6rem;
    font-family: piaggio-icons, aprilia-icons, moto-guzzi-icons, vespa-icons, piaggiocommercial-icons, serif ;
    font-style: normal;
    /*font-weight: normal !important;*/
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    font-size: medium;
    color: #686662;
  }

  .cell.day {
    border-radius: 5px;
    transition: ease 0.5s;
    font-size: 12px;
    padding: 0 !important;
    /*color: brown;
    @include themify{
      color: theme(color-dark);
    } */
  }

  .cell.day.disabled {
    color: lightgrey;
    /*@include themify{
      color: theme(color-disabled);
    }*/
  }

  .cell.day {
    &.selected {
      color: white;
      /*@include themify{
        color: theme(color-white);
      }*/
    }
  }

  .day-header {
    text-transform: uppercase;
    font-size: 12px;
    color: $color-primary;
  }


}

.vdp-datepicker__calendar .cell.selected {
  background: $color-primary !important;
}

.vdp-datepicker__calendar {
  width: 200px;
}



