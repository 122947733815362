.tabs {
  .nav-tabs {
    .nav-item {
      display: inline-block;
    }

    .nav-link {
      display: inline-block;

      &.disabled {
        cursor: not-allowed;
        outline: none;
        opacity: .7;
      }
    }
  }

  .tab-content > .tab-pane {
    display: none;
  }

  .tab-pane {
    transition: opacity 0.15s linear;
    opacity: 0;
    z-index: 0;

    &.active {
      display: block;
      opacity: 1;
      z-index: 1;
    }
  }
}

.nav-tabs-styled {
  .nav-tabs {
    @include xy-cell(12, $output: true, $gutters: 0);
    display: flex;
    justify-content: space-between;

    .nav-item {
      display: flex;
      text-align: center;
      width: 100%;
    }

    .icon-text__icon {
      margin-bottom: rem(10);
    }

    .icon-text__title {
      // @extend .show-for-sr;
      white-space: normal !important;
      position: static !important;
      @include breakpoint(small only) {
        font-size: rem(12);
      }
    }

    .nav-link {
      @include font-heading-s();
      border-bottom: 2px solid $editorial-tabs-nav-link-border-color;
      color: $editorial-tabs-nav-link-color;
      text-decoration: none;
      padding-bottom: rem(8);
      padding-top: rem(8);
      width: 100%;

      position: relative;

      &.active .icon-text__icon,
      &:hover .icon-text__icon {
        color: $editorial-tabs-nav-link-active-color;
      }

      &:after {
        transition: all 0.2s ease-in-out;
        content: "";
        color: $editorial-tabs-nav-link-active-color;
        border-color: $editorial-tabs-nav-link-active-border-color;
        position: absolute;
        left: 0;
        width: 0;
      }

      &.active:after {
        /*color: $editorial-tabs-nav-link-active-color;
        border-color: $editorial-tabs-nav-link-active-border-color;*/
        background-color: $editorial-tabs-nav-link-active-border-color;
        bottom: -2px;
        height: 2px;
        width: 100%;
      }
    }
  }

  &.tab-content {
    padding-top: rem(10);
  }

  @include breakpoint(large) {
    .nav-tabs {
      @include xy-cell(10);
      margin-right: auto;
      margin-left: auto;


      .icon-text__title {
        position: static !important;
        width: auto;
        height: auto;
      }

      .nav-link {
        padding-bottom: rem(16);
        padding-top: rem(16);
      }
    }

    &.tab-content {
      padding-top: rem(64);
    }
  }
}
