.icon-text {
  text-align: center;
  display: inline-block;

  .icon-text__title {
    @include font-heading-s();
    text-transform: none;
    letter-spacing: normal;

    @include breakpoint(large) {
      position: static !important;
      width: auto;
      height: auto;
    }
  }

  .icon-text__subtitle {
    @include font-body-s();
    text-transform: none;
    letter-spacing: normal;
  }

  .icon-text__icon {
    display: block;
    font-size: rem(40);

    @include breakpoint(large) {
      margin-bottom: rem(6);
    }
  }
}

a:hover {
  .icon-text__icon {
    color: $icon-text-icon-color-hover;
  }
}
