////////////////////////////////////////
// Foundation
@import "settings";
@import "~foundation-sites/scss/foundation";

@include foundation-global-styles;
@include foundation-xy-grid-classes;
@include foundation-float-classes;
@include foundation-visibility-classes;
@include foundation-flex-grid;

////////////////////////////////////////
// Foundation Helpers
@include foundation-float-classes;
@include foundation-flex-classes;


////////////////////////////////////////
// Hooper (Carousel)
@import "~hooper/dist/hooper";

////////////////////////////////////////
// Modals
$enable-prefers-reduced-motion-media-query: false !default;
$enable-rounded: true !default;
$enable-transitions: true !default;
$line-height-base: 1.5 !default;
$zindex-modal: 1050 !default;
$border-width: 0 !default;
$border-radius-lg: 0.3rem !default;
$gray-300: #dee2e6 !default;
$border-color: $gray-300 !default;
$enable-shadows: false !default;
$zindex-modal-backdrop: 1040 !default;
// Padding applied to the modal body
$modal-inner-padding: 1rem !default;

$modal-dialog-margin: 0.5rem !default;
$modal-dialog-margin-y-sm-up: 1.75rem !default;

$modal-title-line-height: $line-height-base !default;

$modal-content-color: null !default;
$modal-content-bg: $white !default;
$modal-content-border-color: rgba($black, 0.2) !default;
$modal-content-border-width: $border-width !default;
$modal-content-border-radius: $border-radius-lg !default;
$modal-content-box-shadow-xs: 0 0.25rem 0.5rem rgba($black, 0.5) !default;
$modal-content-box-shadow-sm-up: 0 0.5rem 1rem rgba($black, 0.5) !default;

$modal-backdrop-bg: $black !default;
$modal-backdrop-opacity: 0.5 !default;
$modal-header-border-color: $border-color !default;
$modal-footer-border-color: $modal-header-border-color !default;
$modal-header-border-width: $modal-content-border-width !default;
$modal-footer-border-width: $modal-header-border-width !default;
$modal-header-padding-y: 1rem !default;
$modal-header-padding-x: 1rem !default;
$modal-header-padding: $modal-header-padding-y $modal-header-padding-x !default; // Keep this for backwards compatibility

$modal-xl: 1140px !default;
$modal-lg: 800px !default;
$modal-md: 500px !default;
$modal-sm: 300px !default;

$modal-scale-transform: scale(1.02) !default;
$modal-fade-transform: translate(0, -50px) !default;
$modal-show-transform: none !default;
$modal-transition: transform 0.3s ease-out !default;
$modal-backdrop-opacity: 0.5;
$modal-content-inner-border-radius: 0;
$modal-footer-margin-between: 0;

//@import '~bootstrap/scss/mixins';
//@import '~bootstrap/scss/functions';
//@import '~bootstrap/scss/variables';

@import "~bootstrap/scss/modal";
@import "~bootstrap-vue/src/components/modal/index";
@import "../components/choose-date"; //temporary

//@import '~bootstrap/scss/mixins/caret';
//@import '~bootstrap/scss/dropdown';
//@import '~bootstrap-vue/src/components/dropdown/index';


//@import '~bootstrap-vue/src/components/calendar/index';

