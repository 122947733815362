/*
.nav-tabs {
  @include xy-cell(10);
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: space-between;


  .nav-item {
    text-align: center;
    width: 100%;
  }

  .icon {
    display: block;
    font-size: rem(40);
    margin-bottom: rem(10);
  }

  .nav-link {
    @include font-heading-s();
    border-bottom: 2px solid $editorial-tabs-nav-link-border-color;
    color: $editorial-tabs-nav-link-color;
    text-decoration: none;
    padding-bottom: rem(16);
    width: 100%;

    &.active {
      color: $editorial-tabs-nav-link-active-color;
      border-color: $editorial-tabs-nav-link-active-border-color;
    }

  }

}

.tab-content {
  padding-top: rem(64);

}
*/
.tabs {
  padding-left: rem(10);
  padding-right: rem(10);
  margin-bottom: rem(46);
  @include breakpoint(large){
    margin-bottom: rem(60);
  }
  .editorial {
    padding-left: 0;
    padding-right: 0;
  }
}



