/* ===WEB FONTS MANAGEMENT=== */

/**
* Use this mixin to easely generate a @font-face rule.
* It uses the font-path property provided by postcss rucksack
* to generate bulletproof src sets in @font-face
*/
/*@mixin font-face($family, $path, $weight: 400) {
  @font-face {
    font-display: swap;
    font-family: $family;
    font-path: $path;
    font-weight: $weight;
  }
}*/

@mixin font-face($font-family, $url, $weight: 400) {
  @font-face {
    font-family: "#{$font-family}";
    src: url("#{$url}.eot");
    src: url("#{$url}.eot?#iefix") format("embedded-opentype"),
      url("#{$url}.woff") format("woff"), url("#{$url}.ttf") format("truetype"),
      url("#{$url}.svg") format("svg");
    font-weight: $weight;
    font-style: normal;
    font-display: swap;
  }
}

/**
 * @param $fonts-map
 * Generating @font-face rule from the given $fonts map
 * You can just update the font map to add a new font-face rule
 */
@mixin font-face-generator($fonts-map, $path) {
  //@debug "Font path is `#{$path}` ";
  //@debug "My Font family is `#{$fonts-map}` ";

  @each $font-key, $font-val in $fonts-map {
    @if (map-get($font-val, weights)) {
      $_family: map-get($font-val, family);
      //@debug "Family is `#{$_family}` ";

      @each $weight-key, $weight-val in map-get($font-val, weights) {
        $_path: "#{$path}/#{$_family}-#{$weight-key}";
        //@debug "PATH is `#{$_path}` ";
        @include font-face(#{$_family}, #{$_path}, $weight-val);
      }
    } @else {
      $font-fallback: map-get($font-val, family);
    }
  }
}

/**
 * Sets the font for this weight.
 * @param $family the font family to set.
 * @param $weight one of 'bolder', 'heavy', 'bold', 'semibold', 'normal' (default),
 * 'light', 'lighter' or 'thin'
 */
@mixin application-font($family, $weight: "normal") {
  // We use this kind of mapping in order to keep keywords
  // bound to the Google Web Fonts standard imports.
  @if ($weight == "bolder") {
    $weight: 900;
  }

  @if ($weight == "heavy") {
    $weight: 800;
  }

  @if ($weight == "bold") {
    $weight: 600;
  }

  @if ($weight == "semibold") {
    $weight: 500;
  }

  @if ($weight == "normal") {
    $weight: 400;
  }

  @if ($weight == "light") {
    $weight: 300;
  }

  @if ($weight == "lighter") {
    $weight: 200;
  }

  @if ($weight == "thin") {
    $weight: 100;
  }

  font-family: $family;
  font-weight: $weight;
}

/**
 * Sets the secondary fonts for this weight.
 * @param $weight one of "light", "normal" (default), "bold" or "bolder" (if provided by font).
 * Take care not to use this mixin to apply the primary font,
 * whenever it is already declared for the body element and inherited from it.
 */
@mixin font($font, $weight: "normal") {
  $fontfamily: font-family($font);
  @include application-font($fontfamily, $weight);
}

/**
 * Applies the font smoothing
 * Used for better cross browser support
 * Ref: https://maximilianhoffmann.com/posts/better-font-rendering-on-osx
 */
@mixin font-smoothing($value: on) {
  @if $value == on {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

@mixin add-font-styling(
  $family,
  $size,
  $weight: normal,
  $line-height: normal,
  $font-style: normal
) {
  font-size: $size;
  line-height: $line-height;
  font-style: $font-style;
  font-weight: $weight;
  font-family: $family, $font-family-system-sanserif;
}

/**
 * Absolute centering an element
 */
@mixin center($xy: xy) {
  position: absolute;
  @if $xy == xy {
    transform: translateX(-50%) translateY(-50%);
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
  } @else if $xy == x {
    transform: translateX(-50%);
    left: 50%;
    right: auto;
  } @else if $xy == y {
    transform: translateY(-50%);
    top: 50%;
    bottom: auto;
  }
}

/**
 * Absolute positioning an element to cover its area
 */
@mixin cover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/**
 * Make class for country
 */
$flag-icon-css-path: "../img/flags" !default;

@mixin flag-icon($country) {
  .flag-icon-#{$country} {
    background-image: url("#{$flag-icon-css-path}/#{$country}.svg");
  }
}

/**
 * Form input placeholder text
 * example:
   input,
	 textarea {
		@include input-placeholder {
			color: $grey;
		}
	}
*/

@mixin input-placeholder {
  &.placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

/**
 *  Element maintaining the assigned aspect ratio
 */
@mixin aspect-ratio($width, $height, $content: ".content") {
  position: relative;
  &:before {
    display: block;
    content: " ";
    width: 100%;
    height: 100%;
    padding-top: ($height / $width) * 100%;
  }

  > #{$content} {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
}

/**
 * Bootstrap
 */

// stylelint-disable property-blacklist
@mixin transition($transition...) {
  @if $enable-transitions {
    @if length($transition) == 0 {
      transition: $transition-base;
    } @else {
      transition: $transition;
    }
  }

  @if $enable-prefers-reduced-motion-media-query {
    @media (prefers-reduced-motion: reduce) {
      transition: none;
    }
  }
}

@mixin box-shadow($shadow...) {
  @if $enable-shadows {
    $result: ();

    @if (length($shadow) == 1) {
      // We can pass `@include box-shadow(none);`
      $result: $shadow;
    } @else {
      // Filter to avoid invalid properties for example `box-shadow: none, 1px 1px black;`
      @for $i from 1 through length($shadow) {
        @if nth($shadow, $i) != "none" {
          $result: append($result, nth($shadow, $i), "comma");
        }
      }
    }
    @if (length($result) > 0) {
      box-shadow: $result;
    }
  }
}

@mixin border-top-radius($radius) {
  @if $enable-rounded {
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
  }
}

@mixin border-bottom-radius($radius) {
  @if $enable-rounded {
    border-bottom-right-radius: $radius;
    border-bottom-left-radius: $radius;
  }
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: "") {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin font-icon() {
  font-family: $font-family-icons;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

//Custom motoplex
@mixin margin-motoplex {
  margin-top: 4.5rem;
  margin-bottom: 1.5rem;
}
