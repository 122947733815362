.dealer-selector {
  position: relative;

  .vue-map {
    height: calc(100vh - 205px);
  }

  .vue-map-container {
    height: auto !important;
    .vue-map {
      position: relative !important;
    }
  }

  .dealer-selector__map-selected-dealer {
    margin-left: -2.4rem;
    margin-right: -2.4rem;
    position: relative;
    overflow: hidden;
  }

  .dealer-selector__search {
    margin: rem(15);
  }

  .dealer-selector__icon-search {
    z-index: 1;
    position: absolute;
    right: rem(20);
    top: rem(22);
    font-size: rem(20);
  }

  .dealer-selector__selected-dealer {
    padding: 1rem;
    background-color: $color-background;
    position: absolute;
    bottom: -100%;
    right: 0;
    left: 0;
    border-top: 1px solid $color-gray;
    border-bottom: 1px solid $color-gray;
    transition: bottom 300ms cubic-bezier(.16, .04, .03, .94);
    visibility: hidden;

    &.is-active {
      bottom: 0;
      visibility: visible;
    }
  }

  .dealer-selector__close {
    position: absolute;
    right: 0;
    top: 0;
    i[class*=" icon-"]:before {
    }
  }

  .dealer-selector__selected-dealer__info{
    padding: 0 0 1rem;
  }

  .dealer-selector__selected-dealer__info__name{
    padding: 0 0 1rem;
    display: block;
    @include font-heading-s;
  }

  .dealer-selector__selected-dealer__info__address{
    padding: 0 0 1rem;
    display: block;
    @include font-body;
  }
}

.pac-container {
  z-index: 2000;
  position: absolute !important;
}