.form-group {
  margin-bottom: rem(15);
  position: relative;

  &.is-invalid .invalid-feedback,
  .is-invalid ~ .invalid-feedback,
  .is-invalid ~ .invalid-tooltip {
    display: block;
  }

  &.is-valid .valid-feedback,
  .is-valid ~ .valid-feedback,
  .is-valid ~ .valid-tooltip {
    display: block;
  }
  .valid-feedback,
  .invalid-feedback {
    display: none;
    @include font-body-xs;
    margin: rem(5) 0;
    width: 100%;
  }

  .invalid-feedback {
    color: $color-error;
    @extend .icon-warning-fill;

    &::before {
      @include font-icon();
      position: relative;
      top: rem(1);
    }
  }

  .valid-feedback {
    color: green;
    /*position: absolute;
    top: 0;
    right: 0;
    width: auto;*/
    @extend .icon-tick;

    &::before {
      @include font-icon();
      top: rem(1);
    }
  }
}

.form-text {
  @include font-body-xs;
  margin: rem(5) 0;
  display: block;
}

.checkbox__info-text {
  font-size: $form-checkbox-info-text-font-size;
  font-weight: $form-checkbox-info-text-font-weight;
  font-style: $form-checkbox-info-text-font-style;
  line-height: $form-checkbox-info-text-line-height;
}