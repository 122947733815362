.piaggio-form-field__label {
  font-size: $form-item-label-font-size;
  font-weight: $form-item-label-font-weight;
  font-style: $form-item-label-font-style;
  line-height: $form-item-label-line-height;
}

.piaggio-form-field__error {
  width: max-content;
  height: 20px;
  font-size: $form-item-error-font-size;
  font-weight: $form-item-error-font-weight;
  font-style: $form-item-error-font-style;
  line-height: $form-item-error-line-height;
  color: $form-item-error-text-color;
}