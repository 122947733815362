/**
 * Design System for Piaggio Group
*/

#root > .card-highlight {
  width: 100%;

  @include breakpoint(large) {
    width: 400px;
  }
}

.card-highlight {
  @include breakpoint(large) {
    padding-left: $card-highlight-content-overlap;
    //max-width: 400px;

    @if $card-highlight-border-radius {
      border-radius: $card-highlight-border-radius;
      overflow: hidden;
    }
  }

  & > div {
    max-width: 100%;
    padding-top: 75%; /* 4:3 Aspect Ratio */
    position: relative;

    @include breakpoint(large) {
      padding-top: 137.64%;
      height: 100%;
    }

    @if $card-highlight-content-background == transparent {
      &::before {
        content: "";
        background: rgba($black, 0.5);
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
      }
    }
  }

  a {
    display: block;
    text-decoration: none;
    width: 100%;
    height: auto;
  }

  .card-highlight__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;

      @include breakpoint(large) {
        height: 100%;
      }
    }
  }

  .card-highlight__content {
    background-color: $card-highlight-content-background;
    text-align: $card-highlight-content-text-align;
    border-radius: $card-highlight-border-radius;

    position: absolute;
    bottom: $card-highlight-content-bottom;
    right: $card-highlight-content-right;
    left: $card-highlight-content-left;

    @if $card-highlight-content-position == "center" {
      @include center();
    }

    @if $card-highlight-content-overlap != 0 {
      @include breakpoint(large) {
        transform: translate(-$card-highlight-content-overlap, -25%);
        width: 100%;
        left: 0;
        right: auto;
        max-width: 260px;
      }
    }
  }

  .card-highlight__text {
    padding: $space-size-s;
    @include font-heading-s;
    color: $card-highlight-text-color;
  }

  .card-highlight__cta {
    padding: $space-size-s;
    @include font-button-text-m;
    color: $card-highlight-cta-color;
    text-transform: uppercase;
    background: $card-highlight-cta-color-background;
    text-align: center;
    border-radius: $card-highlight-border-radius;

    @if $card-highlight-content-plus {
      i {
        @extend .icon-plus;
        display: inline-block;
        position: absolute;
        bottom: $space-size-xs;
        right: $space-size-xs;
        font-size: rem(20px);
        color: $card-highlight-text-color;
      }
      span {
        display: none;
      }
    }


    @if $card-highlight-content-arrow {
      i {
        @extend .icon-read-more;
        display: inline-block;
        position: absolute;
        bottom: $space-size-xs;
        right: $space-size-xs;
        font-size: rem(22px);
        color: $card-highlight-text-color;
      }
    }


    @include breakpoint(large) {
      display: inline-block;
    }
  }
}
