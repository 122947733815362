.financial-simulator {
  // CONFIGURATION
  .financial-simulator__config {
    @include xy-grid();
    margin-bottom: rem(52);
  }

  .financial-simulator__config__control {
    border-top: 1px solid $color-gray-lighter;
    border-bottom: 1px solid $color-gray-lighter;
    padding: rem(5) 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }

  .financial-simulator__config__control
    + .financial-simulator__config__control {
    border-top: none;
  }

  .financial-simulator__config__model {
    @include xy-cell(1 of 1, $gutter-type: padding, $gutters: 0);

    @include breakpoint(medium) {
      @include xy-cell(1 of 2, $gutter-type: padding);
    }

    @include breakpoint(large) {
      @include xy-cell(1 of 3, $gutter-type: padding);
    }

    .image {
      margin-bottom: rem(20);

      @include breakpoint(large) {
        margin-bottom: rem(50);
      }
    }

    .financial-simulator__config__control {
      padding: rem(5) rem(10);

      @include breakpoint(medium) {
        padding-left: 0;
        padding-right: 0;
      }

      .text-area {
        width: 100%;
        display: flex;
        align-items: center;
      }
    }

    .definition-list dt,
    .definition-list dd {
      padding: rem(15) rem(10);
      line-height: 2;

      @include breakpoint(medium) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .financial-simulator__config__plan {
    @include xy-cell(1 of 1, $gutters: 0, $gutter-type: padding);
    background-color: rgba($color-gray-lighter, 0.8);

    @include breakpoint(medium) {
      @include xy-cell(1 of 2, $gutters: 0, $gutter-type: padding);
    }

    @include breakpoint(large) {
      @include xy-cell(1 of 3, $gutters: 0, $gutter-type: padding);
    }

    & > fieldset > * {
      padding: map-get($grid-margin-gutters, "small") / 2;

      @include breakpoint(large) {
        padding: map-get($grid-margin-gutters, "medium") / 2;
      }
    }

    .financial-simulator__config__control {
      flex-direction: column;
      align-items: baseline;

      &.selected {
        //border: 1px solid;
      }
    }

    .plan__name {
      display: inline-block;
      position: relative;
      padding-right: 15px;
    }
  }

  .financial-simulator__config__rate {
    @include xy-cell(
      1 of 1,
      $gutter-type: padding,
      $gutter-position: left right top bottom
    );
    background-color: $color-surface;
    @include breakpoint(large) {
      @include xy-cell(1 of 3, $gutter-type: padding);
    }

    .financial-simulator__config__rate_result {
      border-radius: 100%;
      background-color: $color-primary;
      color: $color-on-primary;
      position: relative;
      width: rem(220);
      height: rem(220);
      margin: 1rem auto 1rem;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-evenly;

      @include breakpoint(large) {
        margin-top: -10%;
        width: rem(250);
        height: rem(250);
        margin: 0 auto rem(50);
      }

      .rate {
        @include font-heading-xs;
        text-transform: uppercase;
        text-align: center;
      }
      .duration {
        @include font-body;
      }
      .cost {
        @include font-heading-xl;
      }
      .tan-taeg {
        @include font-heading-xxs;
      }
    }

    .financial-simulator__config__rate_config {
      margin-top: rem(25);
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;

      .duration__label {
        position: relative;
        padding-right: 15px;

        .duration__tooltip {}
      }

    }
  }

  // SUMMARY

  .financial-simulator__summary {
    background-color: $color-surface;
    @include xy-grid();
    margin-bottom: rem(52);
  }

  .financial-simulator__summary__details {
    @include xy-cell(1 of 1, $gutter-position: left right top bottom);

    @include breakpoint(large) {
      @include xy-cell(2 of 3, $gutter-position: left right top bottom);
    }

    .financial-simulator__summary__details__list {
      display: flex;
      flex-direction: column;

      @include breakpoint(large) {
        /*flex-direction: row;
        align-items: baseline;
        justify-content: space-between;*/
      }

      dl {
        @include breakpoint(large) {
          //width: 49%;
        }
      }
    }
  }

  .financial-simulator__summary__notes {
    margin-top: rem(30);
    .text--heading-s {
      margin-bottom: rem(20);
    }

    .financial-simulator__summary__logo {
      margin: rem(20) auto;
      display: block;
    }
  }

  .financial-simulator__summary__cta {
    @include xy-cell(1 of 1, $gutter-position: left right top bottom);
    position: relative;

    @include breakpoint(large) {
      @include xy-cell(1 of 3, $gutter-position: left right top bottom);
      padding-bottom: 10rem;
    }

    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-around;
    align-items: stretch;

    .button--text {
      margin: 0;
    }

    .button--text {
      max-width: 50%;
    }

    .financial-simulator__summary__logo {
      bottom: rem(15);
      @include center(x);
    }
  }
}
