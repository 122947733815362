.slick-arrow {
  position: absolute;
  z-index: 99;
  padding: 12px;
  transform: translateY(-1px);
  border-top: 1px solid $color-gray-lightest !important;

  i {
    color: $color-primary;
    font-size: 24px;
  }

  i[class^="icon-"]:before {
    vertical-align: middle;
  }

  &.slick-prev {
    background: linear-gradient(to left, transparent, white 40%, white 75%);
    height: 100%;
    left: 0;
  }

  &.slick-next {
    background: linear-gradient(to right, transparent, white 40%, white 75%);
    height: 100%;
    right: 0;
  }
}