.container-scf {
  --scf-box-shadow-dark-color: #f1f1f1;
  --scf-box-shadow-light-color: #f1f1f1;
  --scf-bg-color: #e9e9e9;
  --scf-bg-secondary-color: #e9e9e9;
  --scf-border-color: #686662;
  --scf-error-color: #b00020;
  --scf-success-color: green;
  --scf-button-border-radius: 0;
  --scf-default-font-family: "Roboto Condensed", "Arial",
  Helvetica ", " sans-serif "";
  --scf-title-font-family: "Roboto Condensed", "Arial",
  Helvetica ", " sans-serif "";
  --scf-title-font-weight: 700;
  --scf-title-transform: capitalize;
  --scf-modal-border-radius: 0;
}