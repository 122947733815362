/**
 * Design System for Piaggio Group
*/
@font-face {
  font-family: "aprilia-icons";
  src: url("../fonts/aprilia/aprilia-icons.eot?f134d5b56caac9c65707e57da2674291?#iefix")
      format("embedded-opentype"),
    url("../fonts/aprilia/aprilia-icons.woff2?f134d5b56caac9c65707e57da2674291")
      format("woff2"),
    url("../fonts/aprilia/aprilia-icons.woff?f134d5b56caac9c65707e57da2674291")
      format("woff"),
    url("../fonts/aprilia/aprilia-icons.ttf?f134d5b56caac9c65707e57da2674291")
      format("truetype"),
    url("../fonts/aprilia/aprilia-icons.svg?f134d5b56caac9c65707e57da2674291#aprilia-icons")
      format("svg");
  font-display: swap;
}

@import "tokens-styleguide-aprilia";
@import "tokens-components-aprilia";
