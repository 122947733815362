////////////////////////////////////////
// editorial
.editorial {
  padding-right: rem(10);
  padding-left: rem(10);
  @include xy-grid;
  flex-direction: column-reverse;
  //align-items: center;

  &__title {
    padding: rem(15) 0 0 0;
    color: inherit; //from section
  }

  &__subheading {
    color: inherit; //from section
    margin-top: rem(8);
    font-size: rem(16);
    line-height: rem(20);
    font-style: normal;
    font-family: $font-body;
  }

  &__bold {
    font-weight: 700;
  }

  &__subheading--upper {
    padding: rem(15) 0 0 0;
    margin-bottom: 0;
  }

  &__text {
    @include font-body();
    color: inherit; //from section

    & > * {
      padding: rem(15) 0 0 0;
    }

    ul, ol {
      padding-left: 2rem;
      margin-left: .5rem;
      display: inline-block;
      text-align: left;
    }

    ul li {
      list-style-type: $editorial-list-type;
    }

    ol li {
      list-style-type: decimal;
    }
  }

  &__cta {
    display: flex;
    flex-direction: column;

    .button {
      margin-right: 16px !important;
    }

    @include breakpoint(medium) {
      display: block;
    }

    &.padding-left-right-0 {
      /*margin-left: -$button-m-padding-v !important;
      @include breakpoint(large) {
        margin-left: -$button-m-padding-v !important;
        margin-right: -$button-m-padding-v !important;
      }*/
    }

    &.margin-top__btn-m {
      margin-top: $button-m-padding-v;
    }

    &.margin-bottom__btn-m {
      margin-bottom: 45px;
    }

    span + span {
      padding-top: 8px;
    }
  }

  .editorial__content--align-center {
    text-align: center;
  }

  .editorial__content {
    &.vertical-align {
      height: fit-content;
      margin-top: auto;
      margin-bottom: auto;
      .editorial__cta {
        margin-bottom: 0;
      }
    }
  }

  &__figure {
    margin: 0 auto;
    padding: 0;
    align-self: flex-start;

    img {
      border-radius: $editorial-figure-img-border-radius;
    }
  }

  a:link:not(.button), a:visited:not(.button) {
    // color: $general-link-color;
    color: inherit;
    text-decoration: underline;
  }
}

.disclaimer {
  @include font-body-s;

  p {
    padding: rem(20);
    line-height: 1.3;
    white-space: break-spaces;
  }
}

////////////////////////////////////////
// breakpoint Large
@include breakpoint(large) {
  ////////////////////////////////////////
  // editorial
  .editorial {
    flex-direction: row;
    justify-content: center;

    .editorial__content,
    .editorial__figure {
      @include xy-cell(5);
      &.image-center {
        text-align: center;
      }
    }

    &__title {
      padding-bottom: 0;
    }

    ////////////////////////////////////////
    // image Small
    &--image-small {
      .editorial__content {
        @include xy-cell(6);
      }

      .editorial__figure {
        @include xy-cell(4);
      }
    }

    ////////////////////////////////////////
    // image Medium
    &--image-medium {
      .editorial__content  {
        @include xy-cell(5);
      }
    }

    ////////////////////////////////////////
    // image Large
    &--image-large {
      .editorial__content {
        @include xy-cell(4);
      }

      .editorial__figure {
        @include xy-cell(6);
      }
    }

    ////////////////////////////////////////
    // align right
    &--align-right {
      .editorial__content {
        padding-right: $editorial-align-right-padding-right;
      }
    }

    ////////////////////////////////////////
    // align Left
    &--align-left {
      flex-direction: row-reverse;

      .editorial__content {
        padding-left: $editorial-align-left-padding-left;
      }
    }

    ////////////////////////////////////////
    // align Center && align 2 col
    &--align-center,
    &--align-center-two-col {
      @include xy-grid($direction: vertical);
      align-items: center;
      flex-direction: column-reverse;

      .editorial__content,
      .editorial__figure {
        @include xy-cell(10, $gutter-type: padding);
        margin: 0 auto;
      }
    }

    &--align-center-two-col {
      .editorial__content,
      .editorial__figure {
        @include xy-cell(12, $gutters: 0);
      }

      //.editorial__text {
      //  text-align: left;
      //}

      //.editorial__title,
      .editorial__subtitle {
        text-align: left;
      }
    }

    ////////////////////////////////////////
    // text 2 columns
    &__text--two-columns {
      column-count: 2;
      text-align: left;
      padding-top: 1.5rem;

      p {
        padding-top: 0;
      }
    }
  }
}

//custom PIAGGIO-7759
.motoplex--custom {
  .editorial--align-center-two-col {
    .editorial__figure {
      @include margin-motoplex;
    }
  }
}

// custom two-columns-community-area
.section-community {
  .editorial {
    align-content: flex-start;
    .editorial__content {
      margin: 0;
      padding: 0;
      width: 100%;
      @include breakpoint(large) {
        padding: 0 6.5rem 0 0;
      }
      @include breakpoint(xlarge) {
        padding: 0 12.5rem 0 0;
      }
    }
  }
}

#uuid-4d79dad4-f587-4b42-9b8c-f24469ace1e6{
  .grid-x.align-center {
    display: flex;
    justify-content: center;
  }
}
.editorial__content.editorial__content--align-center {
  .button.button--text.button--medium.button--primary {
    margin-right: 0 !important;
  }
}
