////////////////////////////////////////
// ACCESSIBILITY: VISUAL FOCUS INDICATOR DON'T REMOVE

*:focus {
  outline: $general-outline-width solid $general-outline-color-on-light;
}

////////////////////////////////////////
// BODY
& {
  font-family: $font-body-font-family, $font-family-system-sanserif;
  line-height: 1;
  background-color: $color-background !important;
  color: $color-on-background;
  position: relative;
  scroll-behavior: smooth;
  max-width: rem(1920);
  margin: 0 auto;
}

&:not(.sb-show-main) {

  @if $body-effect {
    &.t-model-version {
      &::before,
      &::after {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        z-index: 0;
        width: 0;
        height: 0;
        border-style: solid;
      }

      &::before {
        left: 0;
        border-color: $color-primary transparent transparent transparent;
        border-width: 109px 312px 0 0;

        @include breakpoint(large) {
          border-width: 950px 384px 0 0;
        }
      }

      &::after {
        right: 0;
        border-color: transparent $color-secondary transparent transparent;
        border-width: 0 217px 217px 0;

        @include breakpoint(large) {
          border-width: 0 421px 109px 0;
        }
      }
    }
    &.t-model-version.has-promo {
      &::before,
      &::after {
        margin-top: rem(49);
      }
    }
  }
}

&.t-model-version.has-promo {
  #mainContent {
    padding-top: rem(44);
  }
}

&.hero-active {
  padding-top: 0 !important;
}

.overflow-hidden,
&.overflow-hidden {
  overflow: hidden !important;
}

.fixed,
&.fixed {
  position: fixed !important;
}

.hidden-scroll,
&.hidden-scroll {
  &.homepage-overlay-active {
    overflow: hidden;
  }
  overflow-y: scroll;
  &::-webkit-scrollbar {
    opacity: 0;
  }
  &.disable-zoom {
    touch-action: manipulation;
  }
}

.overlay-display-none {
  display: none !important;
}

* {
  box-sizing: border-box;
}

.project-version {
  position: absolute;
  bottom: 7px;
  right: 10px;
  top: initial;
  z-index: 9999;
  background: #599900;
  color: black;
  padding: 10px;
  border-radius: 30px;
  font-size: 10px;
}

////////////////////////////////////////
// ACCESSIBILITY UTILITIES

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.show-visually-hidden {
  clip: auto;
  overflow: visible;
}

.debug {
  background-color: greenyellow;
  padding: 1rem;
}
////////////////////////////////////////
// COOKIEBOT TABLE
.CookieDeclaration {
  @include font-body-xs;
  @include breakpoint(large) {
    @include font-body()
  }
}

////////////////////////////////////////
// FIX FOR lozad.js
// https://github.com/ApoorvSaxena/lozad.js/issues/176
img[data-src] {
  min-width: 1px;
  min-height: 1px;
}

//////////////////////////////////////////////////////
// FIX FOR PIAGGIO-7640 - custom feature only for iphone
@media screen and (-webkit-min-device-pixel-ratio:0) {
  select.form-control,
  textarea.form-control,
  input.form-control {
    font-size: 16px;
  }
}

//////////////////////////////////
// FIX FOR SANTANDER FIN SIM
/* TODO: to fix */
.hideCustom {
  display: none !important;
}

/*
TODO: to fix because cause bug on PDP PIAGGIO-7502
&.t-model-version {
  #vue-page-loaded {
    #mainContent {
      section:not(.product-presentation) {
        z-index: -1;
      }
    }
  }
}
 */

////////////////////////////////////////
// use fallback for this countries
/* :root :lang(el), :lang(el),
:root :lang(vi), :lang(vi),
:root :lang(vn), :lang(vn),
:root :lang(ro), :lang(ro),
:root :lang(ko), :lang(ko),
:root :lang(id), :lang(id),
:root :lang(th), :lang(th),
:root :lang(zh), :lang(zh),
:root :lang(bg), :lang(bg),
:root :lang(hr), :lang(hr),
:root :lang(cz), :lang(cz),
:root :lang(cs), :lang(cs) {
  font-family: font-family(fallback-sanserif);

  input::placeholder {
    font-family: font-family(fallback-sanserif);
  }
}*/
