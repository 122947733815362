.recall-campaign-editorial {
  .editorial__title {
    padding-bottom: 1.5rem;
  }
}
.recall-campaign-form {

  .editorial__title {
    text-align: center;
  }

  .editorial__text {
    text-align: center;
    padding-bottom: 1.5rem;
  }

  .form {
    margin: 2.4rem;
    .form-content {
      max-width: 350px;
      margin: auto;
      .button {
        width: 100%;
      }
    }
    .grid-x.align-center {
      justify-content: center !important;
    }
  }

  .d-flex {
    display: flex;
  }
}
.recall-campaign-editorial-icon {
  .editorial__title {
    text-align: center;
    padding: 3.5rem 1.5rem;
  }
}

.recall-result {
  text-align: center;
  margin-bottom: 0px;
  .icon-text i {
    font-size: 7rem;
  }
}

.recall-result.editorial__title {
  text-align: center;
  padding-bottom: 4.5rem;
}
