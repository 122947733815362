////////////////////////////////////////
// Accordion
.accordion {
  padding-right: rem(10);
  padding-left: rem(10);
  button {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    display: flex;
  }
}

////////////////////////////////////////
// Accordion Icon
[aria-expanded|="true"] .accordion__trigger-Icon {
  transform: rotate(-180deg);
}

.accordion__trigger-Icon {
  transition: transform 0.2s cubic-bezier(0.23, 1, 0.32, 1);
  stroke: $color-primary;
}

////////////////////////////////////////
// Accordion heading
.accordion__heading {
  display: none;
}

////////////////////////////////////////
// Accordion content
.accordion__content {
  display: none;
  margin-top: 5px;
  height: 0;
  transition: 150ms ease-out;

  &.is-visible {
    display: block;
    height: auto;
  }
}

////////////////////////////////////////
// Breakpoint large
@include breakpoint(large) {
  .accordion {
    &:not(:last-child) {
      border: none;
    }
  }
  .accordion__content {
    display: block;
    height: auto;
  }
}
