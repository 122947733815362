.contacts-us {
  animation: hide-contacts-menu 1s forwards;
  display: none;
  &.show {
    display: flex;
    backdrop-filter: blur(10px);
    flex-direction: column;
    text-align: left;
    position: fixed;
    top: rem(55);
    left: var(--float-contacts);
    z-index: 1000;
    min-width: rem(254);
    max-width: rem(254);
    width: max-content;
    height: max-content;
    padding: rem(20);
    opacity: 0;
    animation: show-contacts-menu 1s forwards;
    &.has-promo {
      top: rem(100);
    }

    &::after {
      content: "";
      position: absolute;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-top: 9px solid rgba(255, 255, 255, .6);
      left: 50.5%;
      transform: rotate(180deg) translateX(50%);
      bottom: 100%;
      backdrop-filter: blur(10px);
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(255, 255, 255, 0.6);
      backdrop-filter: blur(10px);
    }

    .contact-us-voice {
      font-size: $header-v2-contact-us-voice-font-size;
      padding-left: rem(8);
      padding-bottom: rem(24);
      position: relative;

      span {
        font-size: $navigation-v2-menu-font-size;
        font-weight: $font-quote-font-weight;
        color: $navigation-v2-item-color;
        &:hover {
          opacity: 0.7;
        }
      }

      &:last-child {
        padding-bottom: 0;
      }

      &::after {
        content: "";
        position: absolute;
        height: rem(1);
        width: rem(16);
        background-color: $header-v2-contacts-us-menu-separator;
        top: rem(30);
        left: 0;
      }

      &:last-child::after {
        display: none;
      }

      i {
        display: inline-block;
        opacity: 0;
        transform: translateX(20px);
        transition: opacity 0.3s ease, transform 0.5s ease;
        &::before {
          font-size: rem(10)
        }
      }
      @media screen and (min-width: 1025px) {
        &:hover {
          i {
            opacity: 0.7;
            animation: bounceIn 0.6s forwards;
          }
        }
      }
    }
  }
}

@keyframes show-contacts-menu {
  0% { opacity: 0 }
  100% { opacity: 1; }
}

@keyframes hide-contacts-menu {
  0% { opacity: 1; }
  100% { opacity: 0; }
}