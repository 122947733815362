.comm-support-component {
  border: 1px solid $color-gray-lighter;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 44.5rem;
  padding: 5.6rem;
}

.comm-support-heading {
  font-family: $font-heading-s-font-family;
  .comm-support-title {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }
  .comm-support-subtitle {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2rem;
  }
}

.comm-support-benefits {
  .comm-support-icon {
    .icon-text {
      align-items: center;
      display: flex;
      flex-direction: row;
      gap: 8px;
      padding-top: 4px;
      text-align: left;
      .icon-text__title {
        font-family: $font-body-font-family;
        font-size: 1.3rem;
        font-weight: 400;
        line-height: 1.5rem;
      }
      .icon-text__icon {
        font-size: 2.4rem;
        height: 2.4rem;
        margin: 0;
      }
    }
  }
  .comm-support-separator {
    color: $color-gray-lighter;
    margin-top: 1.6rem;
  }
}

.comm-support-contacts {
  display: flex;
  flex-direction: column;
  gap: 8px;
  .comm-support-label {
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.5rem;
  }
  .comm-support-email {
    color: $color-primary;
    font-size: 1.3rem;
    font-weight: 700;
    justify-content: flex-start;
    line-height: 1.5rem;
    text-decoration: underline;
  }
}

.comm-support-cta {
  a {
    width: 100%;
  }
  .button--outline:hover {
    background-color: $color-primary;
    color: $white;
  }
}

// MOBILE
@media screen and (max-width: 640px) {
  .comm-support-component {
    padding: 2.4rem;
  }
}
