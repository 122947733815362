.carousel {
  
  position: relative;
  //border: 1px solid green;

  ////////////////////////////////////////
  // Carousel arrows
  $carousel-arrows-position-small:0;
  $carousel-arrows-position-medium:0;
  $carousel-arrows-position-large:0;
  $carousel-arrows-position-xlarge:-3rem;

  .carousel__arrows {
    .carousel__arrows__prev,
    .carousel__arrows__next {
      //background-color: royalblue;
      top: rem(12);
      position: absolute;
      z-index: 5;

      @include breakpoint(small down) {
        top: 0;
        bottom: 0;
        color: inherit; // from section

        i {
          &::before {
            opacity: 0;
          }
        }
      }  

      @include breakpoint(medium) {
        font-size: 2rem;
        @include center(y);
      }

      @include breakpoint(large) {
        font-size: 3.8rem;
        padding-left: 0;
        padding-right: 0;
      }
    } 

    .carousel__arrows__prev {
      left: $carousel-arrows-position-small;

      @include breakpoint(medium) {
        left: $carousel-arrows-position-medium;
      }

      @include breakpoint(large) {
        left: $carousel-arrows-position-large;
      }

      @include breakpoint(xlarge) {
        left: $carousel-arrows-position-xlarge;
      }
    }

    .carousel__arrows__next {
      right: $carousel-arrows-position-small;
      

      @include breakpoint(medium) {
        right: $carousel-arrows-position-medium;
      }

      @include breakpoint(large) {
        right: $carousel-arrows-position-large;
      }

      @include breakpoint(xlarge) {
        right: $carousel-arrows-position-xlarge;
      }
    }
  }

  // PIAGGIO-8774: content visibility with zoom 400%
  .carousel__arrows-zoom {
    .carousel__arrows__prev,
    .carousel__arrows__next {
      i {
        &::before {
          opacity: 1;
        }
      }
    }
  }

  ////////////////////////////////////////
  // Carousel dot pagination
}
