// THEME RESET
// -----------
// Personal resets in addition to Normalize.scss
// ===========

// Use border-box for everything
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

// Resets margins and paddings for headers, ul, ol and p
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
p,
blockquote {
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  display: block;
}

// Removes all decimals and discs from li
li {
  list-style: none;
  height: auto;
}

// Completely resets form items
// ----------------------------
// Super hard reset that removes all borders
// and radiuses of all form items.
// ============================
input,
textarea,
button {
  border: 0;
  border-radius: 0;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

// Set Image and Objects to have a max-width of 100%
embed,
iframe,
img,
object,
video {
  max-width: 100%;
  width: auto;
}

// Taming Table layouts
table {
  width: 100%;
  table-layout: fixed;
}
