.piaggio-textarea-container {
  margin-bottom: 8px;
}

.piaggio-textarea__error-icon {
  position: absolute;
  right: 8px;
  top: 8px;
  font-size: 24px;
  color: #A22028;
  margin-top: 2px; /* to align not centered image !! */
}
.piaggio-textarea__success-icon {
  position: absolute;
  right: 8px;
  top: 8px;
  font-size: 24px;
  margin-top: 2px; /* to align not centered image !! */
}
.piaggio-textarea {
  border-radius: $textarea-border-radius;
  width: 100%;
  border: 1px solid black !important;
  height: $textarea-height !important;
  padding: 8px !important;
  font-size: $textarea-text-font-size !important;
  font-weight: $textarea-text-font-weight !important;
  line-height: $textarea-text-line-height !important;
  font-style: $textarea-text-font-style !important;
  caret-color: $color-primary;
  background-color: $color-background;
  resize: none;
}
.piaggio-textarea:focus-within {
  box-shadow: 0 2px $color-primary !important;
}