.mini-hero-form {
  position: relative;
  width: 100%;
  height: 100%;

  @include breakpoint(large) {
    max-width: 140rem;
    padding-top: rem(124);
    margin: 0 auto;
    padding-left: 40px;
  }

  @include breakpoint(medium down) {
    position: relative;
    padding-right: 16px;
    padding-left: 16px;
    top: -155px;
    margin-bottom: 48px;
  }

  .mini-hero-form__container {
    background-color: $color-background;
    padding: 24px 36px;
    @include breakpoint(large) {
      min-width: 530px;
    }
    @include breakpoint(medium down) {
      box-shadow: 0 4px 26px rgba(0, 0, 0, 0.25);
    }
  }

  .editorial__title {
    color: $color-primary;
  }

  .editorial__cta {
    text-align: center;
    padding-top: rem(12);
  }

  .form-group {
    margin-bottom: rem(24);
  }

  .editorial__text {
   & > * {
      padding-top: rem(24);
    }
  }

  form {
    padding-top: rem(24);
  }

  .form-control {
    border-color: $primary-color;
  }
}