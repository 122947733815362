/* Tooltip container */
.form-tooltip {
  cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;
  word-break: keep-all;
}

.form-tooltip .form-tooltip__icon {
  font-size: 16px;
  cursor: pointer;
}

/* Tooltip text */
.form-tooltip .form-tooltip__content {
  visibility: hidden;
  width: max-content;
  max-width: $form-tooltip-max-width;
  border-radius: $form-tooltip-border-radius;
  background-color: rgb(102, 102, 102);
  color: white;
  text-align: left;
  padding: $form-tooltip-padding-y $form-tooltip-padding-x;
  font-size: $form-tooltip-text-font-size;
  font-weight: $form-tooltip-text-font-weight;
  font-style: $form-tooltip-text-font-style;
  line-height: $form-tooltip-text-line-height;

  top: 100%;
  left: -1.5px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.form-tooltip:hover .form-tooltip__content {
  visibility: visible;
}

.form-tooltip .form-tooltip__content::after {
  content: " ";
  position: absolute;
  bottom: 100%;  /* At the top of the tooltip */
  left: 5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent rgb(102, 102, 102) transparent;
}