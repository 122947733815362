@import "modal-dialog";
@import "modal-panel";

.modal-backdrop {
  opacity: 0.2;
}

// *:not(.pac-item) Fix 4 autocomplete gmap
// :not(#CybotCookiebotDialogBody) Fix for Cookiebot Bar
&.modal-open {
  @include breakpoint(small) {
    position: fixed;
  }
  position: static;
  & > *:not([id*="modal_outer"]) > *:not(.pac-item):not(#CybotCookiebotDialogBody):not(.grecaptcha-badge) {
    @include breakpoint(medium) {
      filter: blur(2px);
    }
  }
  .form-group.call-to-action {
    @include breakpoint(small) {
        padding-bottom: rem(90);
      }
    padding-bottom: 0;
  }

}
.grecaptcha-badge {
  z-index: 1000000 !important;
}
.modal {
  .modal-content {
    /* BOOTSTRAP STYLES OVERIDE */
    border-radius: 0;
    background-color: $modal-bg;
    box-shadow: 0 0 50px 0 rgba(124, 124, 124, 0.5);
  }

  .close {
    /* BOOTSTRAP STYLES OVERIDE */
    background-color: $modal-close-bg;
    height: $modal-close-height;
    min-width: $modal-close-width;
    cursor: pointer;

    &:focus {
      outline-offset: -4px;
      outline-color: $modal-close-outline-color;
    }
  }

  .close__icon {
    color: $modal-close-icon-color;
    font-size: 2rem;
    line-height: 2rem;
  }

  .close__label {
    @include font-body-xs;
    display: block;
    color: $modal-close-label-color;
    text-transform: uppercase;
  }

  .modal-body {
    /* BOOTSTRAP STYLES OVERIDE */
    padding: rem(24);
    overscroll-behavior: none;
    overflow-scrolling: touch;
    overflow: auto !important;
    &.modal-body--no-padding {
      padding: 0;
    }



  }

  .modal-header {
    /* BOOTSTRAP STYLES OVERIDE */
    padding-left: rem(24);
  }

  .modal-hero{
    width: 100%;
  }

  .modal-title {
    /* BOOTSTRAP STYLES OVERIDE */
    @include font-heading-m;
  }
}

#marketingToolModalContainer___BV_modal_outer_ {
  // z-index: 0 !important;
}
