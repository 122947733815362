/**
 * Design System for Piaggio Group
*/

// ---- Styleguide ----

// Colors
$black: #000000 !default;
$white: #ffffff !default;

$color-gray-darkest: rgb(39, 37, 31); //#27251F
$color-gray-darker: rgb(104, 102, 98); //#686662
$color-gray: rgb(147, 146, 143); //#93928F
$color-background-gray: rgba(0, 0, 0, 0.6);
$color-gray-light: rgb(219, 219, 219); //#dbdbdb
$color-gray-lighter: rgb(190, 190, 190); //#bebebe
$color-gray-lightest: rgb(233, 233, 233); //#e9e9e9

$color-primary: #ff0000;
$color-primary-alt: #A51419;
$color-primary-accessible: #e7131a;
$color-secondary: $black;
$color-background: $color-gray-lightest;
$color-surface: $white;
$color-error: #b00020;


$color-tertiary-1: $color-primary;
$color-tertiary-2: $color-primary;
$color-tertiary-3: $color-primary;
$color-tertiary-4: $color-primary;

$text-color-black: $black;
$text-color-white: $white;
$text-color-primary: $color-primary;
$text-color-secondary: $color-secondary;
$color-on-gray-lightest: $black;

$color-on-primary: $text-color-white;
$color-on-secondary: $text-color-white;
$color-on-background: $text-color-black;
$color-on-background-primary: #d10000; // più scuro rispetto al $color-primary per aumentare il contrasto con $color-background
$color-on-surface: $text-color-black;
$color-on-surface-primary: $color-primary;
$color-on-error: $text-color-white;

$color-on-tertiary-1: $color-on-primary;
$color-on-tertiary-2: $color-on-primary;
$color-on-tertiary-3: $color-on-primary;
$color-on-tertiary-4: $color-on-primary;

// Spacing
$space-size-xxxs: 2px;
$space-size-xxs: 4px;
$space-size-xs: 8px;
$space-size-s: 12px;
$space-size-m: 16px;
$space-size-l: 24px;
$space-size-xl: 32px;
$space-size-xxl: 40px;
$space-size-xxxl: 80px;

// Typography
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
$font-family-icons: aprilia-icons !important;

$font-family-system-serif: "Times New Roman", Times, serif;
$font-family-system-sanserif: Arial, Helvetica, sans-serif;

$base-fonts-path: "../fonts/aprilia/" !default;
$font0: $font-family-system-sanserif;
$font1: $font-family-system-serif;
$font-heading: "Roboto Condensed";
$font-body: $font-heading;

$fonts: (
    fallback-sanserif: (
        family: #{$font0},
    ),
    fallback-serif: (
        family: #{$font1},
    ),
  heading: (
    family: #{$font-heading},
    weights: (
      "Light": 300,
      "Regular": 400,
      "Bold": 700
    )
  ),
  body: (
    family: #{$font-body},
    weights: (
      "Light": 300,
      "Regular": 400,
      "Bold": 700
    )
  )
);

//@include font-face-generator($fonts, $base-fonts-path);

/* heading-xl font style */
$font-heading-xl-font-family: font-family(heading, $fonts), font-family(fallback-sanserif, $fonts);
$font-heading-hero-content-xl-font-size-small: 44px;
$font-heading-hero-content-xl-font-size-large: 88px;
$font-heading-xl-font-size-small: rem(36); //
$font-heading-xl-font-size-large: rem(50);
$font-heading-xl-font-weight: 700;
$font-heading-hero-content-xl-line-height-small: 44px; //
$font-heading-hero-content-xl-line-height-large: 88px;
$font-heading-xl-line-height-small: rem(48); //
$font-heading-xl-line-height-large: rem(70);
$font-heading-xl-font-style: normal;

/* heading-l font style */
$font-heading-l-font-family: font-family(heading, $fonts), font-family(fallback-sanserif, $fonts);
$font-heading-l-font-size-small: rem(30);
$font-heading-l-font-size-large: rem(32);
$font-heading-l-font-weight: 700;
$font-heading-l-line-height-small: rem(36);
$font-heading-l-line-height-large: rem(40);
$font-heading-l-font-style: normal;

/* heading-ml font style */
$font-heading-ml-font-family: font-family(heading, $fonts), font-family(fallback-sanserif, $fonts);
$font-heading-ml-font-size-small: rem(28);
$font-heading-ml-font-size-large: rem(32);
$font-heading-ml-font-weight: 700;
$font-heading-ml-line-height-small: rem(40);
$font-heading-ml-line-height-large: rem(52);
$font-heading-ml-font-style: normal;

/* heading-m font style */
$font-heading-m-font-family: font-family(heading, $fonts), font-family(fallback-sanserif, $fonts);
$font-heading-m-font-size-small: rem(24);
$font-heading-m-font-size-large: rem(28);
$font-heading-m-font-weight: 700;
$font-heading-m-line-height-small: rem(30);
$font-heading-m-line-height-large: rem(32);
$font-heading-m-font-style: normal;

/* heading-s font style */
$font-heading-s-font-family: font-family(heading, $fonts), font-family(fallback-sanserif, $fonts);
$font-heading-s-font-size: rem(18);
$font-heading-s-font-weight: 700;
$font-heading-s-line-height: rem(24);
$font-heading-s-font-style: normal;

/* heading-ms font style */
$font-heading-ms-font-family: font-family(heading, $fonts), font-family(fallback-sanserif, $fonts);
$font-heading-ms-font-size: rem(14);
$font-heading-ms-font-weight: 700;
$font-heading-ms-line-height: rem(24);
$font-heading-ms-font-style: normal;

/* heading-xs font style */
$font-heading-xs-font-family: font-family(heading, $fonts), font-family(fallback-sanserif, $fonts);
$font-heading-xs-font-size: rem(16);
$font-heading-xs-font-weight: 700;
$font-heading-xs-line-height: rem(20); //
$font-heading-xs-font-style: normal;

/* heading-xxs font style */
$font-heading-xxs-font-family: font-family(heading, $fonts), font-family(fallback-sanserif, $fonts);
$font-heading-xxs-font-size: rem(13);
$font-heading-xxs-font-weight: 700;
$font-heading-xxs-line-height: rem(15);
$font-heading-xxs-font-style: normal;

/* body font style */
$font-body-font-family: font-family(body, $fonts), font-family(fallback-sanserif, $fonts);
$font-body-font-size: rem(16);
$font-body-font-weight: 400;
$font-body-line-height: 1.5;
$font-body-letter-spacing: rem(.4);
$font-body-font-style: normal;

/* body-s font style */
$font-body-s-font-family: font-family(body, $fonts), font-family(fallback-sanserif, $fonts);
$font-body-s-font-size: rem(14);
$font-body-s-font-weight: 400;
$font-body-s-line-height: 1;
$font-body-s-font-style: normal;

/* body-ms font style */
$font-body-ms-font-family: font-family(body, $fonts), font-family(fallback-sanserif, $fonts);
$font-body-ms-font-size: rem(12);
$font-body-ms-font-weight: 500;
$font-body-ms-line-height: 1.5;
$font-body-ms-font-style: normal;

/* body-xs font style */
$font-body-xs-font-family: font-family(body, $fonts), font-family(fallback-sanserif, $fonts);
$font-body-xs-font-size: rem(12);
$font-body-xs-font-weight: 400;
$font-body-xs-line-height: 1.4;
$font-body-xs-font-style: normal;

/* quote font style */
$font-quote-font-family: font-family(body, $fonts), font-family(fallback-sanserif, $fonts);
$font-quote-font-size: rem(26);
$font-quote-font-weight: 400;
$font-quote-line-height: rem(40);
$font-quote-font-style: italic;

/* button-l font style */
$font-button-l-font-family: font-family(body, $fonts), font-family(fallback-sanserif, $fonts);
$font-button-l-font-size: rem(24);
$font-button-l-font-weight: 700;
$font-button-l-line-height: 1;
$font-button-l-font-style: italic;
$font-button-l-letter-spacing: rem(2);

/* button-m font style */
$font-button-m-font-family: font-family(body, $fonts), font-family(fallback-sanserif, $fonts);
$font-button-m-font-size: rem(16);
$font-button-m-font-weight: 700;
$font-button-m-line-height: 1;
$font-button-m-font-style: italic;
$font-button-m-letter-spacing: rem(2);

/* button-s font style */
$font-button-s-font-family: font-family(body, $fonts), font-family(fallback-sanserif, $fonts);
$font-button-s-font-size: rem(13);
$font-button-s-font-weight: 700;
$font-button-s-line-height: 1;
$font-button-s-font-style: normal;
$font-button-s-letter-spacing: rem(2);

/* text button-l font style */
$font-button-text-l-font-family: $font-button-l-font-family;
$font-button-text-l-font-size: $font-button-l-font-size;
$font-button-text-l-font-weight: $font-button-l-font-weight;
$font-button-text-l-line-height: $font-button-l-line-height;
$font-button-text-l-font-style: italic;
$font-button-text-l-letter-spacing: $font-button-l-letter-spacing;

/* text button-m font style */
$font-button-text-m-font-family: $font-button-m-font-family;
$font-button-text-m-font-size: $font-button-m-font-size;
$font-button-text-m-font-weight: $font-button-m-font-weight;
$font-button-text-m-line-height: $font-button-m-line-height;
$font-button-text-m-font-style: italic;
$font-button-text-m-letter-spacing: $font-button-m-letter-spacing;

/* text button-s font style */
$font-button-text-s-font-family: $font-button-s-font-family;
$font-button-text-s-font-size: $font-button-s-font-size;
$font-button-text-s-font-weight: 400;
$font-button-text-s-line-height: $font-button-s-line-height;
$font-button-text-s-font-style: $font-button-s-font-style;
$font-button-text-s-letter-spacing: $font-button-s-letter-spacing;

/* letter spacing styles */
$font-heading-xl-letter-spacing: normal;
$font-heading-xl-letter-spacing-large: normal;
$font-heading-hero-content-xl-letter-spacing: normal;
$font-heading-hero-content-xl-letter-spacing-large: normal;
$font-heading-l-letter-spacing: normal;
$font-heading-l-letter-spacing-large: normal;
$font-heading-m-letter-spacing: normal;
$font-heading-m-letter-spacing-large: normal;
$font-heading-s-letter-spacing: normal;
$font-heading-s-letter-spacing-large: normal;
$font-heading-ms-letter-spacing: normal;
$font-heading-ms-letter-spacing-large: normal;
$font-heading-xs-letter-spacing: normal;
$font-heading-xs-letter-spacing-large: normal;
$font-heading-xxs-letter-spacing: normal;
$font-heading-xxs-letter-spacing-large: normal;
$font-body-letter-spacing: normal;
$font-body-s-letter-spacing: normal;
$font-body-ms-letter-spacing: normal;
$font-body-xs-letter-spacing: normal;
$font-quote-letter-spacing: normal;

////////////////////////////////////////
// HERO-HEADING-COMMUNITY
$hero-heading-community-background-color: $black;

////////////////////////////////////////
// CALENDAR-COMMUNITY
$color-icon-benefit-community-calendar: #A51419;
$color-icon-header-community-calendar: #EC3935;
$color-background-benefit-community-calendar: #F1F1F1;
$color-gray-calendar: #BEBEBC;

////////////////////////////////////////
// VEHICLES/ACCESSORIES CAROUSEL (CarouselV1)
$listing-header-title-space-desktop: 4rem;
$listing-header-title-space-mobile: 3.6rem;
