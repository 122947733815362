.listing-header {
  @include xy-gutters($gutter-type: padding);
  color: inherit; // from section
  margin-bottom: $space-size-l;
  display: flex;

  @include breakpoint(medium) {
    margin-bottom: $space-size-xl;
  }

  flex-direction: column;
  align-items: center;

  @if $listing-header-align == "left" {
    @include breakpoint(medium) {
      align-items: baseline;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .listing-header__heading {
    text-align: $listing-header-align;
    color: inherit;
  }
  .listing-header__note {
    text-align: $listing-header-align;
    color: inherit;
    margin-top: $space-size-xs;
    @include font-body-xs();
  }

  // CarouselV1 without listingTitle
  &.listing-no-title {
    min-height: $listing-header-title-space-mobile;

    @include breakpoint(large) {
      min-height: $listing-header-title-space-desktop;
    }

    .carousel-product__viewall {
      align-items: flex-end;
      display: flex !important;
    }
  }

  // CarouselV1 without listingTitle
  &.listing-no-title {
    .carousel-product__viewall {
      align-items: flex-end;
      display: flex !important;
    }
  }
}
