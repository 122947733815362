.promotion-cta-list {
  .promo-section__container {
    max-width: 100%;
    padding: 0 rem(10);
    @include breakpoint(large) {
      padding: 0;
      margin: 0 auto;
    }

    .promotion-cta-list {
      display: flex;
      gap: 10px;
      list-style: none;
      flex-direction: column;
      text-align: center;
      @include breakpoint(large) {
        width: fit-content;
        flex-direction: row;
        margin: 0 auto;
      }
    }

    .promotion-cta-list li {
      margin: 0 auto;
      @include breakpoint(large) {
        margin: 0;
      }
    }
  }
}
