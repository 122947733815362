.menu-anchor {
  background: $white;
  padding: 0 !important;
  // height: 60px;
  min-height: 60px;
  width: 100%;

  &::after {
    border-bottom: 1px solid $color-gray-lightest;
    content: '';
    width: 100%;
    display: block;
    transform: translateY(-1px);
    // position: relative;
    position: absolute;
    z-index: -1;
  }

  &.is-fixed {
    max-width: rem(1920);
    position: fixed;
    top: rem(69);
    z-index: 3;

    + section {
      padding-top: rem(129)!important;
    }
  }

  &.is-navigation-v2.is-fixed {
    top: rem(119);
  }

  .hooper .hooper-slide[aria-hidden="true"] {
    visibility: visible;
  }

  &.do-not-move-style {
    ul.hooper-track {
      transform: none !important;
      display: flex;
      justify-content: center;
    }
  }

  ul.hooper-track {
    align-items: stretch;
  }

  li.hooper-slide {
    /*
    height: rem(60);
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto !important;

     */
    position: relative;
    height: auto;
  }

  li.hooper-slide.is-current-slide::after {
    content: '';
    display: inline-block;
    border-bottom: 1px solid $color-primary;
    position: absolute;
    bottom: 0;
    width: 48px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 2;
  }

  .hooper.hooper--partial-slide {
    .hooper-slide {
      &.is-next,
      &.is-prev {
        opacity: 1;
        visibility: initial;
      }

      &.is-next {
        // opacity: .2;
      }
    }
  }
}

.menu-anchor__item {
  @include font-heading-s;
  display: flex;
  font-weight: normal;
 // white-space: nowrap;
  align-items: center;
  text-decoration: none;
  flex-direction: column;
  justify-content: center;
  color: $black;
  height: 100%;
  min-height: 60px;
  margin: 0 rem(2);
  white-space: normal;
  text-align: center;
}

.menu-anchor__icon {
  font-size: rem(30);
}

.hooper-prev {
  background: linear-gradient(to left, transparent, white 40%, white 75%);
  padding: 0;

  svg {
    width: 34px;
    height: 34px;
  }

  path:not(:first-of-type) {
    fill: $color-primary;
  }
}

.hooper-next {
  background: linear-gradient(to right, transparent, white 40%, white 75%);
  padding: 0;

  svg {
    width: 34px;
    height: 34px;
  }

  path:not(:first-of-type) {
    fill: $color-primary;
  }
}

.hooper-next.is-disabled, .hooper-prev.is-disabled {
  opacity: 0;
}