.gigya-screen.v2.portrait.aprilia-gigya-app,
.gigya-screen.v2.portrait.motoguzzi-gigya-app,
.gigya-screen.v2.portrait.piaggio-gigya-app {
  .gigya-composite-control-header.reset-password-title {
    color: $text-color-black;
  }
  .gigya-composite-control-header.reset-password-success-title {
    color: $text-color-black;
    font-family: $font-heading-l-font-family;
    font-size: $font-heading-l-font-size-large;
    font-style: $font-heading-l-font-style;
    font-weight: $font-heading-l-font-weight;
    line-height: $font-heading-l-line-height-small;
  }
  .gigya-label-text,
  .gigya-input-password,
  .gigya-required-display,
  .reset-password-success-main-text {
    color: $text-color-black;
    font-family: $font-body-font-family;
  }
  .gigya-composite-control-label.reset-password-description {
    margin-bottom: 24px !important;
  }
  .gigya-password-visible,
  .gigya-password-invisible {
    display: none;
  }
  .gigya-input-password.gigya-error {
    border-radius: 0 !important;
  }
  .gigya-input-submit {
    font-family: $font-body-font-family;
    padding: 0 !important;
  }
  .editorial-icon__text.text--body {
    color: $text-color-black;
    font-family: $font-heading-m-font-family;
    font-size: $font-button-m-font-size;
    font-weight: $font-body-font-weight;
  }
}

//MOBILE
@media screen and (max-width: 640px) {
  .gigya-screen.v2.portrait.aprilia-gigya-app,
  .gigya-screen.v2.portrait.motoguzzi-gigya-app,
  .gigya-screen.v2.portrait.piaggio-gigya-app {
    width: auto;
    .gigya-composite-control.gigya-composite-control-header.login-title.reset-password-success-title {
      margin-bottom: 16px;
    }
  }
}