////////////////////////////////////////
// ACCESSIBILITY: VISUAL FOCUS INDICATOR DON'T REMOVE
[data-whatintent="mouse"] *:focus,
[data-whatintent="touch"] *:focus {
  outline: none !important;
}

[data-whatintent="keyboard"] *:focus {
  outline: $general-outline-width solid $general-outline-color-on-light;
}

[data-whatintent="keyboard"] .tooltip:focus {
  .tooltip__content {
    transform: translate(-50%, -100%);
    clip: auto;
    overflow: visible;
    opacity: 1 !important;
  }
}

.tooltip:hover {
  .tooltip__content {
    transform: translate(-50%, -100%);
    @extend .show-visually-hidden;
    opacity: 1 !important;
  }
}

html {
  scroll-behavior: smooth; /* Supported by firefox and Chrome only. Use a fallback for unsupported browsers, if you need. */
  font-size: 62.5%;
  background-color: #e1e1e1;
  *,
  *:before,
  *:after {
    /**
     * Use inheritance here in order to make easier
     * to change the box-sizing in plugins or other components that leverage other behavior.
     * Credits: Jonathan Neal http://blog.teamtreehouse.com/box-sizing-secret-simple-css-layouts#comment-50223
     */
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }
  // Required to override Gigya default script in developer mode
  &.gigya-mobile-modal-mode body {
    height: auto !important;
  }
}
