.categories-navigation {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;

  .hooper-track {
    align-items: flex-start;
    @include breakpoint(large) {
      justify-content: center;
    }
  }

  li {
    max-width: auto;
    min-width: rem(100);
    a {
      display: inline-flex;
      align-items: flex-start;
      color: $color-on-background;
      height: 100%;
      &:after {
        border-bottom: 1px solid;
        content: '';
        display: block;
        max-width: rem(50);
        margin: 0 auto;
        padding-top: 0;
      }
    }

    a:hover:after {
      border-bottom: 1px solid $icon-text-icon-color-hover;
    }
    @include breakpoint(medium) {

      max-width: rem(200);
      min-width: rem(150);

    }

  }

  &.hooper--partial-slide {
    .hooper-slide[aria-hidden="true"] {
      visibility: visible;
      &.is-next,
      &.is-prev {
        opacity: 1;
        visibility: initial;
      }

      &.is-next {
        opacity: .2;
      }
    }
  }
}

