.navigation-v2 {
  &__menu-nested {
    &__menu.navigation {
      &__menu-nested {
        &__menu--product {
          .listing {
            @media screen and (min-width: 641px) and (max-width: 1024px) {
              padding: rem(25);
            }
            @media screen and (min-width: 1025px) {
              display: flex;
              padding: rem(24);
              min-height: rem(292);
            }

            &__items {
              justify-content: space-evenly;
              @media screen and (min-width: 641px) and (max-width: 1024px) {
                justify-content: space-between;
              }
              @media screen and (min-width: 1025px) {
                max-width: rem(1080);
                margin: auto;
                width: auto;
                justify-content: center;
              }

              .listing__item {
                width: 100%;
                height: 100%;
                max-width: rem(152);
                margin: rem(7.5) rem(15);
                @media screen and (max-width: 364px) {
                  margin: rem(7.5) rem(0.5);
                }
                //TABLET ADAPTATION
                @media screen and (min-width: 641px) and (max-width: 1024px) {
                  width: rem(167);
                  height: fit-content;
                  @media screen and (min-width: 641px) and (max-width: 720px) {
                    width: rem(100);
                  }
                  @media screen and (min-width: 721px) and (max-width: 820px) {
                    width: rem(120);
                  }
                  @media screen and (min-width: 821px) and (max-width: 920px) {
                    width: rem(140);
                  }
                }
                @media screen and (min-width: 1025px) {
                  width: rem(150);
                  height: fit-content;
                  margin: rem(15) rem(15);
                  @media screen and (min-width: 1026px) and (max-width: 1180px) {
                    width: rem(130);
                  }
                }

                .card-product {
                  .card-product__name {
                    font-family: $font-body;
                    font-size: $product-list-v2-card-product-font-size-mobile;
                    font-style: $product-list-v2-card-product-font-style;
                    font-weight: $product-list-v2-card-product-font-weight;
                    line-height: $product-list-v2-card-product-line-height;
                    text-transform: $product-list-v2-card-product-text-transform;
                    color: $product-list-v2-card-product-font-color !important;
                    @media screen and (min-width: 1025px) {
                      font-size: $product-list-v2-card-product-font-size;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}