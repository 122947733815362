.card-big {
  width: fit-content;
  height: fit-content;
  @include breakpoint(medium) {
    margin: auto;
  }
  @include breakpoint(xlarge) {
    margin: 0;
    padding: 0;
  }
  .card-big-container.section__container {
    width: fit-content;
    height: fit-content;
    @include breakpoint(medium) {
      margin: 0;
      padding: 0;
    }
  }
  &__picture {
    @include breakpoint(medium) {
      width: rem(540);
      height: rem(720);
    }
    width: 100%;
    max-width: rem(540);
    max-height: rem(720);
    color: $white;
    position: relative;
    display: block;
    overflow: hidden;
    .image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .text-black {
      color: $black;
    }
    .card-big__gradient {
      background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0.58%, rgba(0, 0, 0, 0) 63.24%);
      position: absolute;
      display: block;
      z-index: 0;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      &.disabled {
        background-image: none;
      }
    }
    .card-big__cta-links {
      font-family: $font-heading;
      font-size: $card-big-button-font-size;
      font-style: $card-big-button-font-style;
      font-weight: $card-big-button-font-weight;
      letter-spacing: $card-big-button-letter-spacing;
      text-transform: $card-big-button-text-transform;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include breakpoint(medium) {
        flex-direction: row;
        gap: 4%;
      }
      .card-big-cta-link {
        width: 100%;
        margin-top: rem(16);
      }
    }
    .card-big__info-container-flex {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column-reverse;
      position: absolute;
      .card-big__info-container {
        font-family: $font-body;
        width: 100%;
        height: fit-content;
        padding: rem(23);
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        .card-big__event-category {
          font-family: $font-heading!important;
          font-size: $card-big-event-category-font-size;
          font-style: $card-big-event-category-font-style;
          font-weight: $card-big-event-category-font-weight;
          line-height: $card-big-event-category-line-height;
          text-transform: $card-big-event-category-text-transform;
          background-color: $card-big-event-category-background-color;
          color: $card-big-event-category-text-color;
          padding: $card-big-event-category-padding;
          border-bottom: $card-big-event-category-underline;
        }
        .card-big__title {
          font-family: $card-big-font-family!important;
          font-size: $card-big-heading-font-size-mobile;
          font-style: $card-big-heading-font-style;
          font-weight: $card-big-heading-font-weight-mobile;
          line-height: $card-big-heading-line-height-mobile;
          letter-spacing: $card-big-heading-letter-spacing;
          text-align: left;
          @include breakpoint(large) {
            font-size: $card-big-heading-font-size;
            font-weight: $card-big-heading-font-weight;
            font-style: $card-big-heading-font-style;
            line-height: $card-big-heading-line-height;
            letter-spacing: $card-big-heading-letter-spacing;
          }

          &.event {
            margin-top: rem(8);
          }
        }
        .card-big__paragraph {
          font-size: $card-big-paragraph-font-size-mobile;
          font-style: $card-big-paragraph-font-style;
          font-weight: $card-big-paragraph-font-weight-mobile;
          line-height: $card-big-paragraph-line-height;
          letter-spacing: $card-big-paragraph-letter-spacing;
          text-align: left;
          @include breakpoint(large) {
            font-size: $card-big-paragraph-font-size;
            font-style: $card-big-paragraph-font-style;
            font-weight: $card-big-paragraph-font-weight;
            line-height: $card-big-paragraph-line-height;
            letter-spacing: $card-big-paragraph-letter-spacing;
          }
        }
      }
    }
  }
}