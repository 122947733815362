.toggle-play,
.toggle-mute {
  position: absolute;
  bottom: rem(20);
  z-index: 4;

  button {
    background: rgba($color-primary, 0.6);
    cursor: pointer;
    color: $color-background;
    font-size: rem(20);
    padding: 4px;
    transition: background 150ms ease-out;
    &:hover {
      background: rgba($color-primary, 1);
    }
  }
}
.toggle-play {
  left: rem(20);
}

.toggle-mute {
  right: rem(20);
}

.carouselV3 .toggle-mute {
  display: none;
}

.gallery {
  .toggle-mute {
    z-index: 5;
  }
  .toggle-play {
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;

    button {
      cursor: pointer;
      background: transparent;
      width: 100%;
      height: 100%;

      .icon-play {
        background: rgba($color-primary, 0.6);
        border-radius: 100%;
        font-size: rem(40);
        width: rem(60);
        height: rem(60);
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        transition: background 150ms ease-out;
        &:hover {
          background: rgba($color-primary, 1);
        }
      }

      .icon-pause {
        display: none;
      }
    }
  }

}