/**
 * Design System for Piaggio Group
*/
.icon-vespa_pin {
  font-size: rem(24);
}

.header-v2-promo-alert {
  position: var(--header-position);
  z-index:5;
  width: 100%;
  margin-bottom: 0;
}

.header-v2-placeholder {
  display: none;
  width: 100%;
  height: rem(70);
  background-color: transparent;
  @media screen and (min-width: 1025px) {
    height: rem(120);
  }
  &.visible {
    display: block;
  }
}
.header.v2 {
  height: fit-content;
  @if $header-v2-border {
    border-bottom: 1px solid $header-v2-border;
  }
  position: var(--header-position);
  border-radius: $header-v2-border-radius-small;
  margin: $header-v2-margin-small;
  top: 0;
  right: 0;
  left: 0;
  z-index: 101;
  color: $header-v2-color-on-background;
  overflow: hidden;
  max-width: rem(1920);

  nav {
    height: rem(70);
    padding: 0 rem(20);
    &::before,
    &::after {
      transition: opacity 0.2s ease-in-out;
    }
    &::after {
      display: block;
      content: "";
      position: absolute;
      z-index: 0;
      background-color: $header-v2-background;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: $header-v2-border-radius-small;

      @include breakpoint(medium) {
        border-radius: $header-v2-border-radius-large;
      }
    }

    .header__logo__on-background {
      animation: logo-bk-fade-in 1s forwards;
    }

    .horizontal-rule {
      opacity: 0;
      height: 1px;
      background-color: $header-v2-nav-separator;
      position: absolute;
      left: rem(20);
      bottom: 0;
      z-index: 10;
      animation: close-menu-rule 400ms forwards;
    }

    &.nav-open {
      .header__logo__on-navigation-mobile {
        animation: logo-mobile-fade-in 1s forwards;
      }
      &::after {
        background-color: $header-v2-nav-background-color !important;
        transition: 200ms;
      }
      .header__menu-services {
        height: rem(25);
        width: rem(25);
        .icon-menu {
          color: $header-v2-menu-services-icon-color;
        }
      }
      .header-right-container {
        display: none;
      }
      .header-v2__menu-services__nav {
        width: rem(24);
        height: rem(24);
        .custom-burger-icon {
          height: 0;
          transition: height 200ms;
          &::after, &::before {
            background-color: $header-v2-services-nav-background-color-black;
          }
          &::after {
            animation: burger-bottom-menu-close 300ms forwards;
          }
          &::before {
            animation: burger-up-menu-close 300ms forwards;
          }
        }
      }

      .horizontal-rule {
        opacity: 1;
        animation: open-menu-rule 400ms forwards;
      }
    }

    .header-v2__menu-services__nav {
      width: rem(24);
      height: rem(24);
      .custom-burger-icon {
        height: rem(1);
        width: rem(16);
        background-color: $header-v2-services-nav-background-color-mb;
        display: block;
        font-size: 0;
        line-height: 0;
        padding: 0;
        position: relative;
        @media screen and (min-width: 1025px) {
          display: none;
        }
        &::before, &::after {
          height: rem(1);
          width: rem(16);
          background-color: $header-v2-services-nav-background-color;
        }
        &::before {
          content: "";
          position: absolute;
          top: rem(-6);
          bottom: 0;
          left: 0;
          transform: rotate(0);
          animation: burger-up-menu-open 300ms forwards;
        }
        &::after {
          content: "";
          position: absolute;
          top: rem(6);
          bottom: 0;
          left: 0;
          transform: rotate(0);
          animation: burger-bottom-menu-open 300ms forwards;
        }
      }
    }
  }

  @include breakpoint(medium) {
    margin: $header-v2-margin-large;

    nav {
      @include xy-grid;
      align-items: center;
      z-index: 1;
      position: relative;
      justify-content: space-between;
      @if $header-v2-effect {
        &::before {
          // skewed background
          display: block;
          content: "";
          position: absolute;
          z-index: 1;
          background-color: $header-background;
          height: $header-height-large - 26px;
          left: 0;
          right: 0;
          bottom: rem(5);
          border-radius: $header-border-radius-large;
          transform: skewY(-0.5deg);
        }
      }

      .header__menu-services {
        z-index: 1;
        @include xy-cell(6, $output: false, $gutters: 0);

        @include breakpoint(medium) {
          @include xy-cell(4, $output: false, $gutters: 0);
        }

        .header__menu-items {
          display: flex;

          .header__menu-services__store {
            order: 1;
          }

          .header__menu-services__contact-us {
            order: 2;
            pointer-events: all;
            cursor: pointer;
            position: relative;
            .icon-arrow-down {
              height: rem(10);
              // margin-top: rem(4);
              animation: rotate-back-arrow 400ms forwards;
              &.rotate {
                // margin-top: rem(6);
                animation: rotate-arrow 400ms forwards;
              }
              &::before {
                font-size: rem(13) !important;
              }
            }
          }

          .header__menu-services__dealer-locator {
            order: 3;
          }

          .header__menu-services__store,
          .header__menu-services__contact-us,
          .header__menu-services__dealer-locator {
            margin-left: rem(32);
            display: flex;
            align-items: center;
            span {
              @include font-body-xs;
              padding: 0 rem(8);
              font-size: $header-v2-left-part-font-size;
            }
            i:before {
              font-size: rem(20);
            }
          }
          .header__menu-services__store {
            margin-left: rem(20);
          }
        }
      }
    }
  }
  @media screen and (min-width: 1025px) {
    .nav{
      .header__menu-services {
        .header__menu-items {
          .header__menu-services__store,
          .header__menu-services__contact-us,
          .header__menu-services__dealer-locator {
            span {
              font-size: $header-v2-left-part-font-size!important;
            }
          }
        }
      }
    }
  }

  @include breakpoint(xxxlarge) {
    margin: 0 auto;
  }

  button,
  a {
    color: $header-v2-color-on-background;
  }

  ////////////////////////////////////////
  // other styles
  .show-on-focus {
    &:focus {
      @include font-body-s;
      position: absolute !important;
      top: 50%;
      margin-top: -9px;
      left: 15%;
      z-index: 10;
    }
  }

  .header__logo {
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    .header__logo__on-background,
    .header__logo__on-tranparent,
    .header__logo__on-navigation-mobile {
      display: inline-block;
      padding: $space-size-s;

      img {
        aspect-ratio: auto 90 / 35;
        max-height: rem(35);

        @include breakpoint(medium) {
          aspect-ratio: auto 115 / 45;
          max-height: rem(45);
        }
      }

      &:focus {
        outline-offset: $general-outline-width * -1;
      }
    }

    .header__logo__on-background {
      display: inline-block;
    }
    .header__logo__on-tranparent {
      display: none;
    }
  }

  .header-right-container {
    display: flex;
    margin-right: 0;
    @media screen and (min-width: 1025px) {
      display: flex;
      margin-right: rem(20);
    }

    .header-v2-right-item{
      z-index: 1;
      position: relative;
      margin-right: rem(12);
      .button {
        display: flex;
        align-items: center;
        
        span {
          display: none;
          @include font-body-xs;
          padding: 0 rem(8);
          font-size: $header-v2-left-part-font-size;
        }
        @media screen and (min-width: 1025px) {
          span{
            display: block;
          }
        }

        i::before {
          font-size: rem(24);
          @media screen and (min-width: 1025px) {
            font-size: rem(20);
          }
        }
      }
    }

    .header-v2__community-login {
      margin-right: rem(12);
      height: fit-content;
      @media screen and (min-width: 1025px) {
        margin: 0;
      }

      .header-v2__login-gigya {
        width: rem(24);
        height: fit-content;
        @media screen and (min-width: 1025px) {
          width: auto;
          height: auto;
        }
        .icon-header-v2-community-login::before {
          font-size: rem(16);
        }
      }
    }

    .header__menu-services__dealer-locator {
      width: rem(24);
      height: rem(18);
      z-index: 10;
      a {
        
        @media screen and (min-width: 1025px) {
          height: rem(18);
        }
      }
      .icon-dealer-locator {
        font-size: rem(20);
      }
    }
  }

  .header__menu-shortcuts {
    text-align: $header-v2-shortcuts-align;

    li {
      display: inline-block;

      a {
        @include font-body-s;
        padding: $space-size-s;
        display: inline-block;
        text-decoration: $header-v2-shortcuts-text-decoration;
        text-transform: $header-v2-shortcuts-text-transform;
      }
    }
  }

  /* Login gigya */
  .header-v2__login-gigya {
    .link_login_gigya {
      color: $button-primary-text-color;
      text-decoration: none;
      z-index: 1;
      position: relative;
      display: flex;
      align-items: center;

      span {
        margin-right: rem(10);
        font-weight: $font-quote-font-weight;
        font-size: $font-body-xs-font-size;
        line-height: rem(15);
      }

      i:before {
        font-size: rem(15);
      }

      &.logged {
        i {
          &::after {
            content: "";
            width: rem(5);
            height: rem(5);
            position: absolute;
            background-color: $color-surface;
            border-radius: rem(10);
            bottom: rem(20);
            right: rem(-5);
          }
        }
      }
    }
  }

  .header__logout-gigya {
    a.link_logout_gigya {
      border-color: #adadad;
      border-bottom: solid 1.5px;
      color: #FFF;
      display: none;
      font-size: $font-body-xs-font-size;
      font-weight: 700;
      line-height: rem(15);
      margin-right: rem(25);
      padding-bottom: rem(2);
      position: relative;
      text-decoration: none;
      z-index: 1;
    }
  }

  .header__menu-services {
    text-align: right;
    display: flex;
    align-items: center;

    @if $header-effect {
      position: relative;
      top: -5px;
    }

    ul,
    li {
      display: inline-block;
      button {
        color: $header-v2-services-color;
        font-size: $header-v2-services-size;
        //line-height: $header-services-size;
      }
    }

    ul {
      .contact-us-back-button {
        padding: rem(12) 0 rem(32) 0 !important;
        .contact-us {
          i {
            font-size: $main-navigation-item-v2-menu-nested-back-button-font-size !important;
            color: $main-navigation-item-v2-menu-nested-back-button-text-color !important;
            width: rem(16) !important;
            height: rem(16) !important;
            margin-right: rem(12);
          }
          span {
            font-size: $main-navigation-item-v2-menu-nested-back-h2-font-size;
            color: $main-navigation-item-v2-menu-nested-back-button-text-color !important;
            font-weight: $main-navigation-item-v2-menu-nested-back-h2-font-weight;
            @media screen and (min-width: 1025px) {
              font-size: $header-v2-services-size;
            }
          }
        }
      }
    }
  }

  & {
    transition: transform 0.2s ease-in-out;
  }

  &.header--sticky {
    position: var(--header-position);
    opacity: 1;
    transform: unset;

    nav {
      &::before,
      &::after {
        opacity: 1;
      }
      .header__menu-services {
        .header__menu-items {
          .header__menu-services__contact-us {
            ul {
              &.show {
                top: 47%;
              }
            }
          }
        }
      }
    }
  }

  &.header--hidden {
    position: fixed;
    opacity: 0;
    transform: translateY(-100%);
  }

  &.header--promo {
    top: rem(44);
  }
}

.header__login-modal {
  background-color: $color-gray-lightest;
  min-height: 15rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  padding: 1.2rem 2rem;
  position: fixed;
  right: 6rem;
  top: 7rem;  // header height
  width: 32rem;
  z-index: 110;  // if not enough, increase it looking into hero-heading value
  .modal-heading {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .modal-links {
      display: flex;
      flex-direction: column;
      font-size: 1.3rem;
      font-weight: 400;
      gap: 1.6rem;
      line-height: 1.5rem;
      padding: 2rem 0;
      .modal-option {
        color: $black;
        justify-content: flex-start;
        text-decoration: underline;
      }
    }
    .modal-close-btn {
      padding: 0.5rem 0;
      .icon-close:before {
        background-color: $white;
        border-radius: 2rem;
        color: $color-primary;
        font-size: 1.8rem;
        padding: 0.5rem;
      }
    }
  }
  .modal-separator {
    background-color: $black;
    height: 1px;
  }
  .modal-logout {
    color: $color-primary;
    font-size: 1.6rem;
    font-weight: 700;
    justify-content: flex-start;
    line-height: 2rem;
    padding: 0.8rem 0;
    text-decoration: none;
    text-transform: uppercase;
  }
}

.modal-overlay {
  background: $header-v2-modal-background-color;
  height: 100vh;
  left: 0;
  opacity: 0.6;
  position: fixed;
  width: 100vw;
  z-index: 102;  // linked to header__login-modal
}

@media screen and (max-width: 1024px) {
  #account-login span {
    display: none;
  }

  .header__login-modal {
    margin: 0 2.8rem;
    min-width: 26.4rem;
    right: 0;
    width: auto;
    width: -webkit-fill-available;
  }
}

// PIAGGIO-8774: visibility with zoom 400%
@media screen and (max-width: 320px) {
  .header__login-modal {
    gap: 1rem;
    margin: 0;
    min-width: unset;
    right: 0;
    top: 0;
    .modal-heading {
      .modal-links {
        gap: 1.4rem;
        padding: 1.2rem 0;
      }
    }
    .modal-logout {
      padding: 0.5rem 0;
    }
  }
}

.main-navigation-open {
  .navigation__content {
    display: flex;
  }
}

//over 1440px
@media screen and (min-width: 1441px) {
  .header__login-modal {
    position: absolute;
  }
}

//Animations
@keyframes rotate-arrow {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(-180deg); }
}

@keyframes rotate-back-arrow {
  0% { transform: rotate(-180deg); }
  100% { transform: rotate(0deg); }
}

@keyframes show-contacts-menu {
  0% { opacity: 0 }
  100% { opacity: 1; backdrop-filter: blur(10px); }
}

@keyframes hide-contacts-menu {
  0% { opacity: 1 }
  100% { opacity: 0; }
}

@keyframes burger-bottom-menu-close {
  0% { top: 6px; transform: rotate(0) }
  100% { top: 0; transform: rotate(45deg) }
}

@keyframes burger-up-menu-close {
  0% { top: -6px; transform: rotate(0) }
  100% { top: 0; transform: rotate(-45deg) }
}

@keyframes burger-bottom-menu-open {
  0% { top: 0; transform: rotate(45deg)}
  100% { top: 6px; transform: rotate(0)  }
}

@keyframes burger-up-menu-open {
  0% { top: 0; transform: rotate(-45deg)}
  100% { top: -6px; transform: rotate(0) }
}

@keyframes logo-bk-fade-in  {
  0% { opacity: 0 }
  100% { opacity: 1; }
}

@keyframes logo-mobile-fade-in {
  0% { opacity: 0 }
  100% { opacity: 1; }
}

@keyframes open-menu-rule {
  0% { width: 0; }
  100% { width: calc(100% - 4rem); }
}

@keyframes close-menu-rule {
  0% { width: calc(100% - 4rem); }
  100% { width: 0; }
}