.form-select-brand {
  height: 100vh !important;

  .select-brand-grid {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 25px;
    padding: 0 25px;
  }

  .select-brand-card {
    width: 100%;
    height: 100px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 70%;
    }
  }

  .invalid-feedback {
    width: 100%;
    padding: 10px 25px;
  }
}
