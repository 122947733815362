.introduction__date {
  @include font-body();
  text-align: center;

  &:after {
    content: '';
    display: block;
    height: auto;
    border-bottom: 2px solid $introduction-date-after-border-color;
    width: 5%;
    margin: 2rem auto 4rem;
  }

}

.introduction__text,
.introduction__text p {
  @include font-heading-s();
  text-align: center;
}

.introduction__social-sharing {
  text-align: center;
  padding: rem(20);
  font-size: rem(20);
  display: flex;
  width: fit-content;
  margin: 0 auto;
  gap: rem(10);

  span {
    cursor: pointer;
    color: $introduction-social-sharing-color;
  }

  span:not(:last-child) {
    margin-right: rem(10);
  }
}
