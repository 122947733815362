/* GLOBAL */
.expand-img-premium {
  animation: expand-img-premium var(--duration) ease-out both;
}
.collapse-img-premium {
  animation: collapse-img-premium 200ms ease-out both;
}

/* SCOPED */
.gallery-premium {
  padding-bottom: 1rem;

  .swiper-button-prev {
    transform: translateX(-140px);
  }
  .swiper-button-next {
    transform: translateX(140px);
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    color: black;
    font-size: 14px;
  }
  @media screen and (max-width: 640px) {
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }

  .swiper-pagination {
    transform: translateY(50px);
    text-align: center;
  }
  .swiper-pagination-bullet {
    background-color: black;
    margin: 0 2px;
    width: 10px;
    height: 10px;
    &-active {
      background-color: $color-primary;
    }
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* mobile */
  .swiper {
    width: calc(60svw - 20px);
    height: calc(80svw - 20px);
    margin-bottom: 50px;
  }
  .swiper-slide img {
    height: 100%;
    width: 100%;
    max-width: none !important;
    object-fit: cover;
    object-position: center;
  }
  /* desktop */
  @media screen and (min-width: 640px) {
    .swiper {
      width: 52svw;
      height: 39svw;
    }
  }

  .swiper-wrapper {
    width: 100%;
    height: 100%
  }

  .page-progress-counter {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 25px;
    padding-bottom: 25px;
    font-size: 10px;
    font-weight: 700;
    font: $font-body-font-family;
  }

}
.gallery-premium-button-close {
  background-color: $color-primary;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
  font-size: 30px;
  cursor: pointer;
  border-radius: 100%;
  &__icon {
    color: #fff;
  }
}
.gallery-premium-download-wallpaper {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}

.swiper-overlay.active {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  left: -20px;
  top: -20px;
  z-index: -1;
}

/* ANIMATIONS */
@keyframes expand-img-premium {
  from {
    position: fixed;
    top: var(--from-top);
    left: var(--from-left);
  }
  to {
    position: fixed;
    width: calc(100% - var(--to-top) - var(--to-top));
    height: calc(100% - var(--to-left) - var(--to-left));
    top: var(--to-top);
    left: var(--to-left);
  }
}
@keyframes collapse-img-premium {
  from {
    position: fixed;
    width: calc(100% - var(--to-top) - var(--to-top));
    height: calc(100% - var(--to-left) - var(--to-left));
    top: var(--to-top);
    left: var(--to-left);
  }
  to {
    position: fixed;
    top: var(--from-top);
    left: var(--from-left);
  }
}