.arrow-up {
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid $color-surface;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-top: 9px solid $color-surface;
}

.arrow-right {
  width: 0;
  height: 0;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-left: 9px solid $color-surface;
}

.arrow-left {
  width: 0;
  height: 0;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-right: 9px solid $color-surface;
}

.tooltip {
  align-items: center;
  display: flex;
  //flex-direction: column;
  width: 40px;
  height: 40px;
  justify-content: center;
  position: relative;
  text-align: center;
  font-size: 1.7rem;
  cursor: pointer;
  z-index: 2;

  &.absolute {
    position: absolute;
    right: -15px;
    top: -15px;
  }

  .tooltip__content {
    @extend .visually-hidden;
    height: auto;
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    padding: rem(5) rem(5);
    text-align: center;
    color: $color-on-surface;
    background-color: $color-surface; //$color-surface
    max-width: rem(300);
    min-width: rem(120);
    white-space: normal;
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
    width: auto;

    @include font-heading-xxs;
      text-transform: uppercase;

    &.tooltip__content--sm {
      min-width: initial;
    }


    &::after {
    content: "";
    @extend .arrow-down;
    @include center(x);
    bottom: -9px;
    }
    &::after {
      content: "";
      @extend .arrow-down;
      @include center(x);
      bottom: -9px;
    }
  }
}
