.listing {
  position: relative;
  //padding: $space-size-xl 0 $space-size-l;

  @include breakpoint(large) {
    //padding: $space-size-xxl 0 $space-size-xl;
  }

  ////////////////////////////////////////
  // listing items
  .listing__items {
    @include xy-grid;
    &.carousel {
      flex-wrap: nowrap;
      overflow-x: auto;
      overflow-scrolling: touch;
      .listing__item {
        width: rem(280);
        height: rem(355);
        @include breakpoint(large) {
          height: rem(312);
        }
      }
      .card-promotion-small__img picture {
        height: auto;
      }
    }

    .listing__item {
      @include xy-gutters($gutter-position: top left right bottom);
      //margin-bottom: rem(30);
      display: flex;
      transition: opacity .3s linear;
    }
  }

  ////////////////////////////////////////
  // listing 2/3 columns
  &.listing--small-2-large-3 {
    .listing__item {
      @include xy-cell(1 of 2);
      @include breakpoint(large) {
        @include xy-cell(1 of 3);
      }
      &.card-promotion-small {
        width: 100%;
        @include breakpoint(medium) {
          width: calc(100% / 3);
        }
      }
    }
  }

  ////////////////////////////////////////
  // listing 1/4 columns
  &.listing--small-1-large-4 {
    .listing__item {
      @include xy-cell(1 of 1);

      @include breakpoint(medium) {
        @include xy-cell(1 of 2);
      }

      @include breakpoint(large) {
        @include xy-cell(1 of 4);
      }
    }
  }

  ////////////////////////////////////////
  // listing 1/4 columns
  &.listing--small-2-large-4-with-2cols {
    .listing__item {
      @include breakpoint(medium down) {
        @include xy-cell(
                1 of 2,
            $gutters: 0.4rem,
            $gutter-type: padding,
            $gutter-position: top left right bottom
        );
        margin: 0;

        &--2cols {
          @include xy-cell(
                  2 of 2,
              $gutters: 0.4rem,
              $gutter-type: padding,
              $gutter-position: top left right bottom
          );
        }
      }

      @include breakpoint(large) {
        @include xy-cell(1 of 4);

        &--2cols {
          &:nth-child(7),
          &:nth-child(16) {
            @include xy-cell(2 of 4);
          }
        }
      }
    }
  }

  ////////////////////////////////////////
  // listing 2/4 columns
  &.listing--small-2-large-4 {
    .listing__item {
      @include xy-cell(1 of 2, $gutters: 0, $gutter-type: margin);

      @include breakpoint(medium down) {
        margin-top: rem(1.5);
        margin-bottom: rem(1.5);

        &:nth-child(odd) {
          padding: 0 rem(1.5) 0 0;
        }

        &:nth-child(even) {
          padding: 0 0 0 rem(1.5);
        }
      }

      @include breakpoint(large) {
        @include xy-cell(1 of 4);
      }
    }
  }

  ////////////////////////////////////////
  // listing 2/6 columns
  &.listing--small-2-large-6 {
    .listing__items {
      @include breakpoint(medium down) {
        margin-right: rem(2.5);
        margin-left: rem(2.5);
      }

      .listing__item {
        @include xy-cell(1 of 2, null, .5rem);
        @include breakpoint(medium down) {
          margin-top: rem(2.5);
          margin-bottom: rem(2.5);
        }
        @include breakpoint(large) {
          @include xy-cell(1 of 6);
        }
      }
    }
  }

  ////////////////////////////////////////
  // listing 12/12 columns
  &.listing--small-12-large-12 {
    .listing__item {
      @include xy-cell(1 of 1);

      @include breakpoint(large) {
        @include xy-cell(1 of 1);
      }
    }
  }

  ////////////////////////////////////////
  // listing width Card Promotion Small
  .listing__item {
    &.card-promotion-small {
      @include breakpoint(medium) {
        width: calc((100% / 3) - 30px);
        height: rem(360);
      }
    }
  }
}

.listing--mosaic {
  overflow: auto;
  list-style: none;
  @include breakpoint(large) {
    //padding-bottom: rem(8);
  }
}

.listing--mosaic .listing__items {
  display: flex;
  flex-direction: column;

  .listing__item {
    margin: 0;
    padding: rem(1.5) 0;
    order: 2;

    &:nth-child(2) {
      order: 1;
    }
  }

  @include breakpoint(large) {
    display: block;

    .listing__item {
      width: 50%;
      float: left;
      padding: rem(16);

      &:nth-child(2) {
        padding-bottom: 0;
        width: 50%;
        float: right;
      }
    }
  }
}

.pg-tab-content .listing {
  .listing__item {
    align-items: flex-start;
  }
}

.visual-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  opacity: 0;
}

.filter-no-results {
  text-align: center;
  font-size: rem(16);

}
#uuid-14d91808-98df-488a-b9c1-04ff43a313f1 {
  .listing-header div .listing-header__heading {
    text-align: center !important;
  }
}