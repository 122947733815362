.form-steps{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: rem(15);

  .nav-item{
    flex: 1 1 auto;
    text-align: center;
  }

  .nav-link{
    @include font-heading-s;
    text-decoration: none;
    color: $color-on-background;
    padding: rem(10);
    display: block;
    width: 100%;

    &.active{
      border-bottom: rem(1) solid $color-primary;
    }
  }
}