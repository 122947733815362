.definition-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: rem(5);

  dt,
  dd {
    border-bottom: 1px solid $color-gray-lighter;
    padding: rem(5) 0;
    min-height: rem(44);
    display: flex;
    width: 50%;
    align-items: center;
    justify-items: space-between;
    @include font-body-s;

    &:first-of-type {
      // border-top: none;
    }
  }

  dd {
    justify-content: flex-end;
    font-weight: 600;
  }
}

.product-price__label {
  display: inline-block;
  position: relative;
  // @include font-heading-s;
  padding-right: 15px;
}

.product-price__price {
  display: inline-block;
  position: relative;
  // @include font-heading-s;
}
