.breadcrumb__back {
 /* @include font-button-text-m;
  text-transform: uppercase;
  */
  position: relative;
  left: rem(20);
  top: 0;
  z-index: 2;
  /*color: $color-primary;*/
  .icon-arrow-sx {
    font-size: 1.3rem !important;
    @include center(y);
    left: 0;
    color: $color-primary;
    display: $breadcrumb-arrow;
  }
}

/* exception */
.hero-heading .breadcrumb__back {
  position: absolute !important;
  top: rem(80);
}

/* PIAGGIO-9042: visibility with zoom 400% */
@media screen and (max-width: 320px) {
  .hero-heading .breadcrumb__back {
    top: rem(60);
  }
}