////////////////////////////////////////
// Gallery container
.gallery {
  &.gallery--full {
    padding-bottom: 0;
    padding-top: 0;

    .hooper-list {
      border-radius: 0px !important;
    }
  }

  .gallery__container {
    // background-color: $color-gray-lighter;
    overflow: hidden;
    position: relative;
    padding-right: 0 !important;
    padding-left: 0 !important;

    &:not(.full-width) {
      .hooper-list {
        @include breakpoint(large) {
          border-radius: $gallery-border-radius;
          -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
        }
      }
    }
  }

  slide-img__image picture {
    display: block;
    background: #000;
  }

  figure {
    margin: 0 auto;
    width: 100%;
  }

  img {
    width: 100%;
  }
}

.gallery__shape-nav {
  display: none;
  border-style: solid;
  border-width: 0 30rem 13rem 0;
  border-color: transparent $color-primary transparent transparent;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  @include breakpoint(large) {
    display: $gallery-shape-nav-display;
  }
}

.gallery {
  overflow: hidden;

  .hooper-slide {
    &.gallery__img-slide {
      @include aspect-ratio(16, 9, ".content-slide");

      &.gallery__img-slide--mobile-vertical {
        @include breakpoint(medium down) {
          @include aspect-ratio(9, 16, ".content-slide");
        }
      }

      @include breakpoint(large) {
        @include aspect-ratio(1920, 1080, ".content-slide");
      }
    }

    .gallery__caption {
      text-align: center;
      margin: 10px;
    }
  }

  .slide-img__image,
  .slide-img__video,
  .slide-img__video video,
  .slide-img__video iframe {
    position: absolute;
    top: 0;
    width: calc(100% + 1px);
    height: calc(100% + 1px);
    background-color: $gallery-slide-img-video-bg;
  }

  /*
  .slide-img__video iframe {
    // hack for Firefox on Windows with zoom
    top: 1px;
  }
  */

  ////////////////////////////////////////
  // Gallery carousel nav
  .gallery__nav-button {
    @include center(y);
    display: none;
    border: none;
    color: $gallery-nav-button-color;
    cursor: pointer;
    font-size: rem(40);
    position: absolute;
    top: $gallery-nav-button-top;
    //top: 0;
    //height: $gallery-nav-button-height;
    transition-duration: 0.3s;
    opacity: $gallery-nav-button-opacity;
    height: auto;
    width: $gallery-nav-button-width;
    z-index: 2;

    .icon {
      background: $gallery-nav-button-bg;
      display: inline-flex;
      width: 100%;
      align-items: center;
      border-radius: $gallery-nav-button-icon-radius;
      height: $gallery-nav-button-icon-height;
    }

    @include breakpoint(large) {
      display: block;
    }
  }

  .gallery__nav-button:hover {
    opacity: 1;
  }

  .gallery__nav-button[disabled] {
    opacity: 0;
  }

  .gallery__nav-button--prev {
    left: $gallery-nav-button-prev-left;
    right: $gallery-nav-button-prev-right;
    transform: $gallery-nav-button-prev-transform;

    .icon {
      justify-content: flex-end;
      transition-duration: 0.3s;
      padding-right: $gallery-nav-button-icon-padding-right;
    }

    &:hover {
      transform: $gallery-nav-button-prev-hover-transform;
    }

    &:hover .icon {
      padding-right: $gallery-nav-button-icon-padding-right-hover;
    }
  }

  .gallery__nav-button--next {
    right: 0;
    transform: $gallery-nav-button-next-transform;

    .icon {
      transition-duration: 0.3s;
      padding-left: $gallery-nav-button-icon-padding-left;
    }

    &:hover {
      transform: $gallery-nav-button-next-hover-transform;
    }

    &:hover .icon {
      padding-left: $gallery-nav-button-icon-padding-left-hover;
    }
  }
}

////////////////////////////////////////
// Gallery carousel dots
.slide-img__image picture {
  display: block;
  height: 100%;
  width: 100%;
}

.slide-img__image figure {
  margin: 0;
}

.slide-img__image,
.slide-img__video,
.slide-img__video iframe,
.slide-img__video video {
  width: calc(100% + 1px);
  height: calc(100% + 1px);
}

.slide-img__video div:first-child {
  height: 100%;
  width: 100%;
  position: absolute;
  // PIAGGIO-6978 fix related to PIAGGIO-6887
  top: -1px;
  bottom: 0;
  left: 0;
  right: 0;
}