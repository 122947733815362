$form-field-radio-size: rem(21);
$form-field-radio-checkmark-size: rem(9);
$form-field-radio-select-size: rem(30);

.piaggio-form-radio {
  display: flex;
  gap: 24px;

  &__container {
    display: flex;
    gap: 8px;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  &__label {
    font-size: $form-radio-label-font-size;
    font-weight: $form-radio-label-font-weight;
    font-style: $form-radio-label-font-style;
    line-height: $form-radio-label-line-height;
  }

  &__checkmark {
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    border: 2px solid $color-primary;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  &__checkmark-dot {
    display: block;
    width: 14px;
    height: 14px;
    background-color: transparent;
    border-radius: 50%;
  }

  &__container input:checked ~ .piaggio-form-radio__checkmark > .piaggio-form-radio__checkmark-dot {
    display: block;
    width: 14px;
    height: 14px;
    background-color: $color-primary;
  }

}

.custom-radio {
  input[type="radio"] {
    opacity: 0;
  }

  label {
    position: relative;
    display: inline-block;
    padding-left: rem(30);
    cursor: pointer;

    &::before,
    &::after {
      position: absolute;
      display: inline-block;
    }

    /*Outer box of the fake radio*/
    &::before {
      height: $form-field-radio-size;
      width: $form-field-radio-size;
      border-radius: 100%;
      border: $form-field-border-bottom-width solid $form-field-border-color;
      left: 0;
      top: rem(3);
      background: $white;
    }

    /*Checkmark of the fake radio*/
    &::after {
      height: $form-field-radio-checkmark-size;
      width: $form-field-radio-checkmark-size;
      background: $color-primary;
      border-radius: 100%;
      left: rem(6);
      top: rem(9);
    }
  }

  /*Hide the checkmark by default*/
  input[type="radio"] + label::before {
    content: "";
  }

  /*Unhide on the checked state*/
  input[type="radio"]:checked + label::after {
    content: "";
  }

  /*Adding focus styles on the outer-box of the fake checkbox*/
  input[type="radio"]:focus + label::before {
    [data-whatintent="keyboard"] & {
      outline: $general-outline-width solid $general-outline-color-on-light;
      outline-offset: 2px;
    }
  }

  &.custom-radio--select {
    label {
      width: $form-field-radio-select-size;
      height: $form-field-radio-select-size;
      padding: 0;
      margin: 0;

      &::before,
      &::after {
        border-radius: 0;
      }

      &::before {
        border: 0;
        background-color: transparent;
        top: 0;
        width: $form-field-radio-select-size;
        height: $form-field-radio-select-size;
      }

      &::after {
        @include font-icon();
        background: transparent;
        color: $white;
        border-radius: 0;
        left: rem(7);
        top: rem(3);
        font-size: 1.8rem;
      }
    }

    input[type="radio"]:checked + label::before {
      @include font-icon();
      background-color: $form-selected-true-color;
      padding-top: 4px;
    }

    /*input[type="radio"]:checked + label::after {
      content: "\f160";
    }*/
    input[type="radio"]:checked + label {
      @extend .icon-tick;
      text-align: center;
      color: $white;
      border-radius: 0;
      font-size: 1.8rem;
    }

  }
}

