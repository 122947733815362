.homepage-overlay {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  top: 0;
  left: var(--overlay-translate);
  position: absolute;
  background-color: #000;
  z-index: 1110;
  overflow: hidden;
  &__brand-logo-container {
    opacity: 0;
    width: rem(66);
    height: fit-content;
    position: absolute;
    z-index: 110;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: logo-fade-in forwards;
    animation-duration: 400ms;
    animation-delay: 200ms;
    @include breakpoint(medium) {
      width: rem(90);
    }
    @include breakpoint(xlarge) {
      height: fit-content;
      top: rem(30);
      left: 50%;
      transform: translate(-50%, 0);
    }
    img {
      width: 100%;
      margin: 0;
    }
    a {
      pointer-events: none;
    }
  }
  &__wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    margin: auto;
    @include breakpoint(xlarge) {
      flex-direction: row;
      //max-height: calc(100% - 33px);
      max-height: 100%;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 50%;
    margin: 0;

    .overlay-container-picture {
      width: 100%;
      height: 100%;
      overflow: hidden;
      .overlay-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 0;
        @include breakpoint(xlarge) {
          height: 100vh;
          width: 100%;
        }
      }
    }

    @include breakpoint(xlarge) {
      object-fit: cover;
      width: 50vw;
      height: 100vh;
      margin: 0;
      position: relative;
      &.side-0, &.side-1 {
        .overlay-image {
          object-position: 50% 0;
        }
      }
    }

    .description-contents {
      opacity: 0;
      text-decoration: none;
      color: $text-color-white;
      pointer-events: all;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      width: 70%;
      height: fit-content;
      position: absolute;
      z-index: 100;
      margin: 0 15%;
      top: 50%;
      transform: translateY(-50%);
      text-align: center;
      animation: contents-fade-in forwards;
      animation-duration: 500ms;
      animation-delay: 700ms;
      @include breakpoint(xlarge) {
        width: 50%;
        margin: 0 25%;
      }
      .content-logo {
        width: rem(385);
        margin-bottom: rem(30);
        @include breakpoint(xlarge) {
          min-width: rem(385);
          max-width: rem(385);
          margin-bottom: rem(70);
        }
      }
      .content-text-logo {
        font-size: rem(72);
        font-weight: $font-button-l-font-weight;
        text-decoration: none;
        margin-bottom: rem(30);
        @include breakpoint(xlarge) {
          font-size: rem(130);
          margin-bottom: rem(80);
        }
      }
      .content-subtext-logo {
        font-size: $font-heading-xs-font-size;
        font-weight: $font-button-s-font-weight;
        text-transform: uppercase;
        letter-spacing: rem(2);
        white-space: nowrap;
        @include breakpoint(medium) {
          font-size: rem(30);
        }
      }
      .content-description {
        display: none;
        font-size: $font-heading-xs-font-size;
        font-weight: $font-button-l-font-weight;
        letter-spacing: $font-body-s-letter-spacing;
        line-height: $font-heading-xxs-line-height;
        @include breakpoint(xlarge) {
          display: block;
          opacity: 0;
          font-size: rem(24);
          line-height: rem(30);
          width: 100%;
          right: 0;
          position: absolute;
          bottom: rem(-130);
        }
        @include breakpoint(xxxlarge) {
          font-size: rem(30);
          line-height: $font-heading-l-line-height-small;
        }
      }
    }
    .overlay-container-picture::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: #000;
      opacity: 0.35;
      z-index: 100;
    }
    @include breakpoint(xlarge) {
      //Animate image on hover from left to right
      .description-contents:hover {
        width: 60%;
        margin: 0 20%;
        .content-description {
          animation: fade-in-description forwards;
          animation-delay: 300ms;
          animation-duration: 700ms;
        }
      }
      .content-description:focus {
        animation: fade-in-description forwards;
        animation-delay: 300ms;
        animation-duration: 700ms;
      }
      &.side-0.hover-side-0, &.side-1.hover-side-1 {
        width: 60vw;
        &::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 50%, rgba(255,255,255,0) 100%);
          opacity: 0;
          z-index: 10;
          animation: fade-in-layer forwards;
          animation-delay: 100ms;
          animation-duration: 500ms;
        }
      }
      &.side-1.hover-side-0, &.side-0.hover-side-1 {
        width: 40vw;
        &::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background-color: #ACCCCE;
          mix-blend-mode: color;
          filter: contrast(1.75);
          z-index: 100;
        }
      }
      transition: width 500ms;
    }
  }
  .footer {
    display: none;
    font-size: $font-body-xs-font-size;
    padding: 0;
    height: rem(33);
    width: 100%;
    z-index: 100;
    @include breakpoint(medium) {
      font-size: $font-body-ms-font-size;
    }
    &__legal {
      padding: 0;
      margin: auto;
    }
  }

  //////////////////////////////////////////////////////////
  //// OVERRIDE STYLE FOR LANDSCAPE DEVICES
  &.landscape {
    .homepage-overlay {
      &__wrapper {
       flex-direction: row;
      }
      &__brand-logo-container {
        height: fit-content;
        top: rem(30);
        left: 50%;
        transform: translate(-50%, 0);
        width: rem(60);
      }
      &__content {
        height: 100vh;
        width: 100%;
        .overlay-container-picture {
          .overlay-image {
            object-position: 50% 0;
          }
        }
        .content-subtext-logo {
          font-size: $font-heading-xs-font-size;
          @include breakpoint(medium) {
            font-size: rem(large);
          }
        }
      }
    }
  }
}


/* ANIMATIONS */
@keyframes logo-fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fade-in-layer {
  0% { opacity: 0; }
  100% { opacity: 0.60; }
}

@keyframes contents-fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fade-in-description {
  0% { opacity: 0; }
  100% { opacity: 1; }
}