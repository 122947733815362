
////////////////////////////////////////
// Input .form-control
.form-control {
  -webkit-appearance: none;
  @include font-body-s;
  appearance: none;
  background-color: $form-field-background-color;
  border-bottom: $form-field-border-bottom-width solid $form-field-border-bottom-color;
  border-left: $form-field-border-width solid $form-field-border-color;
  border-right: $form-field-border-width solid $form-field-border-color;
  border-top: $form-field-border-width solid $form-field-border-color;
  display: inline-block;
  font-weight: 600;
  min-height: $form-field-height;
  padding: $form-field-padding-vertical $form-field-padding-vertical;
  width: 100%;
  &.form-input-no-border {
    border: none;
  }
  &.form-textarea-no-border {
    border: none;
    resize: none;

    &.single-row {
      min-height: 0;
      height: 26px;
      overflow-y: hidden;
      padding: 4px;
    }
  }

  &:focus {
    border-color: $form-field-border-color-focus;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }


  &.is-invalid{
    border-color: $color-error;
    border-width: .2rem;

    &:focus{
      outline-color: $color-error;
    }

  }

  /*
  &.is-valid{
    border-color: green;
    border-width: .2rem;

    &:focus{
      outline-color: green;
    }

  }*/


}

////////////////////////////////////////
// Textarea
textarea.form-control{

}



////////////////////////////////////////
// Input range

$form-field-range-thumb-size: 21px;

@mixin range-thumb() {
  //box-shadow: 1px 1px 1px $black, 0px 0px 1px #0d0d0d;
  border: 2px solid $form-field-border-color;
  height: $form-field-range-thumb-size;
  width: $form-field-range-thumb-size;
  border-radius: $form-field-range-thumb-size;
  background: $white;
  cursor: pointer;
}

@mixin range-track() {
  width: 100%;
  height: rem(8);
  cursor: pointer;
  //box-shadow: 1px 1px 1px $black, 0px 0px 1px #0d0d0d;
  background: $form-field-border-color;
  border: none;
  color: transparent;
  border-radius: rem(4);
}

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  margin: rem(7) 0;
}

input[type="range"]:focus {
  outline-offset: 7px;
}

//
// Input range Track
input[type="range"]::-webkit-slider-runnable-track {
  @include range-track();
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  //outline-offset: 15px;
}

input[type="range"]::-moz-range-track {
  @include range-track();
  border-color: transparent;
}

input[type="range"]::-ms-track {
  @include range-track();
  background: transparent;
  border-color: transparent;
  color: transparent;
}

//
// Input range Thumb
input[type="range"]::-webkit-slider-thumb {
  @include range-thumb();
  -webkit-appearance: none;
  margin-top: -6px;
}

input[type="range"]::-moz-range-thumb {
  @include range-thumb;
}

input[type="range"]::-ms-thumb {
  @include range-thumb;
  height: 8.4px;
}

input[type="range"]::-ms-fill-lower {
  background: $form-field-border-color;
  border: 0.2px solid $black;
  border-radius: 2.6px;
  //box-shadow: 1px 1px 1px $black, 0px 0px 1px #0d0d0d;
}
input[type="range"]::-ms-fill-upper {
  background: $form-field-border-color;
  border: 0.2px solid $black;
  border-radius: 2.6px;
  //box-shadow: 1px 1px 1px $black, 0px 0px 1px #0d0d0d;
}

input[type="range"]:focus::-ms-fill-lower {
  //background: $form-field-border-color;
}
input[type="range"]:focus::-ms-fill-upper {
  //background: $form-field-border-color;
}
