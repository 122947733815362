.accordion-privacy{
  margin: rem(10) 0;
}

.accordion-privacy__trigger {
  text-align: left;
  align-items: baseline;
  text-decoration: none;
  font-size: $form-accordion-header-font-size;
  font-weight: $form-accordion-header-font-weight;
  font-style: $form-accordion-header-font-style;
  line-height: $form-accordion-header-line-height;
  padding: 8px 8px 16px 8px;
  border-bottom: solid 1px #575A5A;

  &::after{
    position: relative;
    margin-left: rem(10);
    @include font-icon();
    text-decoration: none;
    top: rem(2);
  }

  &[aria-expanded="false"]{
    &::after{
      content: "\f105";
    }
  }

  &[aria-expanded="true"]{
    &::after{
      //content: "\f108";
      content: "\f105";
      transform: rotate(180deg);
    }
  }

}

.accordion-privacy__content {
  padding: rem(15);
  max-height: rem(700);
  background-color: $form-accordion-content-background-color;
  overflow-y: scroll;
  display: none;

  p {
    margin: 24px;
    font-size: $form-accordion-content-font-size;
    font-weight: $form-accordion-content-font-weight;
    font-style: $form-accordion-content-font-style;
    line-height: $form-accordion-content-line-height;
  }
}

[aria-expanded="true"] + .accordion-privacy__content{
  display: block;
}