/////////
// Gigya
.gigya-community {
  padding-top: 24px;
}

/////////
// send vin container
.send-vin-keyboard {
  margin: 0 auto;
  max-width: 840px;
  padding: 24px 0 55px;
}
// title
.title-vin {
  display: flex;
  justify-content: space-between;
  padding: 2px;
  margin-bottom: 16px;
  border-bottom: 1px solid $color-gray-lighter;
  font-weight: 700;
  #tit-vin-1 {
    font-family: $font-heading, serif !important;
    font-size: 18px;
    line-height: 25px;
    padding: 4px 0 !important;
  }
  #tit-vin-2 {
    color: $color-primary;
    cursor: pointer;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 25px;
    padding: 4px 0 !important;
  }
}

// messages common style
.message-label-light {
  font-size: 18px;
  font-weight: 300;
  line-height: 27px;
}
.message-label-normal {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
}
.message-label-bold {
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
}

// button
.vin-btn {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 36px;
}
.vin-cancel-btn {
  background-color: inherit !important;
  border: 2px solid;
  border-color: $color-primary !important;
  color: $color-primary !important;
  cursor: pointer;
  @include font-button-m;
  height: 51px;
  margin-right: 30px;
  text-transform: uppercase;
  transition: .3s !important;
  width: 255px;
  &:hover {
    color: #F1F1F1 !important;
    background-color: $color-primary !important;
  }
}
.vin-submit-btn {
  background-color: $color-primary !important;
  border-radius: $button-m-radius;
  border: 2px solid;
  border-color: $color-primary !important;
  color: #F1F1F1 !important;
  cursor: pointer;
  @include font-button-m;
  height: 51px;
  text-transform: $button-m-text-transform;
  transition: .3s !important;
  width: 255px;
  &:hover {
    color: $color-primary !important;
    background-color: inherit !important;
  }
}
.add-document-btn {
  background-color: $white !important;
  color: $color-primary !important;
  cursor: pointer;
  @include font-button-m;
  height: 51px;
  text-transform: uppercase;
  transition: .3s !important;
}

//input form
.vin-form-input {
  display:flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 39px;
  margin-top: 16px;
}
.input-vin {
  background-color: inherit;
  border: solid 0.5px;
  font-size: 18px;
  height: 44px;
  padding: 10px 8px;
  width: 100%;
}
#in-vin {
  border-color: $color-primary;
}
.label-input-vin {
  font-size: 18px;
  font-weight: 300;
  line-height: 27px;
}
.send-vin-form {
  max-width: 530px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
}
//warning and tick icon
.input-icon-form {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  .form-tick-icon {
    position: absolute;
    height: auto;
    top: 39%;
    transform: translateY(-50%);
    right: 5px;
  }
  .icon-text__icon.icon-tick::before {
    font-size: 20px;
  }
  .form-warning-icon {
    position: absolute;
    height: auto;
    top: 60%;
    transform: translateY(-50%);
    right: 5px;
    color: $color-error;
  }
  .icon-text__icon.icon-warning::before {
    font-size: 30px;
  }
}
//error message vin input
.error-message-vin {
  color: $color-error;
  font-size: 13px;
  margin-top: 102px;
  position: absolute;
}
//error send vin - add document
.add-document {
  text-align: center;
  background-color: $white;
  padding: 1rem;
}
.form-upload-redirect {
  line-height: 24px;
  margin-bottom: 1.5rem;
}

// displayed VIN
.motorcycle-chassis-number {
  display: flex;
  justify-content: flex-start;
}
.displayed-vin-label {
  display: inline-block;
  margin-right: 30px;
  min-width: 100px;
  width: 255px;
}
.displayed-vin-number {
  word-break: break-word;
}

// pending verification
.message-vin-check {
  background-color: $white;
  padding: 2rem 1.5rem;
  text-align: center;
}

// feedback after VIN
.success-vin-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 2.5rem;
  text-align: center;
}
.vin-title {
  font-size: 34px;
  line-height: 52px;
}
.vin-message {
  font-size: 18px;
  line-height: 27px;
  margin: 1.5rem 0 2.5rem;
  text-align: center;
  &-bold {
    font-weight: bold;
  }
}
.motorbike-icon {
  height: 64px;
  width: 64px;
  color: $color-primary;
}

//TABLET
@media screen and (min-width: 641px) and (max-width: 1023px) {
  // container
  .send-vin-keyboard {
    margin: 0 auto;
    padding: 24px 30px 55px;
  }
  // icon
  .input-icon-form {
    .form-warning-icon {
      top: 50%;
    }
    .form-tick-icon {
      top: 24%;
    }
  }
}

// MOBILE
@media screen and (max-width: 640px)  {
  // container
  .send-vin-keyboard {
    padding: 24px 15px 55px;
  }
  // icon
  .input-icon-form {
    .form-warning-icon {
      top: 50%;
    }
    .form-tick-icon {
      top: 24%;
    }
  }
  .motorbike-icon {
    height: 36px;
    width: 36px;
  }
  // displayed VIN label
  .displayed-vin-label {
    width: 100px;
  }
}

@media screen and (min-width: 1044px) {
  // icon
  .input-icon-form {
    .form-warning-icon {
      top: 60%;
    }
  }
}
