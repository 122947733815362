.banner-full {
  .button.button--primary.button--text-white,
  .button.button--primary.button--text {
    padding-left: 0 !important;
  }
  &__gradient, &__gradient.disabled {
    position: absolute;
      display: block;
      z-index: 0;
      height: 100%;
      width: 100%;
      top:0;
      left: 0;
    &.enabled {
      background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0.58%, rgba(0, 0, 0, 0) 70%);
      @include breakpoint(medium) {
        background-image: linear-gradient(90.01deg, rgba(0, 0, 0, 0.7) 0.58%, rgba(0, 0, 0, 0) 90%);
        }
    }
  }
  .banner-full-container.section__container {
    @include breakpoint(medium) {
      padding-right: 0;
      padding-left: 0;
    }
  }
  &__picture {
    color: $white;
    position: relative;
    display: block;
    .banner-full__info-container {
      font-family: $font-body;
      position: absolute;
      top: rem(16);
      left: rem(16);
      z-index: 1;
      width: 80%;
      @include breakpoint(medium) {
        width: 50%;
      }
      .banner-full__title {
        font-family: $font-heading;
        font-size: rem(24);
        font-weight: 700;
        line-height: rem(37);
        padding-bottom: rem(8);
      };
      .banner-full__paragraph {
        font-size: rem(18);
        font-weight: 300;
        line-height: rem(27);
        @include breakpoint(medium) {
          max-width: 70%;
        }
      };
      .banner-full__advanced-button {
        font-size: rem(16);
        color: $white;
        font-weight: 700;
        line-height: rem(10);
        letter-spacing: 0.2em;
        text-align: left;
        padding: rem(8) 0;
      };
    };
  };
  .btn-banner {
    margin-top: 16px !important;
    height: auto;
    padding: 16px !important;
  }
};