.accordion {
  font-family: sans-serif;
  padding-bottom: 20px;
}

.accordion__panel {
  padding-bottom: 10px;
}

.accordion__header {
  border-top: 1px solid $accordion-header-border-color;
  padding-bottom: rem(10);
  padding-top: rem(10);

  &:first-of-type {
    border-top: 0;
  }

}

.accordion__title {
  @include font-heading-s;
  padding: 0;
}

@include breakpoint(large) {
  .accordion__title {
    @include font-heading-m
  }
}

.accordion__header button {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: auto;
  border: 0;
  text-align: left;
  @include breakpoint(large) {
    width: calc(100% - 1rem);
  }
}

.accordion__icon {
  color: $color-primary;
  font-weight: 900;
}

/* Accordion content */
.accordion__panel-details {
  overflow: hidden;
  transition: height .5s ease;
  padding-bottom: rem(10);

  &:last-of-type {
    border-bottom: 1px solid $accordion-header-border-color;

  }
}

.accordion__panel-title {
  @include font-heading-xs;
}

@include breakpoint(large) {
  .accordion__panel-title {
    @include font-heading-s;
  }
}

.accordion__panel-enter-active {
  will-change: height;
  transition: height 0.5s ease;
}

.accordion__panel-enter, .accordion__panel-leave-to {
  height: 0 !important;
}

// custom two-columns-community-area
.editorial__support-header {
  .editorial__header-title {
    font-family: $font-heading-l-font-family;
    font-size: 3.4rem;
    font-weight: 700;
    line-height: 5.2rem;
    margin-bottom: 2.4rem;
    margin-top: 0;
    @include breakpoint(medium) {
      margin-top: 4.5rem;
    }
  }

  .editorial__header-desc {
    font-size: 1.8rem;
    font-weight: 300;
    line-height: 2.7rem;
    margin-bottom: 2.4rem;
  }
}
