/**
 * Design System for Piaggio Group
*/
.listing__item > .card-product {
  width: 100%;
  height: auto;
}

.card-product {
  text-align: $card-product-align;
  position: relative;
  background-color: $card-product-bg;
  height: auto;

  @include breakpoint(large) {
    height: 100%;
  }

  @if $card-product-border-radius {
    border-radius: $card-product-border-radius;
    overflow: hidden;
  }

  .card-product__img {
    overflow: hidden;
    position: relative;
    z-index: 2;
  }

  &.is-not-selected {
    border: rem(2) solid $color-background;
  }

  &.is-selected {
    border: rem(2) solid $form-selected-true-color !important;
  }

  &.cursor-default {
    cursor: default;
  }

  //.card-product__component {
  display: block;
  text-decoration: none;
  cursor: pointer;

  img {
    width: 100%;
    height: auto;
    position: relative;
    transform: scale(1.01);

    /*@if $card-product-hover-roller {
      background-color: $white;
    }*/
  }

  & > div:not(.custom-control) {
    position: relative;

    @if $card-product-hover-roller {
      z-index: 2;
    }
  }

  .icon-brand {
    display: none;
  }

  /*@if $card-product-border-radius {
    // accessibility workaround
    // when border radius and overflow hidden are present the outline is invisible
    &:focus {
      .card-product__name {
        outline: $general-outline-width solid $general-outline-color-on-light;
      }
    }
  }*/
  //}

  &.card-product-vehicle { // TODO: VERIFICARE CON CLAUDIO
    &:hover,
    &:focus {
      .card-product__name {
        text-decoration: underline;
      }
    }

    //a,
    //label {
    @if $card-product-hover-icon {
      img {
        z-index: 2;
      }
      .icon-brand {
        display: inline-block;
        position: absolute;
        z-index: 1;
        @include center(x);
        top: 10px;
        color: $color-gray-lightest;
        font-size: 10rem;
        transition: all 0.5s ease-in-out;

        @include breakpoint(large) {
          font-size: 12rem;
        }
      }
      &:hover,
      &:focus {
        .icon-brand {
          color: #cce7f5;
        }
      }
    }
    //}
  }

  &.card-product--accessory,
  &.card-product--service {
    background-color: $card-product-accessory-bg;
    border-bottom: 1px solid $card-product-accessory-border-color;

    @include breakpoint(medium down) {
      border: none;
    }

    .card-product__img {
      overflow: hidden;
      border-bottom: 1px solid $card-product-accessory-img-border-color;
      position: relative;

      &::after {
        content: "";
        background-color: $card-product-accessory-bg;
        mix-blend-mode: multiply;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        @supports not (mix-blend-mode: multiply) {
          opacity: 0.3;
        }
      }
    }

    img {
      transition: transform 0.5s linear;
    }

    .card-product__name {
      color: $card-product-accessory-name-color;
      word-break: break-word;

      @if ($card-product-accessory-name-height) {
        overflow: hidden;
        height: calc(#{$font-heading-xs-line-height} * 3);
      }
    }

    .card-product__price {
      color: $card-product-accessory-price-color;

      .icon-divider {
        color: $card-product-accessory-name-color;
      }
    }

    //a,
    //label {
    @if $card-product-hover-roller {
      &::after {
        content: "";
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 1;
        background-color: $color-primary;
        transition: height 0.6s;
        height: 0;
        position: absolute;
      }
    }
    //}

    &:hover,
    &:focus {
      img {
        transform: $card-product-hover-img;
      }

      @if $card-product-hover-roller {
        img {
          transform: none;
        }

        .icon-divider {
          color: $color-on-primary !important;
        }

        .card-product__price,
        .card-product__name {
          color: $text-color-white;
        }

        &::after {
          height: 100%;
        }
      }
    }
  }

  &.card-product--service {
    cursor: default;
  }

  // Accessories Carousel with disabled links
  &.card-product--accessory.cursor-default {
    img {
      transform: none;
    }
    &:hover,
    &:focus {
      .card-product__name {
        color: $card-product-accessory-name-color;
      }
      .card-product__price {
        color: $card-product-accessory-price-color;
      }
    }
    &::after {
      background-color: $card-product-accessory-bg;
    }
  }

  .card-product__promo {
    position: absolute;
    @include font-heading-xxs;
    background-color: $card-product-promo-background;
    color: $card-product-promo-color;
    border-radius: $card-product-promo-border-radius;
    display: inline-block;
    padding: 2px 5px 1px;
    text-transform: uppercase;
    bottom: 0;
    left: 0;
    z-index: 9;
  }

  .card-product__img {
    margin: 0;
  }

  .card-product__swatches {
    margin: 0 $space-size-s $space-size-xxs $space-size-s;
  }

  .card-product__name {
    margin: $space-size-xs $space-size-s;
    @include font-heading-xs;
    color: $card-product-name-color;
    display: block;
    @include breakpoint(medium down) {
      margin-right: rem(2);
      margin-left: rem(2);
    }
  }

  &.card-product--family {
    .card-product__img {
      @if $card-product-family-name-overlap {
        margin-bottom: 0;
      }
    }

    .card-product__name {
      text-transform: uppercase;
      font-weight: 400;
      //@include font-heading-s;

      @if $card-product-family-name-overlap {
        background-color: $color-primary;
        color: $color-on-primary;
        padding: rem(5);
        display: inline-block;
        transform: translateY(-100%);
        z-index: 3;
        position: absolute;
        margin: 0 rem(6) 0;
      }
    }
  }

  .card-product__price {
    padding: 0 $space-size-s $space-size-s;
    color: $text-color-black;
    @include font-body-s;
    display: block;

    .card-product__price__list {
      .icon-divider {
        display: none;
      }
    }

    .card-product__price__discounted + .card-product__price__list {
      text-decoration: line-through;
      display: inline-block;
      padding-left: 12px;
      position: relative;
      margin-left: 6px;

      .icon-divider {
        display: inline-block;
        @include center(y);
        left: $card-product-price-divider-left;

        font-size: $card-product-price-divider-size;
        line-height: 1;

        @include breakpoint(large) {
          position: absolute;
          left: $card-product-price-divider-left;
        }
      }
    }

    .icon-divider {
      color: $card-product-name-color;
    }

    .card-product__price__planned {
      display: block;
      @include font-body-xs;
      margin-top: $space-size-xxs;
    }
  }

  .custom-control {
    position: absolute;
    top: $card-product-form-control-position-top;
    right: $card-product-form-control-position-right;
    z-index: 10;
  }

  &.card-product--editorial {
    .card-product__img {
      position: relative;
      height: 0;
      padding-top: 66.67%;

      .image {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.pg-modal-price-container {
  .icon-divider {
    display: inline-block;
    color: $card-product-name-color;
    @include center(y);
    right: $card-product-price-divider-left;

    font-size: $card-product-price-divider-size;
    line-height: 1;

    @include breakpoint(large) {
      position: absolute;
      right: $card-product-price-divider-left;
    }
  }
}
