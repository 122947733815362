.bg--primary {
  background-color: $color-primary !important;
  color: $color-on-primary !important;

  &.sb-color {
    &::after {
      content: "#{$color-primary}";
    }
  }
}

.sb-color {
  &::after {
    font-size: 1.2rem;
    padding-left: 5px;
    position: relative;
    top: 4px;
    text-transform: uppercase;
  }
}
.sb-test {
  outline-width: 5px !important;
}

.bg--promo-blade {
  background-color: $promo-blade--bg-color;
  color: $promo-blade--color;
}

.bg--secondary {
  background-color: $color-secondary !important;
  color: $color-on-secondary !important;

  &.sb-color {
    &::after {
      content: "#{$color-secondary}";
    }
  }
}

.bg--background {
  background-color: $color-background !important;
  color: $color-on-background !important;

  &.sb-color {
    &::after {
      content: "#{$color-background}";
    }
  }
}

.bg--surface {
  background-color: $color-surface !important;
  color: $color-on-surface !important;

  &.sb-color {
    &::after {
      content: "#{$color-surface}";
    }
  }
}

.bg--error {
  background-color: $color-error !important;
  color: $color-on-error !important;

  &.sb-color {
    &::after {
      content: "#{$color-error}";
    }
  }
}

.bg--tertiary-1 {
  background-color: $color-tertiary-1 !important;
  color: $color-on-tertiary-1 !important;
  &.sb-color {
    &::after {
      font-size: 1.2rem;
      content: "#{$color-tertiary-1}";
      text-transform: uppercase;
    }
  }
}

.bg--tertiary-2 {
  background-color: $color-tertiary-2 !important;
  color: $color-on-tertiary-2 !important;
  &.sb-color {
    &::after {
      content: "#{$color-tertiary-2}";
    }
  }
}

.bg--tertiary-3 {
  background-color: $color-tertiary-3 !important;
  color: $color-on-tertiary-3 !important;
  &.sb-color {
    &::after {
      content: "#{$color-tertiary-3}";
    }
  }
}

.bg--tertiary-4 {
  background-color: $color-tertiary-4 !important;
  color: $color-on-tertiary-4 !important;
  &.sb-color {
    &::after {
      content: "#{$color-tertiary-4}";
    }
  }
}

.color--primary {
  color: $color-primary !important;
}

.color--secondary {
  color: $color-secondary !important;
}

.color--error {
  color: $color-error !important;
}

.color--black {
  color: $text-color-black !important;
}

.color--white {
  color: $text-color-white !important;
}

.color--tertiary-1 {
  color: $color-tertiary-1 !important;
}

.color--tertiary-2 {
  color: $color-tertiary-2 !important;
}

.color--tertiary-3 {
  color: $color-tertiary-3 !important;
}

.color--tertiary-4 {
  color: $color-tertiary-4 !important;
}
