.simple-image {
  width: 100%;
  display: flex;
  justify-content: center;
  img {
    width: auto;
    max-width: 100%;
  }
  @media screen and (min-width: 1920px) {
    img {
      max-width: 1920px;
    }
  }
}
