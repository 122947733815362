.hub-links.section {
  text-align: center;
  .grid-x.align-center {
    justify-content: center !important;
  }

  .section__container {
    padding: rem(80) rem(20);
      .grid-x.align-center {
        align-items: flex-start;
        justify-content: center;
        @include breakpoint(large) {
          justify-content: space-between;
        }
      }
    }

  .hub-links__title {
    //font-size: 28px;
    font-size: rem(34) !important;
    line-height: rem(52);
    @include breakpoint(medium) {
      font-size: 32px;
    }
    //line-height: 40px;
    font-weight: 400;
    font-family: $font-heading;
    color: $color-secondary;
  }

  .hub-links__link-container {
    width: 50%;
    display: flex;
    justify-content: center;
    max-width: rem(165);
    margin: rem(80) rem(7.5) 0;
    @include breakpoint(large) {
      margin: rem(40) 0;
      max-width: unset;
    }

      .hub-links__icon-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .hub-links__icon {
          font-size: rem(64);
          color: $black;
        }

        .hub-links__text {
          font-size: rem(18);
          font-family: $font-body;
          color: $color-primary;
          font-style: inherit;
          font-weight: 700;
          letter-spacing: 0.76px;
          text-transform: uppercase;
        }
      }
  }
  ////////////////////////////////////////
  // breakpoint medium
  @include breakpoint(medium) {
    .hub-links__title {
      font-size: rem(36);
    }

    .hub-links__link-container {
      width: 25%;

      .hub-links__icon-container {
        .hub-links__text {
          font-size: rem(18);
        }
      }
    }
  }
}
