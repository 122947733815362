.loader-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader {
  border-radius: 50%;
  width: 10em;
  height: 10em;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba($loader-border-color, 0.2);
  border-right: 1.1em solid rgba($loader-border-color, 0.2);
  border-bottom: 1.1em solid rgba($loader-border-color, 0.2);
  border-left: 1.1em solid $loader-border-color;
  transform: translateZ(0);
  animation: loading 1.1s infinite linear;
}
@-webkit-keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
