.card-small {
  width: fit-content;
  height: fit-content;
  background-color: $color-surface;
  @include breakpoint(medium) {
    margin: auto;
  }
  @include breakpoint(xlarge) {
    margin: 0;
    padding: 0;
  }
  .card-small-container.section__container {
    width: fit-content;
    height: fit-content;
    @include breakpoint(medium) {
      margin: 0;
      padding: 0;
    }
  }
  &__cta-links {
    margin-left: rem(-15);
  }
  &__picture {
    @include breakpoint(medium) {
      width: rem(540);
      height: rem(345);
    }
    width: 100%;
    max-width: rem(540);
    max-height: rem(345);
    position: relative;
    display: flex;
    overflow: hidden;
    .image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin: auto 0 0 0;
    }
    .card-small__gradient {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
      position: absolute;
      display: block;
      z-index: 0;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      &.disabled {
        background-image: none;
      }
    }
    .card-small__info-container-flex {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1;
    }
    .card-small__info-container {
      width: 100%;
      padding: rem(24);
      display: block;
      .card-small__event-category {
        font-family: $font-heading;
        font-size: $card-small-event-category-font-size;
        font-style: $card-small-event-category-font-style;
        font-weight: $card-small-event-category-font-weight;
        line-height: $card-small-event-category-line-height;
        text-transform: $card-small-event-category-text-transform;
        background-color: $card-small-event-category-background-color;
        color: $card-small-event-category-text-color;
        padding: $card-small-event-category-padding;
        border-bottom: $card-small-event-category-underline;
      }
      .card-small__title {
        font-family: $font-heading!important;
        font-size: $card-small-heading-font-size-mobile;
        font-style: $card-small-heading-font-style;
        font-weight: $card-small-heading-font-weight-mobile;
        line-height: $card-small-heading-line-height-mobile;
        letter-spacing: $card-small-heading-letter-spacing;
        text-align: left;
        margin-bottom:  rem(12);
        @include breakpoint(large) {
          font-size: $card-small-heading-font-size;
          font-style: $card-small-heading-font-style;
          font-weight: $card-small-heading-font-weight;
          line-height: $card-small-heading-line-height;
          letter-spacing: $card-small-heading-letter-spacing;
        }

        &.event {
          margin-top: rem(8);
        }
      }
      .card-small__title.cta-white {
        color: $white;
      }
      .card-small-cta-link {
        font-family: $font-body;
        font-size: $card-small-button-font-size;
        font-style: $card-small-button-font-style;
        font-weight: $card-small-button-font-weight;
        letter-spacing: $card-small-button-letter-spacing;
        text-transform: $card-small-button-text-transform;
        line-height: rem(10);
        text-align: left;
        margin: 0 rem(16);
      }
      .card-small-cta-link.cta-white {
        color: #fff;
        &::after {
          background-color: $card-small-button-underline-color;
        }
      }
    }
  }
}