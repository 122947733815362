.contact-form {
  min-height: 45vh;

  .form {
    margin: 2.4rem;
  }

  .custom-radio {
    display: inline-block;
  }

  .d-flex {
    display: flex;
  }

  .flex-field-valid {
    .valid-feedback {
      display: block;
    }
  }
  .flex-field-invalid {
    .invalid-feedback {
      display: block;
    }
  }

  .prefix {
    width: 20%;
    border-top: none;
    border-left: none;
    border-right: none;
    margin-right: 5px;
  }
}
