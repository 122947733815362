.pdp-page {
  .section.product-presentation.immersive {
    margin-top: rem(20);
  }
}
.section.product-presentation.immersive {
  padding-top: calc(var(--hero-padding) + 15px);
}
.product-presentation {
  .text-xs-upper {
    margin: 24px 0 14px 0;
    font-family: $font-body-s-font-family;
    font-style: $font-body-s-font-style;
    font-size: $font-body-s-font-size;
    font-weight: $font-body-s-font-weight;
    line-height: $font-body-s-line-height;
    text-transform: uppercase;

    .color-name-xs{
      text-transform: uppercase;
    }
  }

  .product-presentation_colors__swatches {
    ul {
      position: relative;
      z-index: 0;
    }
  }

  .product-presentation_colors__swatches, .product-presentation__colors .text-xs-upper {
    text-align: center;
    @include breakpoint(large) {
      text-align: unset;
    }
  }

  .product-presentation-immersive-swatch {
    width: rem(30);
    height: rem(30);
    .swatches-color__icon,
    .swatches-color__darken {
      font-size: rem(20);
      line-height: rem(31);
      padding: 0 3px;
    }
    .swatches-color__active {
      font-size: rem(30);
      line-height: $swatches-color-icon-size;
      top: unset;
    }
    .swatches-color__inactive {
      @include cover;
      font-size: rem(30);
      line-height: $swatches-color-icon-size;
      color: $swatches-inactive-color;
      top: unset;
    }
  }

  .pdp-immersive-breadcrumb {
    span {
      margin-left: rem(20);
    }
  }

  .container-title {
    position: relative;
    width: 100%;

    .title {
      color: $black;
      margin: 0 rem(39);
      @include breakpoint(large) {
        margin: 0;
      }
    }
  }

  .back-alignment {
    i {
      color: black !important;
    }
    &.mobile {
      position: absolute;
      left: rem(10);
      top: rem(20);
      transform: translateY(-50%);
      .breadcrumb__back {
        left: 0;
      }
      a {
        padding: 0;
        margin: 0;
        min-height: 0;
      }
      i {
        position: static;
        transform: none;
      }
      span {
        display: none;
      }
    }
  }

  .title {
    color: $text-color-primary;
    font-family: $font-heading-l-font-family;
    font-size: $font-heading-l-font-size-large;
    font-weight: $font-heading-l-font-weight;
    line-height: $font-heading-l-line-height-large;
    font-style: $font-heading-l-font-style;

    text-align: center;

    @include breakpoint(large) {
      text-align: left;
    }
  }

  // price

  .price {
    color: $text-color-black;
    font-family: $font-heading-m-font-family;
    font-size: $font-heading-m-font-size-large;
    font-weight: $font-heading-m-font-weight;
    line-height: $font-heading-m-line-height-large;
    font-style: $font-heading-m-font-style;

    text-align: center;

    @include breakpoint(large) {
      text-align: left;
    }

    .price-info {
      width: 14px;
      height: 14px;
      border-radius: 100%;
      background-color: $color-primary;
      position: absolute;
      top: 0;
      right: -30px;
    }
  }

  .product-presentation__price {
    margin: 10px 0 0 0;
    position: relative;

    .product-price-immersive__tooltip {
      position: absolute;
      bottom: auto;
      left: auto;
      right: $product-presentation-immersive-tooltip-right-m;
      top: $product-presentation-immersive-tooltip-top-m;
      @include breakpoint(medium) {
        right: $product-presentation-immersive-tooltip-right;
        top: $product-presentation-immersive-tooltip-top;
        transform: none;
      }

      i[class^="icon-"] {
        color: $color-primary;
      }

      span {
        z-index: 1000;
      }

      .tooltip__content {
        font-size: $product-presentation-immersive-font-size !important;
        font-weight: $product-presentation-immersive-font-weight !important;
        line-height: $product-presentation-immersive-font-line-height !important;
        top: $product-presentation-immersive-tooltip-content-top;
        max-width: rem(175);
        width: rem(175);
        color: $white;
        background-color: $color-background-gray;
        backdrop-filter: blur(8px);

        &::after {
          border-left: 9px solid transparent;
          border-right: 9px solid transparent;
          border-top: 9px solid $color-background-gray;
          left: 50.5%;
          bottom: $product-presentation-immersive-tooltip-content-bottom;
          transform: rotate(180deg) translateX(50%);
        }
      }
    }
  }


  .product-price {
    .product-price__discounted {
      @include font-heading-l;
      white-space: nowrap;
      position: relative;
      display: inline-block;
    }

    .product-price__list {
      position: relative;
      display: inline-block;
      @include font-heading-l;
      white-space: nowrap;

      .icon-divider {
        display: none;
      }
    }

    .product-price__discounted + .product-price__list {
      text-decoration: line-through;

      @include font-body;
      margin-right: $space-size-xs * 2;
      position: relative;
      display: inline-block;

      @include font-heading-s;
      margin-left: $space-size-xs * 2;

      .icon-divider {
        display: inline-block;
        @include center(y);
        right: $product-presentation-price-divider-right;

        color: $color-primary;
        font-size: $product-presentation-price-divider-size;
        line-height: $product-presentation-price-divider-size;

        @include breakpoint(large) {
          position: absolute;
          left: $product-presentation-price-divider-right;
          right: auto;
        }
      }
    }

    .product-price__planned {
      @include font-body;
      white-space: nowrap;

      @include breakpoint(large) {
        display: block;
      }
    }
  }

  .product-presentation-container {
    @include breakpoint(large) {
      padding-right: rem(40);
    }
    @media screen and (min-width: 1366px) {
      padding-right: rem(112);
    }
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .santander-simulator {
      margin: 24px 10px 0 10px;
      @include breakpoint(large) {
        margin: 24px 0 0 0;
      }

      .button {
        font-style: $font-button-m-font-style;
      }
    }

    .pdp-cta-links {
      @include breakpoint(large) {
        margin-top: 16px;
      }
    }

    .container-left {
      @include breakpoint(large) {
        width: 60%;
      }
      @include breakpoint(xlarge) {
        width: 65%;
      }
      @media screen and (min-width: 1720px) {
        width: 70%
      }
      width: 65%;
      height: 100vh;
      overflow: hidden;
      position: relative;

      .product-image {
        @media screen and (max-width: 1023px) and (min-width: 641px) {
          width: 90%;
        }
        @include breakpoint(large) {
          width: 130%;
        }
        @include breakpoint(xlarge) {
          width: 110%;
        }
        position: absolute;
        top: 0;
        right: 0;
        max-width: unset;
        width: 100%;
        transform: translateX(100%);
        &.translate-origin {
          @media screen and (max-width: 1023px) and (min-width: 641px)  {
            transform: translateX(-300px);
          }
          @include breakpoint(large) {
            transform: translateX(-100px);
          }
          @include breakpoint(xlarge) {
            transform: translateX(-190px) translateY(-10px);
          }
        }
        &.placeholder {
          height: auto;
          overflow: hidden;
        }
      }
    }

    .container-right {
      @include breakpoint(large) {
        width: 40%;
        margin-top: rem(52);
      }
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding-right: 0;
      overflow: initial;
      .product-image {
        max-width: unset;
        width: 140%;
        transform: translateX(-100%);
        @media screen and (max-width: 1023px) and (min-width: 641px) {
          width: 110%;
          padding: 0;
        }
        &.translate-origin {
          transform: translateX(-120px);
          @media screen and (max-width: 1023px) and (min-width: 641px) {
            transform: translateX(-160px);
          }
        }
      }
      .content-right {
        @include breakpoint(large) {
          padding: 0 10% 0 0;
        }
        @media screen and (min-width: 1366px) {
          padding: 0 10%;
        }
        width: 100%;
        .text-xs-upper {
          @include breakpoint(large) {
            margin: 24px 0 14px;
          }
          width: fit-content;
          margin: 24px auto 14px
        }
        .displacements-text {
          @include breakpoint(large) {
            padding: 0;
          }
          margin: 24px 0 14px;
          padding-left: rem(12.5);
        }
        .color-displacement-container {
          @include breakpoint(large) {
            margin: 0 0 0 5px;
          }
          width: fit-content;
          margin: 0 auto;
        }
      }
    }
  }

  .right-info-container {
    .text-center-mobile {
      margin: rem(24) 0 rem(8) 0;
    }
    @media screen and (max-width: 1023px) and (min-width: 641px) {
      width: 100%;
    }
    @include breakpoint(large) {
      padding: 0;
    }

    .text-center-mobile {
      margin: 20px 0 8px 0;
      text-align: center;
      @include breakpoint(large) {
        padding: 0;
        text-align: left;
      }
      margin: 20px 0 8px 0;
      padding-left: rem(12.5);
      text-align: center;
      &.displacements-text {
        margin: 5px 0 8px 0;
      }
    }
    width: 100%;
    padding: rem(12.5) 0;
    .container-displacement {
      min-height: rem(70);
      height: fit-content;
      @include  breakpoint(large) {
        padding: 0;
      }
      display: flex;
      gap: 10px;
      width: 100%;
      padding: 0 rem(12.5);
      .button-displacement-container {
        height: fit-content;
        width: 33%;
        text-align: center;
        margin-bottom: 10px;
        .displacement {
          &.disabled {
            border: dashed 1px $color-gray;
            background-color: $white;
            color: $color-gray;
          }
          &.selected {
            border: solid 1px $color-primary;
            background-color: rgba($color-primary, 0.12);
            box-shadow: 0 4px 4px $medium-gray;
          }
          font-family: $font-button-m-font-family;
          font-size: $font-button-m-font-size;
          font-weight: $font-button-m-font-weight;
          font-style: $font-button-m-font-style;
          display: flex;
          justify-content: center;
          max-height: rem(48);
          width: 100%;
          cursor: pointer;
          align-items: center;
          border: solid 1px $color-gray;
          border-radius: 10px;
          height: max-content;
          background-color: rgba($color-gray-lighter, 0.12);
          padding: 10px;
          margin-bottom: 5px;
        }
      }
    }
    .container-setup-over {
      height: fit-content;
      &.show-more {
        height: fit-content;
        text-transform: capitalize;
      }
      .container-setup {
        &.show-more-container {
          height: rem(395);
        }
        .show-more-gradient {
          width: 100%;
          height: rem(50);
          position: absolute;
          left: 0;
          bottom: 0;
          background: linear-gradient(0deg, $color-background, transparent 150% 100%);
          z-index: 100;
          &.show-more {
            text-transform: capitalize;
            background: unset;
          }
        }
        .vehicle {
          text-transform: uppercase;
          max-width: 21%;
          @media screen and (max-width: 1720px) {
            max-width: 30%;
          }
          padding: 8px 0;
          cursor: pointer;
          height: fit-content;
          img {
            position: relative;
            z-index: -1;
            width: 100%;
          }
          span {
            display: block;
            margin: 10px auto 0;
          }
          &.selected {
            border: solid 1px $color-primary;
            border-radius: 10px;
            box-shadow: 2px 2px 7px $medium-gray;
            padding-right: 2px;
            padding-left: 2px;
          }
          &.disabled {
            opacity: 0.6;
            color: #575A5A;
            cursor: pointer;
          }
        }
        position: relative;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        padding-bottom: rem(20);
        overflow: hidden;
        gap: 0 5%;
        &.show-more {
          height: fit-content;
          text-transform: capitalize;
        }
        &.fade-in-out {
          opacity: 0;
          animation: fade-out 50ms ease-out forwards, fade-in 300ms 300ms ease-in forwards;
        }
      }
      .show-setup-button {
        cursor: pointer;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: rem(30);
      }
    }
    .swiper {
      text-align: center;
      text-transform: uppercase;
      margin-bottom: rem(30);
      padding: 0 rem(10);

      /*.swiper-slide-margin-left {
        margin-left: -140px !important;
      }*/

      .vehicle {
        width: fit-content;
        padding: 8px 0;
        &.selected {
          border: solid 1px $color-primary;
          border-radius: 10px;
          box-shadow: 2px 2px 7px $medium-gray;
        }
        &.disabled {
          opacity: 0.6;
          color: #575A5A;
          cursor: pointer;
        }
      }
    }
    .buttons-container {
      display: flex;
      flex-direction: column;
      margin: 0px 10px;
      @include breakpoint(large) {
        margin: 0px;
      }
      .book-appointment-button, .configuration-button {
        margin-right: 0!important;
        width: 100%;
      }
      .configuration-button {
        margin-top: rem(12);
      }
    }
  }

  /* ANIMATIONS */
  //Setup carousel transition
  @keyframes fade-in {
    100% {
      opacity: 1;
    }
  }

  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  //Show more button transition
  .fade-buttons-enter-active, .fade-buttons-leave-active {
    transition: opacity 0.2s;
  }
  .fade-setup-enter, .fade-setup-leave-to,
  .fade-buttons-enter, .fade-buttons-leave-to {
    opacity: 0;
  }

  //Fade out on displacement click
  .product-image.fade-out {
    animation-name: fade-out-displacement;
    animation-duration: 1200ms;
    animation-fill-mode: forwards;
  }

  @keyframes fade-out-displacement {
    100% {
      opacity: 0;
    }
  }

  //Image Animation on page Load
  .product-image.transition-onload {
    animation-name: transition-onload;
    animation-duration: 1000ms;
    animation-fill-mode: forwards;
  }

  @include breakpoint (small) {
    @keyframes transition-onload {
      0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(-120px);
      }
    }
  }

  @media screen and (max-width: 1023px) and (min-width: 641px) {
    @keyframes transition-onload {
      0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(-160px);
      }
    }
  }

  @include breakpoint(large) {
    @keyframes transition-onload {
      0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(-100px);
      }
    }
  }

  @include breakpoint(xlarge) {
    @keyframes transition-onload {
      0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(-190px) translateY(-10px);
      }
    }
  }

  /*@include breakpoint(xxxlarge) {
    @keyframes transition-onload {
      0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(-360px) translateY(-10px);
      }
    }
  }*/

  //fade-image color-variation
  .fade-image-enter-active, .fade-image-leave-active {
    transition: opacity 0.7s;
  }

  .fade-image-enter, .fade-image-leave-to {
    opacity: 0;
  }

  //fade-image displacement-variant
  .fade-image-displacement-leave-active {
    transition: opacity 0.7s;
  }
  .fade-image-displacement-leave-to {
    opacity: 0;
  }
}

//Remove t-model-version borders from the body - PIAGGIO:9252
&:not(.sb-show-main) {
  @if $body-effect {
    &.t-model-version.pdp-page {
      &::before, &::after {
        border-color: transparent transparent transparent transparent !important;
      }

      //Set navbar background visible
      &:not(.main-navigation-open) {
        .header:not(.header--sticky) {
          nav {
            &::before,
            &::after {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
