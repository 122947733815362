////////////////////////////////////////
// editorial row
.editorial-row {
  @include xy-cell(12);

  @include breakpoint(large) {
    @include xy-cell(6);
  }
}


////////////////////////////////////////
// editorial blockquote
.editorial__blockquote {
  @include font-quote();
  margin: 10px 10px 0;
  /*padding: 60px;*/
  padding-top: rem(40);
  padding-bottom: rem(20);
  position: relative;
  border: none;


  .icon-quote-left,
  .icon-quote-right {
    color: $editorial-blockquote-quote-color;
    font-size: rem(40);
    position: absolute;
    font-weight: 700;
  }

  .icon-quote-left {
    top: 0;
    left: rem(-20);
  }

  .icon-quote-right {
    transform: rotate(180deg);
    right: rem(-20);
    bottom: 0;
  }

  @include breakpoint(large) {

    .icon-quote-left,
    .icon-quote-right {
      font-size: rem(64);
    }

    .icon-quote-left {
      top: rem(9);
      left: rem(-100);
    }

    .icon-quote-right {
      right: rem(-100);
      bottom: rem(-12);
    }

  }

}

////////////////////////////////////////
// editorial author
.editorial__author {
  @include font-body();
  text-align: right;
  margin-right: 2.5rem;
}
