/**
 * Design System for Piaggio Group
*/
.icon-vespa_pin {
  font-size: 24px;
}
.header {
  height: $header-height-small;

  &:not(.v2){
    @if $header-border {
      border-bottom: 1px solid $header-border;
    }
  }
  position: relative;
  border-radius: $header-border-radius-small;
  margin: $header-margin-small;
  top: 0;
  right: 0;
  left: 0;
  z-index: 101;
  color: $header-color-on-background;
  overflow: hidden;
  max-width: rem(1920);

  &.not-visible {
    display: none;
  }

  nav {
    &::before,
    &::after {
      transition: opacity 0.2s ease-in-out;
    }
    &::after {
      // default background
      display: block;
      content: "";
      position: absolute;
      z-index: 0;
      background-color: $header-background;
      //height: $header-height-small;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: $header-border-radius-small;

      @include breakpoint(large) {
        border-radius: $header-border-radius-large;
        //height: $header-height-large;

        @if ($header-effect) {
          height: $header-height-large - 20px;
        }
      }
    }
  }

  @include breakpoint(large) {
    margin: $header-margin-large;

    nav {
      @if $header-effect {
        &::before {
          // skewed background
          display: block;
          content: "";
          position: absolute;
          z-index: 1;
          background-color: $header-background;
          height: $header-height-large - 26px;
          left: 0;
          right: 0;
          bottom: 5px;
          border-radius: $header-border-radius-large;
          transform: skewY(-0.5deg);
        }
      }
    }
  }

  @include breakpoint(xxxlarge) {
    margin: 0 auto;
  }

  button,
  a {
    color: $header-color-on-background;
  }

  nav {
    @include xy-grid;
    height: 100%;
    align-items: center;
    z-index: 1;
    position: relative;
    justify-content: space-between;
  }

  ////////////////////////////////////////
  // layout default version
  .header__logo {
    z-index: 1;
    @include xy-cell(6, $output: false, $gutters: 0);

    @include breakpoint(large) {
      @include xy-cell(4, $output: false, $gutters: 0);
    }
  }

  .header__menu-shortcuts {
    z-index: 1;
    @include show-for(large);
    @include xy-gutters(0);

    @include breakpoint(large) {
      @include xy-cell(4, $output: false, $gutters: 0);
    }
  }

  .header__menu-services {
    z-index: 1;
    @include xy-cell(6, $output: false, $gutters: 0);

    @include breakpoint(large) {
      @include xy-cell(4, $output: false, $gutters: 0);
    }

    .header__menu-services__search {
      @include show-for(large);
    }
  }

  ////////////////////////////////////////
  // layout variant version
  &.header--centered-logo {

    nav{
      justify-content: flex-end;
    }
    
    .header__logo {
      @include center();
      text-align: center;
    }

    .header__menu-shortcuts {
      display: none !important;
    }

    .header__menu-services {
      /*@include breakpoint(large) {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }*/

      margin: 0 $space-size-s;

      .header__menu-services__item,
      .header__menu-services__search {
        @include show-for(large);
      }

      .header__menu-services__main {
        @include center(y);
        right: 0;

        /*@include breakpoint(large) {
          position: static;
          transform: none;
        }*/
      }

      .header__menu-services__nav {
        @include center(y);
        left: 0;

        /*@include breakpoint(large) {
          position: static;
          transform: none;
        }*/
      }
    }
  }

  ////////////////////////////////////////
  // other styles
  .show-on-focus {
    &:focus {
      @include font-body-s;
      position: absolute !important;
      top: 50%;
      margin-top: -9px;
      left: 15%;
      z-index: 10;
    }
  }

  .header__logo {
    .header__logo__on-background,
    .header__logo__on-tranparent {
      display: inline-block;
      padding: $space-size-s;

      img {
        max-height: 35px;

        @include breakpoint(large) {
          max-height: 45px;
        }
      }

      &:focus {
        outline-offset: $general-outline-width * -1;
      }
    }

    .header__logo__on-background {
      display: inline-block;
    }
    .header__logo__on-tranparent {
      display: none;
    }
  }

  .header__menu-shortcuts {
    text-align: $header-shortcuts-align;

    li {
      display: inline-block;

      a {
        @include font-body-s;
        padding: $space-size-s;
        display: inline-block;
        text-decoration: $header-shortcuts-text-decoration;
        text-transform: $header-shortcuts-text-transform;
      }
    }
  }

  .header__actions {
    display: flex;
    align-items: center;
  }

  /* Login gigya */
  .header__login-gigya {
    .link_login_gigya {
      color: #FFF;
      font-size: 18px;
      text-transform: uppercase;
      text-decoration: none;
      z-index: 1;
      position: relative;
      display: flex;
      align-items: center;

      span {
        margin-right: 10px;
        //text-decoration: underline;
        font-weight: 700;
        font-size: 13px;
        line-height: 15px;
        border-bottom: solid 1.5px rgba(255, 255, 255, 0.5);
        padding-bottom: 2px;
      }

      &::after {
        content: '\00a0';
        background-image: $gigya-login-icon;
        background-size: 25px;
        background-repeat: no-repeat;
        display: flex;
        height: 25px;
        width: 25px;
      }

      &.logged {
        &::after {
          background-image: $gigya-logged-icon;
        }
      }
    }
  }

  .header__logout-gigya {
    a.link_logout_gigya {
      border-color: #adadad;
      border-bottom: solid 1.5px;
      color: #FFF;
      display: none;
      font-size: 13px;
      font-weight: 700;
      line-height: 15px;
      margin-right: 25px;
      padding-bottom: 2px;
      position: relative;
      text-decoration: none;
      text-transform: uppercase;
      z-index: 1;
    }
  }

  .header__menu-services {
    text-align: right;
    display: flex;
    align-items: center;

    @if $header-effect {
      position: relative;
      top: -5px;
    }

    ul,
    li {
      display: inline-block;

      button {
        color: $header-services-color;
        font-size: $header-services-size;
        //line-height: $header-services-size;
      }
    }

    .header__menu-services__nav {
      color: $header-hamburger-color;
      font-size: $header-hamburger-size;
      //line-height: $header-hamburger-size;

      &[aria-expanded="true"] {
        i {
          @extend .icon-close;
        }
      }
      margin: 0 $space-size-s;
    }
  }

  & {
    transition: transform 0.2s ease-in-out;
  }

  &.header--sticky {
    position: fixed;
    opacity: 1;
    transform: translateY(0);

    nav {
      &::before,
      &::after {
        opacity: 1;
      }
    }
  }

  &.header--hidden {
    position: fixed;
    opacity: 0;
    transform: translateY(-100%);
  }

  &.header--promo {
    top: rem(44);
  }
}

.header__login-modal {
  background-color: $color-gray-lightest;
  min-height: 15rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  padding: 1.2rem 2rem;
  position: fixed;
  right: 6rem;
  top: 7rem;  // header height
  width: 32rem;
  z-index: 110;  // if not enough, increase it looking into hero-heading value
  .modal-heading {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .modal-links {
      display: flex;
      flex-direction: column;
      font-size: 1.3rem;
      font-weight: 400;
      gap: 1.6rem;
      line-height: 1.5rem;
      padding: 2rem 0;
      .modal-option {
        color: $black;
        justify-content: flex-start;
        text-decoration: underline;
      }
    }
    .modal-close-btn {
      padding: 0.5rem 0;
      .icon-close:before {
        background-color: $white;
        border-radius: 2rem;
        color: $color-primary;
        font-size: 1.8rem;
        padding: 0.5rem;
      }
    }
  }
  .modal-separator {
    background-color: $black;
    height: 1px;
  }
  .modal-logout {
    color: $color-primary;
    font-size: 1.6rem;
    font-weight: 700;
    justify-content: flex-start;
    line-height: 2rem;
    padding: 0.8rem 0;
    text-decoration: none;
    text-transform: uppercase;
  }
}

.modal-overlay {
  background: $black;
  height: 100vh;
  left: 0;
  opacity: 0.6;
  position: fixed;
  width: 100vw;
  z-index: 102;  // linked to header__login-modal
}

@media screen and (max-width: 767px) {
  #account-login span {
    display: none;
  }

  .header__login-modal {
    margin: 0 2.8rem;
    min-width: 26.4rem;
    right: 0;
    width: auto;
    width: -webkit-fill-available;
  }
}

// PIAGGIO-8774: visibility with zoom 400%
@media screen and (max-width: 320px) {
  .header__login-modal {
    gap: 1rem;
    margin: 0;
    min-width: unset;
    right: 0;
    top: 0;
    .modal-heading {
      .modal-links {
        gap: 1.4rem;
        padding: 1.2rem 0;
      }
    }
    .modal-logout {
      padding: 0.5rem 0;
    }
  }
}

@mixin transparentHeader {
  position: fixed;
  border-color: transparent;
  color: $text-color-white;

  * {
    color: $text-color-white;
  }

  *:focus {
    outline-color: $general-outline-color-on-dark;
  }

  nav {
    &::before,
    &::after {
      opacity: 0;
    }

  }

  .header__logo__on-background {
    display: none;
  }
  .header__logo__on-tranparent {
    display: inline-block;
  }
  .header__login-gigya {
    .link_login_gigya {
      span {
        border-color: #fff;
      }
    }
  }
}

// header trasparent on hero
&:not(.main-navigation-open).hero-active,
.hero-active {
  .header:not(.header--sticky) {
    &:not(.v2) {
      @include transparentHeader;
    }
  }

}

.main-navigation-open {
  .navigation__content {
    display: flex;
  }
}

// header trasparent but not sticky on aprilia PDP (exception!!)
@if $header-transparent-not-sticky-pdp {
  &.t-model-version:not(.main-navigation-open) {
    .header:not(.header--sticky):not(.v2) {
      @include transparentHeader;
      position: relative;
    }
  }
}


//over 1440px
@media screen and (min-width: 1441px) {
  .header__login-modal {
    position: absolute;
  }
}

/* Removed due to PIAGGIO-6965
// temporary code for motoguzzi 100 years anniversary... to be removed really quickly
&.theme-moto-guzzi {
  header.header { overflow: visible !important; z-index: 101 !important;}
  .header__logo { padding-top: 46px !important;}
  .header__logo .image { max-height: 65px !important;}
}
 */