.editorial-icon {
  text-align: center;

  a:link:not(.button), a:visited:not(.button) {
    // color: $general-link-color;
    color: inherit;
    text-decoration: underline;
  }
  .icon-text__icon {
    font-size: rem(36);
    color: $editorial-icon-secondary-color;
  }
  .icon-text__title {
    @include font-heading-m();
  }
  &__title {

    margin-top: rem(10);
    margin-bottom: rem(20);

    &::after {
      content: "";
      border-bottom: $editorial-icon-title-after-border-bottom;
      display: block;
      width: 60px;
      text-align: center;
      margin-right: auto;
      margin-left: auto;
      height: 2px;
      margin-top: $editorial-icon-title-after-margin-top;
    }
  }

  &__text {
    margin-bottom: rem(10);
  }

  &.editorial-icon--two-columns,
  &.editorial-icon--four-columns,
  &.editorial-icon--three-columns,
  &.editorial-icon--four-columns {
    @include xy-cell(12);
  }

  ////////////////////////////////////////
  // breakpoint Large
  @include breakpoint(large) {
    .icon-text__icon {
      font-size: rem(64);
    }
    ////////////////////////////////////////
    // 2 item two columns
    &.editorial-icon--two-columns {
      @include xy-cell(5);
    }

    ////////////////////////////////////////
    // 3 item three columns
    &.editorial-icon--three-columns {
      @include xy-cell(27.8%);
    }

    ////////////////////////////////////////
    // 4 item four columns
    &.editorial-icon--four-columns {
      @include xy-cell(21%);
    }
  }
}

.grid-x.align-center.one_icon  {
  justify-content: center !important;
}