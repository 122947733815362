@mixin input-error {
  border-color: $form-item-error-text-color !important;
  margin-bottom: 5px !important;
}
// page container
.gigya-community {
  // background-color: $white;
  padding: 40px 30px 30px;
  // screen caption title
  .gigya-screen-caption {
    border-bottom: none;
    color: $black;
    font-family: $font-heading, serif !important;
    font-size: 34px;
    font-weight: 700;
    line-height: 52px;
    margin: 0 auto 24px;
    padding: 0 0 0 15px;
  }
  // login in page - form container
  .gigya-login-form {
    // login - email field
    .gigya-composite-control-loginID {
      margin: 0;
    }
    // login - password field
    .gigya-composite-control-password {
      max-width: none;
    }
    // login - new user section
    .new-user-label {
      font-family: $font-heading, serif !important;
      font-size: 18px !important;
      margin: 0 0 16px !important;
    }
  }
}
// only for 2 columns template!
.section-community .gigya-community {
  padding: 4.5rem 6.5rem 0 0;
  @include breakpoint(xlarge) {
    padding: 4.5rem 12.5rem 0 0;
  }
  #gigya-login-one-column {
    padding-right: 30px;
  }
  .gigya-screen.portrait {
    margin: 0;
  }
  .gigya-screen-caption {
    margin: 0 0 24px;
    padding: 0;
  }
}
// login - modal container
.gigya-screen-dialog,
.gigya-screen-dialog-mobile {
  height: 100vh !important;
  left: auto !important;
  right: 0;
  width: 500px !important;
  .gigya-screen-dialog-main {
    height: 100%;
    max-height: 100vh;
  }
  // login - top bar with close btn
  .gigya-screen-dialog-top {
    background-color: $white !important;
    display: flex;
    justify-content: space-between;
    line-height: 20px;
    padding: 0 0 0 15px;
    width: 500px !important;
    // login - title
    .gigya-screen-dialog-caption {
      align-self: center;
      color: $black;
      font-size: 18px !important;
      font-weight: 700;
      letter-spacing: .05em;
      padding-bottom: 0;
      font-family: $font-heading-l-font-family !important;
    }
    // login - close btn
    .gigya-screen-dialog-close {
      align-items: center;
      background-color: $color-primary-alt;
      display: flex;
      flex-direction: column;
      float: right;
      height: 56px;
      justify-content: center;
      padding: 5px;
      width: 56px;
      .gigya-close-btn-link {
        background-image: url('../../assets/img/moto-guzzi/close-comm.svg');
        background-repeat: no-repeat;
        background-size: contain;
        height: 40px;
        width: 40px;
      }
    }
    .gigya-screen-dialog-close:hover,
    .gigya-screen-dialog-close:focus {
      color: black;
      text-decoration: none;
      cursor: pointer;
    }
  }
  // login - modal content
  .gigya-screen-dialog-inner {
    padding: 0;
    height: calc(100% - 56px);
    width: 100%;
    // login & verification - modal form scrollbar
    #gigya-login-form,
    #gigya-profile-form,
    #gigya-resend-verification-code-form {
      max-height: calc(100vh - 100px);
      overflow-x: hidden;
      overflow-y: auto;
      padding: 15px;
      padding-top: 24px;
    }
    #gigya-verification-sent-screen {
      max-height: calc(100vh - 55px);
      overflow-x: hidden;
      overflow-y: auto
    }
    .register-success-button {
      margin: 0;
    }
    .login-field {
      max-width: unset;
    }
  }
}
// required for developer mode & mobile
.gigya-screen-dialog-mobile .gigya-screen-dialog-inner {
  margin-top: 56px !important;
  #gigya-login-form,
  #gigya-profile-form {
    max-height: calc(100vh - 88px);  // overrides the default height
  }
}
// login - same container level of gigya-screen
#gigya-login-one-column {
  width: 100%;
}
// custom class for 'or'
#forgot-password-reminder-or {
  font-size: 18px !important;
  font-weight: 400 !important;
}
// registration email - community
#uuid-de0eddcf-66f6-41fd-91ce-825046424e61 {
  padding: 0;
}
// success screens - portrait screen id
#gigya-registration-success.gigya-screen.v2.portrait,
#gigya-forgot-password-success-screen.gigya-screen.v2.portrait {
  padding: 15px;
}

// reset password success - window id
#gigya-reset-password-success-screen.gigya-screen.v2.portrait {
  max-width: 835px;
  padding: 0 !important;
  width: 100%;
  .register-success-button {
    max-width: 345px;
  }
}
.gigya-screen .gigya-composite-control.gigya-composite-control-header.login-title.reset-password-success-title {
  font-family: $font-heading-l-font-family  !important;
}
.gigya-composite-control.gigya-composite-control-label.reset-password-success-main-text {
  font-size: 18px;
}
// verification pending - window container
#gigya-verification-pending-screen {
  //padding: 15px;
  // form title
  .pending-verification {
    letter-spacing: .05em;
    font-size: 18px;
    color: $black;
  }
  .register-success-button {
    margin: 0;  // required for page content
  }
}
//pending registration - mail sent
#gigya-verification-sent-screen {
  padding: 15px;
  .register-success-description {
    text-align: left !important;
    margin-bottom: 24px !important;
  }
}
.gigya-composite-control.gigya-composite-control-link.register-redirect-link.verification-sent-redirect {
  margin-top: 16px !important;
  width: 95%;
}
.gigya-label-text.gigya-composite-control.gigya-composite-control-label.main-text.register-success-description {
  text-align: center !important;
}
// common container for all Gigya related updates
.gigya-screen {
  color: $black;  // same value is updated on gigya
  // social register&login
  .gigya-login-providers-list-container {
    width: auto !important;
    .gigya-login-provider {
      margin: 20px;
    }
  }
  .gigya-composite-control.gigya-composite-control-header.login-title {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    span {
      margin: 0;
    }
    .login-label {
      letter-spacing: .05em;
      font-size: 18px !important;
      font-weight: 700;
      padding-bottom: 0;
      color: $black;
    }
  }
  // login - link style
  .gigya-href-link {
    color: inherit;
    font-size: inherit !important;
    font-weight: 700;
    line-height: 27px;
    text-decoration: underline;
  }
  // generic link style
  .gigya-href-link-style {
    color: inherit;
    font-size: inherit !important;
    text-decoration: underline;
  }
  // reset password success - tick icon
  .reset-password-success-icon {
    .icon-tick-fill:before {
      color: $color-primary;
      display: flex;
      font-size: 6.4rem;
    }
  }
  .gigya-composite-control.gigya-composite-control-header.login-title.reset-password-success-title {
    font-size: 34px;
    justify-content: center !important;
    line-height: 40px;
  }
  // forgot password - input div
  .gigya-reset-password-form .gigya-composite-control-loginID {
    max-width: 345px !important;
  }
  // forgot&reset password - title
  .gigya-composite-control.gigya-composite-control-header.forgot-password-title,
  .gigya-composite-control.gigya-composite-control-header.reset-password-title {
    font-size: 34px !important;
    font-weight: 700 !important;
    line-height: 40px !important;
    font-family: $font-heading-l-font-family !important;
  }
  // forgot password - forgot password email sent label
  .gigya-label-text.gigya-composite-control.gigya-composite-control-label.reset-password-description {
    margin-bottom: 0 !important;
    font-size: 18px;
  }
  .profile-email-label {
    font-weight: 700;
  }
  .gigya-composite-control.gigya-composite-control-label.register-success-button {
    font-size: 18px;
    b {
      font-size: 18px !important;
    }
  }
  // login&register - submit btn
  .gigya-composite-control.gigya-composite-control-submit.button-register {
    display: flex !important;
  }
  .gigya-composite-control.gigya-composite-control-textbox.form-field.form-field-input,
  .gigya-composite-control.gigya-composite-control-dropdown.form-field.form-field-select,
  .gigya-composite-control.gigya-composite-control-password.form-field.gigya-position-relative.form-field-input {
    margin-top: 0;
    margin-bottom: 24px;
  }
  .gigya-composite-control.gigya-composite-control-label.max-width.dropdown-label {
    margin: 0 !important;
    display: none;
  }
  .gigya-composite-control.gigya-composite-control-label.form-title {
    //margin-top: 24px;
    //margin-bottom: 0 !important;
    margin-bottom: 12px !important;
    font-family: $font-heading-l-font-family !important;
  }
  .gigya-composite-control.gigya-composite-control-checkbox.form-field.form-field-checkbox {
    margin-bottom: 0 !important;
  }
  .gigya-composite-control.gigya-composite-control-label.form-title.form-personal-data {
    margin-bottom: 24px !important;
  }
  // forgot password - submit btn
  .gigya-composite-control.new-password-button .gigya-input-submit {
    line-height: 6px;
    padding: 20px 36px !important;
    &:hover {
      line-height: 6px;
    }
  }
  // forgot password - label
  .gigya-composite-control.gigya-composite-control-label.forgot-password-title {
    margin-bottom: 0 !important;
    line-height: 27px;
    font-size: 18px;
  }
  .gigya-composite-control.gigya-composite-control-label.forgot-password-reminder {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 27px;
  }
  // registration email title
  .gigya-composite-control.gigya-composite-control-header.registration-title {
    color: var(--text-text-primary, $black);
    text-align: center;
    font-size: 34px !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 52px;
    font-family: $font-heading-l-font-family !important;
  }
  // used for informative description
  .gigya-label-text.gigya-composite-control.gigya-composite-control-label.register-success-description,
  .gigya-composite-control.gigya-composite-control-label.register-success-email {
    text-transform: none !important;
    font-size: 18px;
    line-height: 24px;
    color: $black;
    display: block;
    b {
      font-size: 18px !important;
      word-wrap: break-word;
    }
  }
  .gigya-label-text.gigya-composite-control.gigya-composite-control-label.register-success-description {
    margin-bottom: 0 !important;
  }
  // login&register - fields
  .gigya-composite-control, .login-field, .login-link, .gigya-composite-control-submit {
    margin-bottom: 24px !important;
    padding: 0 !important;
    margin-top: 0 !important; //added for forgot password
  }
  .gigya-composite-control.gigya-composite-control-header.login-title.new-user-label {
    font-family: $font-heading-l-font-family !important;
    font-size: 18px;
  }
  .gigya-composite-control.gigya-composite-control-link.login-link {
    display: flex;
    flex-direction: row-reverse;
    font-size: 13px !important;
    margin-left: auto;
    margin-right: 0;
    min-width: 150px;
    width: fit-content;
  }
  .gigya-login-label-text {
    font-size: 16px !important;
    font-weight: initial !important;
    line-height: 24px;
    margin: 0 0 24px !important;
    b {
      font-size: 16px !important;
      line-height: 24px;
    }
  }
  // removed default :focus style
  .gigya-composite-control-link:focus {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }
  // login&register - show-hide password
  .gigya-show-password-button {
    align-items: center !important;
    background: transparent;
    border-radius: 0 4.5px 4.5px 0;
    bottom: 0;
    cursor: pointer;
    display: flex !important;
    height: auto !important;
    justify-content: center !important;
    padding: 0 12px !important;
    position: absolute !important;
    right: 0;
    top: 0;
    width: 24px !important;
    &:focus {
      border: none !important;
      box-shadow: none !important;
    }
  }
  // login&register - sections separator
  .login-divider {
    margin-bottom: 0 !important;
    padding: 0 !important;
    span {
      color: $color-gray-darker !important;
      font-size: 13px !important;
      font-weight: 400 !important;
      min-width: 160px !important;
    }
    .login-divider:before, .login-divider:after {
      color: $color-gray-lighter;
      margin: 0;
      max-width: none;
      width: 100%;
    }
  }
  // register - country mapping
  .gigya-country-field.hidden {
    content-visibility: hidden;
    margin-bottom: 0 !important;
  }
  // register&complete-registration - birth-day field
  .gigya-composite-control.gigya-composite-control-textbox.gigya-birth-day {
    label {
      display: flex !important;
      height: 18px;
      line-height: 23px !important;
    }
    span {
      display: flex;
      line-height: 23px !important;
      margin: 0;
      min-width: max-content;
      text-transform: none !important;
    }
  }
  // reset password form
  .gigya-composite-control.gigya-composite-control-password.password {
    display: flex;
    height: auto;
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
  .gigya-reset-password-form .gigya-composite-control-password {
    max-width: 345px;
    min-width: 250px;
    margin-left: auto;
    margin-right: auto;
  }
  .gigya-reset-password-form .new-password-button {
    display: flex !important;
  }
  // reset password - btn
  .gigya-reset-password-form .gigya-input-submit {
    margin-top: 16px;
    max-width: 345px;
    min-width: 250px;
  }
  // login - input
  .login-field input {
    border-color: $color-gray-darker !important;
    &:focus-within {
      box-shadow: 0 2px $color-primary !important;
    }
    &.gigya-error {
      @include input-error;
    }
  }
  // login - redirect to register page
  .register-redirect-link {
    width: 100%;
  }
  .register-redirect-button {
    background-color: inherit !important;
    border: 2px solid $color-primary !important;
    color: $color-primary !important;
    @include font-button-m;
    height: 51px;
    min-width: 66px;
    text-transform: uppercase;
    transition: .3s !important;
    width: 100% !important;
    &:hover {
      background-color: $color-primary !important;
      color: $white !important;
    }
  }
  // login&register - error message
  .gigya-error-display-active {
    margin: 0 !important;
  }
  // reset password - error form window
  .gigya-reset-password-form .gigya-error-display-active {
    display: flex;
    justify-content: center;
  }
  // login - invalid username or password
  .gigya-error-code-403042 {
    max-height: 80px;
    min-height: 60px;
  }
  // generic style applied to all errors
  .gigya-error-msg {
    // TODO: add variables brand scoped
    font-size: 14px !important;
    font-weight: 400 !important;
    margin: 0 !important;
    line-height: 20px !important;
    padding: 0 !important;
    &-active {
      color: $form-item-error-text-color !important;
      //font-weight: $input-error-font-weight !important;   to be evaluated
      position: absolute;
    }
    .error-message-description {
      color: $form-item-error-text-color !important;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      max-width: 95%;
    }
  }
  // specific for messages coming from form error element
  div.gigya-error-msg-active {
    margin-bottom: 24px !important;
    position: relative;
  }
  // register - checkbox
  .form-field-checkbox .gigya-input-checkbox:checked+.gigya-label span::after {
    opacity: 1;
  }
  .form-field-checkbox span::after {
    position: absolute;
    margin: -2px 0 0 -3px;
    text-align: center;
    opacity: 0;
    background-color: $color-primary;
    content: "";
  }
  //checkbox registration
  .gigya-composite-control.gigya-composite-control-checkbox.form-field.registration-checkbox {
    margin: 0;
  }
  .gigya-label-text.gigya-checkbox-text::before {
    width: 18.5px;
    height: 18.5px;
  }
  .form-field-checkbox .gigya-label-text .gigya-checkbox-text::before,
  .form-field-checkbox .gigya-label-text .gigya-checkbox-text::after {
    position: absolute;
    left: 0;
    cursor: pointer;
    transition: opacity .3s ease;
    width: 14px;
    height: 14px;
  }
  .gigya-input-checkbox.fd-checkbox {
    border: 2px solid $color-primary !important;
  }
  .gigya-multi-choice-item {
    display: flex !important;
    align-items: center !important;
  }
  .gigya-multi-choice-item label {
    font-size: 14px !important;
  }
  .form-field label {
    margin-bottom: 0 !important;
  }
  // login&register - social icons
  .gigya-login-provider button div {
    border-radius: 5px;
  }
  // login - labels
  // N.B.: not all labels directly accept capitalize
  .gigya-label-text {
    font-size: 18px !important;
    text-transform: lowercase !important;
  }
  .gigya-label-text::first-letter {
    text-transform: capitalize;
  }
  i.gigya-password-icon {
    color: black !important;
  }
  // register - custom rule - hide * in checkbox
  .gigya-required-display.gigya-reset.gigya-hidden {
    display: none !important;
  }
  // form title
  .form-title {
    // TODO: add variables brand scoped
    font-size: 18px !important;
    line-height: 24px !important;
    font-weight: 700 !important;
    width: 100% !important;
    text-align: left !important;
  }
  .description {
    // TODO: add variables brand scoped
    font-size: 18px !important;
    font-weight: 300 !important;
    line-height: 27px !important;
    b {
      font-size: 18px !important;
      font-weight: 700 !important;
      line-height: 27px !important;
    }
  }
  .description-small {
    // TODO: add variables brand scoped
    font-size: 16px !important;
    font-weight: 300 !important;
    line-height: 24px !important;
  }
  // registration title
  .registration-title {
    // TODO: add variables brand scoped
    font-size: 34px !important;
    font-weight: 700 !important;
    line-height: 52px !important;
  }
  // label
  .form-field label {
    all: unset !important;
    font-size: $form-item-label-font-size !important;
    font-weight: $form-item-label-font-weight !important;
    font-style: $form-item-label-font-style !important;
    line-height: $form-item-label-line-height !important;
    margin-bottom: 2px !important;
  }
  // form input
  .form-field-input {
    margin: 0;
    width: 100%;
    input {
      border-radius: $input-border-radius;
      width: 100%;
      border: 1px solid #575A5A !important;
      height: 44px !important;
      padding: 8px !important;
      font-size: $input-text-font-size !important;
      font-weight: $input-text-font-weight !important;
      line-height: $input-text-line-height !important;
      font-style: $input-text-font-style !important;
      caret-color: $color-primary;
      background-color: $color-background;
      &:focus-within {
        box-shadow: 0 2px $color-primary !important;
      }
      &.gigya-error {
        @include input-error;
      }
    }
  }
  // select
  .form-field select {
    background-color: inherit;
    border-radius: $select-border-radius !important;
    border: 1px solid #575A5A !important;
    caret-color: green;
    cursor: pointer;
    font-size: $select-text-font-size !important;
    font-style: $select-text-font-style !important;
    font-weight: $select-text-font-weight !important;
    line-height: $select-text-line-height !important;
    height: 44px !important;
    padding: 8px !important;
    &:focus {
      box-shadow: none !important;
    }
    &:focus-within {
      box-shadow: 0 2px $color-primary !important;
    }
    &.gigya-error {
      @include input-error;
    }
  }
  // checkbox
  .form-field-checkbox {
    width: 100% !important;
    display: flex !important;
    gap: 8px !important;
    input {
      width: 24px !important;
      height: 24px !important;
      border: solid 2px $color-primary !important;
      &:focus-within {
        box-shadow: unset !important;
        border: solid 2px $color-primary !important;
      }
      &[class='gigya-empty'] {
        background-color: $color-primary !important;
      }
    }
  }
  .left {
    width: calc(50% - 12px) !important;
    display: inline-block !important;
    margin-right: 24px !important;
  }
  .right {
    width: calc(50% - 12px) !important;
    display: inline-block !important;
  }
  .separator {
    background-color: #b8b8b8;
    width: 100%;
    height: 1px;
    padding: 0;
  }
  // form - color-primary CTA
  .gigya-input-submit {
    background-color: $color-primary !important;
    border: 2px solid $color-primary;
    border-radius: $button-m-radius;
    @include font-button-m;
    height: 51px;
    text-transform: $button-m-text-transform;
  }
  .button input[type=submit]:hover,
  .gigya-input-submit:hover {
    background-color: inherit !important;
    border: 2px solid $color-primary !important;
    color: $color-primary !important;
  }
  // textbox input
  .gigya-input-text,
  .gigya-input-password {
    background-color: inherit;
    border-color: #575A5A !important;
    border-radius: $input-border-radius;
    caret-color: $color-primary;
    font-style: $input-text-font-style;
    font-weight: $input-text-font-weight !important;
    height: 44px;
    line-height: $input-text-line-height;
    &:focus-within {
      box-shadow: 0 2px $color-primary !important;
    }
    &.gigya-error {
      @include input-error;
    }
  }
}
div.gigya-container.gigya-container-enabled {
  margin-left: 0 !important;
}
// TABLET
@media screen and (min-width: 641px) and (max-width: 1023px) {
  // only for 2 columns template!
  .section-community .gigya-community {
    #gigya-login-one-column {
      padding-right: 0;
    }
  }
  //login social post registration
  .gigya-screen.v2.gigya-link-account-screen.portrait {
    .gigya-composite-control.gigya-composite-control-submit.gigya-link-accounts-submit-button {
      margin: 0;
    }
  }
  //birthdate - register & update profile
  .gigya-register-form, .gigya-profile-form {
    .gigya-layout-row {
      .gigya-composite-control.gigya-composite-control-dropdown.form-field.form-field-input.gigya-birth-month {
        label {
          display: none !important;
        }
        select {
          margin: 0;
        }
      }
      .gigya-composite-control.gigya-composite-control-textbox.form-field.form-field-input.gigya-birth-year {
        label {
          display: none !important;
        }
      }
    }
  }
  .gigya-update-profile-form .gigya-profile-form {
    .gigya-layout-row {
      .gigya-composite-control.gigya-composite-control-textbox.form-field.form-field-input.gigya-birth-day {
        max-width: 200px !important;
        margin-right: 20px !important;
      }
      .gigya-composite-control.gigya-composite-control-dropdown.form-field.form-field-input.gigya-birth-month {
        margin-right: 20px !important;
        max-width: 280px !important;
        margin-top: 27px !important;
        label {
          display: none !important;
        }
        select {
          margin: 0;
        }
      }
      .gigya-composite-control.gigya-composite-control-textbox.form-field.form-field-input.gigya-birth-year {
        max-width: 200px !important;
        margin-top: 27px !important;
        label {
          display: none !important;
        }
      }
    }
  }

  .gigya-screen.v2.portrait.gigya-profile-window-info,
  .gigya-update-profile-form .gigya-profile-form {
    width: 720px !important;
    margin: 0 auto !important;
    padding: 0 !important;
    .gigya-composite-control.gigya-composite-control-dropdown.form-field.form-field-input.left {
      margin: 0 !important;
    }
  }

  .gigya-screen {
    .gigya-layout-row {
      display: flex;
      text-align: left;
      flex-direction: column;
      width: 100% !important;
    }
    .left {
      width: 100% !important;
    }
    .right {
      display: inline-block !important;
      width: calc(70% - 12px) !important;
    }
    .gigya-label-text.gigya-checkbox-text::before {
      width: 31px;
      height: 31px;
    }
    // login error - invalid email or password
    .gigya-error-msg .error-message-description {
      max-width: 100%;
    }
    .gigya-composite-control.gigya-composite-control-submit.login-button .gigya-input-submit {
      height: 51px;
    }
    .register-redirect-button {
      height: 51px;
    }
  }

  // forgot password - window
  #uuid-0db5f99f-ab0f-4efc-a0d5-9834b99f332f {
    padding: 3.4rem 1rem 5.8rem !important;
    // forgot password - input
    .gigya-composite-control.gigya-composite-control-textbox.gigya-composite-control-loginID {
      max-width: 265px;
    }
  }

  // login window
  .gigya-screen-dialog,
  .gigya-screen-dialog-mobile {
    height: 100vh;
    left: auto !important;
    right: 0;

    .gigya-screen-dialog-main {
      max-width: 100vw;
    }
  }

  // used both for dialog and in page login
  .login-field, .gigya-composite-control-submit.login-button {
    margin: 0 0 24px !important;
  }

  .gigya-screen {
    .gigya-layout-row {
      display: flex;
      text-align: left;
      flex-direction: column;
      width: 100% !important;
    }
    .left {
      width: 100% !important;
    }
    .right {
      display: inline-block !important;
      width: 100% !important;
    }
    // Invalid email or password
    .gigya-error-msg .error-message-description {
      max-width: 100%;
    }
  }

  .gigya-screen.v2.portrait.gigya-profile-window-info,
  .gigya-profile-form {
    margin: 0 auto !important;
    padding: 0 !important;
    .gigya-composite-control.gigya-composite-control-dropdown.form-field.form-field-input.left {
      margin: 0 !important;
    }
  }

  // profile info - tablet
  .gigya-profile-window-info {
    padding-left: 70px;
    padding-right: 70px;
    .gigya-profile-form {
      max-width: 100%;
      .gigya-layout-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
      }

      .gigya-composite-control.gigya-composite-control-label.gigya-profile-column-left {
        margin-right: 30px;
        width: 255px !important;
        height: fit-content;
      }
    }
  }


  // profile update - tablet
  .gigya-screen.v2.gigya-update-profile-screen.portrait.gigya-update-profile-form,
  .gigya-screen.v2.portrait.gigya-update-profile-form {
    .gigya-profile-form {
      width: 100%;
      margin: 0 auto;
      .gigya-layout-row {
        .gigya-composite-control.gigya-composite-control-textbox.form-field.form-field-input.left {
          margin-right: 0 !important;
        }
        .gigya-composite-control.gigya-composite-control-password.gigya-composite-control-password-peek.form-field.form-field-input.left.gigya-reset {
          margin-right: 0 !important;
        }
        .gigya-composite-control.gigya-composite-control-password.gigya-composite-control-password-peek.form-field.form-field-input.left.old-password-field.gigya-reset {
          margin-right: 0 !important;
        }
      }
      .gigya-composite-control.gigya-composite-control-submit.button.right {
        .gigya-input-submit {
          margin-top: 0 !important;
          height: 51px;
        }
      }
      .gigya-composite-control.gigya-composite-control-link.register-redirect-link.profile-redirect-button.left {
        margin-right: 0 !important;
      }
      // current password field
      .field1 {
        margin-right: 0 !important;
      }
      // new password field
      .field2 {
        margin-right: 0 !important;
      }
      .label-mandatory {
        margin-right: 0 !important;
        // margin-top: 0;
        width: 100% !important;
      }
    }
  }
}

// MOBILE
@media screen and (max-width: 640px) {
  //login social post registration
  .gigya-screen.v2.gigya-link-account-screen.portrait {
    .gigya-composite-control.gigya-composite-control-submit.gigya-link-accounts-submit-button {
      margin: 0;
    }
  }
  //birthdate - update profile
  .gigya-register-form, .gigya-profile-form,
  .gigya-profile-form {
    .gigya-layout-row {
      .gigya-composite-control.gigya-composite-control-textbox.form-field.form-field-input.gigya-birth-day {
        width: 100% !important;
      }
      .gigya-composite-control.gigya-composite-control-dropdown.form-field.form-field-input.gigya-birth-month {
        width: 100%;
        label {
          display: none !important;
        }
        select {
          margin: 0;
        }
      }
      .gigya-composite-control.gigya-composite-control-textbox.form-field.form-field-input.gigya-birth-year {
        width: 100% !important;
        label {
          display: none !important;
        }
      }
    }
  }
  .gigya-profile-form {
    .gigya-composite-control.gigya-composite-control-dropdown.form-field.form-field-input.left {
      margin: 0 !important;
    }
  }
  .gigya-community {
    padding: 30px 15px 15px 15px;
  }
  // only for 2 columns template!
  .section-community .gigya-community {
    padding: 0 0 20px 0;
    #gigya-login-one-column {
      padding-right: 0;
    }
  }
  .gigya-screen.v2.portrait {
    padding: 0 !important;
  }
  // added to align 2 columns template spaces
  #gigya-registration-one-column {
    padding-bottom: 2rem;
  }
  .gigya-screen {
    .gigya-layout-row {
      display: flex;
      text-align: left;
      flex-direction: column;
      width: 100% !important;
    }
    .left {
      width: 100% !important;
    }
    .right {
      display: inline-block !important;
      width: 100% !important;
    }
    .gigya-label-text.gigya-checkbox-text::before {
      width: 31px;
      height: 31px;
    }
    // login error - invalid email or password
    .gigya-error-code-403042 {
      max-height: 120px;
      max-width: 90vw;
      min-height: 100px;
    }
    // reset password error - Password does not meet complexity requirements
    span.gigya-error-code-400009 {
      display: contents; // to wrap into the page
    }
    // forgot password - label
    .gigya-composite-control.gigya-composite-control-label.forgot-password-title {
      line-height: 22px;
    }
    .gigya-composite-control.gigya-composite-control-label.forgot-password-reminder {
      line-height: 22px;
      font-size: 18px;
    }
    // forgot password - input div
    .gigya-composite-control.gigya-composite-control-textbox.gigya-composite-control-loginID {
      margin-top: 0 !important;
    }
    // forgot password - submit btn
    .gigya-composite-control.new-password-button .gigya-input-submit {
      padding: 20px 26px !important;
    }
    .gigya-reset-password-form .new-password-button {
      width: 100%;
    }
    // forgot&reset password - title
    .gigya-composite-control.gigya-composite-control-header.forgot-password-title,
    .gigya-composite-control.gigya-composite-control-header.reset-password-title,
    .gigya-composite-control.gigya-composite-control-header.login-title.reset-password-success-title {
      font-family: $font-heading-l-font-family !important;
      font-size: 34px;
      justify-content: center !important;
      line-height: 36px;
      margin-top: 2.5rem !important;
    }
  }
  // login - mobile
  .gigya-screen-dialog,
  .gigya-screen-dialog-mobile {
    height: 100vh;
    left: auto !important;
    right: 0;
    width: 100% !important;
    .gigya-screen-dialog-top {
      width: 100% !important;
    }
    .gigya-screen-dialog-main {
      max-width: 100vw;
    }
  }
  // in page caption
  .gigya-screen-caption {
    padding: 0 !important;
  }
  // login - in page
  #gigya-forgot-password-success-screen {
    .gigya-login-form {
      padding: 0 !important;
    }
  }
  // used both for dialog and in page login
  .login-field, .gigya-composite-control-submit.login-button {
    margin: 0 0 24px !important;
  }
  // pending verification window
  #gigya-verification-pending-screen.gigya-screen.v2.portrait,
  #gigya-verification-sent-screen.gigya-screen.v2.portrait {
    .register-success-email {
      margin: 0;
    }
  }
  #gigya-verification-sent-screen.gigya-screen.v2.portrait {
    padding: 1.5rem !important;
  }
  #uuid-0db5f99f-ab0f-4efc-a0d5-9834b99f332f {
    padding: 3.4rem 1rem 5.8rem !important;
  }
  // reset password success - window
  #gigya-reset-password-success-screen.gigya-screen.portrait {
    width: 100%;
  }
  .gigya-reset-password-form .gigya-composite-control-password,
  .gigya-composite-control-loginID {
    max-width: unset;
    min-width: unset;
    width: 100%;
  }
  // reset password success - content
  .reset-password-success-icon {
    .icon-tick-fill:before {
      color: $color-primary;
      display: flex;
      font-size: 3.6rem;
    }
  }
  .gigya-composite-control.gigya-composite-control-header.login-title.reset-password-success-title {
    font-size: 28px;
    justify-content: center !important;
    line-height: 36px;
  }
  // profile info - mobile
  .gigya-community {
    padding-top: 15px !important;
  }
  .gigya-profile-window-info {
    .gigya-profile-form {
      max-width: 100%;
      .gigya-layout-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
      }
      .gigya-composite-control.gigya-composite-control-label.gigya-profile-column-left {
        margin-right: 30px;
        width: 100px !important;
      }
    }
  }
  // profile update - mobile
  .gigya-screen.v2.portrait.gigya-profile-window-info {
    padding-left: 0;
    padding-right: 0;
    width: auto;
  }
  .gigya-update-profile-form {
    .gigya-profile-form {
      .gigya-layout-row {
        .gigya-composite-control.gigya-composite-control-textbox.form-field.form-field-input.left {
          margin-right: 0 !important;
        }
        .gigya-composite-control.gigya-composite-control-password.gigya-composite-control-password-peek.form-field.form-field-input.left.gigya-reset {
          margin-right: 0 !important;
        }
        .gigya-composite-control.gigya-composite-control-password.gigya-composite-control-password-peek.form-field.form-field-input.left.old-password-field.gigya-reset {
          margin-right: 0 !important;
        }
        .gigya-composite-control.gigya-composite-control-link.register-redirect-link.profile-redirect-button.left {
          margin-right: 0 !important;
        }
      }
      .gigya-composite-control.gigya-composite-control-submit.button.right {
        .gigya-input-submit {
          margin-top: 0 !important;
          height: 51px;
        }
      }
      // current password field
      .field1 {
        margin-right: 0 !important;
      }
      // new password field
      .field2 {
        margin-right: 0 !important;
      }
      .label-mandatory {
        margin-right: 0 !important;
        // margin-top: 0;
        width: 100% !important;
      }
    }
  }
  .gigya-screen .gigya-composite-control.new-password-button .gigya-input-submit {
    height: 51px;
  }
  //register btn
  .gigya-screen .gigya-composite-control.gigya-composite-control-submit.button-register .gigya-input-submit {
    height: 51px;
    margin-top: 20px;
    width: 220px;
    &:hover {
      min-width: unset;
    }
  }
  .gigya-screen .register-redirect-button {
    height: 47px;
  }
  .gigya-screen .gigya-composite-control.gigya-composite-control-submit.login-button .gigya-input-submit {
    height: 51px;
  }
}

// PIAGGIO-8774: visibility with zoom 400%
@media screen and (max-width: 320px) {
  .gigya-screen {
    // login error - invalid email or password
    .gigya-error-code-403042 {
      max-height: 17rem;
    }
  }
}

// PROFILE info
.gigya-profile-window-info {
  .gigya-profile-form {
    width: 100%;
    max-width: 840px !important;
    margin: 0 auto;
    //white space before password
    .gigya-clear {
      display: none;
    }

    // flex row profile
    .gigya-layout-row,
    .gigya-layout-ro {
      display: flex;
      justify-content: flex-start;
      // spacer - line under title
      .gigya-composite-control.gigya-spacer {
        border-top: 1px solid $color-gray-lighter;
        width: 100%;
        height: 16px !important;
        margin: 0 !important;
      }
      .gigya-composite-control.gigya-composite-control-label.profile-info-value {
        font-size: 18px;
        font-weight: 700;
        word-break: break-word;
      }
      .gigya-composite-control.gigya-composite-control-label.gigya-profile-column-left {
        font-size: 18px;
      }
    }

    // telephone alignment profile
    .gigya-layout-ro.ui-sortablew {
      height: 39px !important;
      display: flex !important;
      align-items: flex-start;
      .gigya-composite-control.gigya-composite-control-label.gigya-profile-column-left {
        font-size: 18px;
      }
    }

    // label profile
    .gigya-composite-control.gigya-composite-control-label {
      margin-right: 0;
      margin-left: 0;
      text-align: left !important;
    }

    .gigya-composite-control.gigya-composite-control-label.gigya-profile-column-left {
      font-weight: 300;
      margin-right: 30px;
      min-width: 100px;
      width: 255px;
    }

    // title profile
    .gigya-composite-control.gigya-composite-control-label.gigya-profile-window-title {
      color: $black;
      font-family: $font-heading, serif !important;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 0 !important;
      padding: 4px 0 !important;
      text-transform: uppercase;
    }

    // edit btn profile
    .gigya-composite-control.gigya-composite-control-link.gigya-profile-edit-btn {
      color: $color-primary;
      font-weight: 700;
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 27px;
      margin-bottom: 0 !important;
      margin-right: 0 !important;
      padding: 4px 0 !important;
      text-transform: uppercase;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.gigya-screen.v2.portrait.gigya-profile-window-info {
  margin: 0 auto;
  padding-left: 0;
  padding-right: 0;
  width: 100% !important;
}

// profile update
.gigya-update-profile-form {
  .gigya-profile-form {
    width: 100%;
    max-width: 840px !important;
    margin: 0 auto;

    .gigya-composite-control.gigya-composite-control-submit.button.right {
      .gigya-input-submit {
        max-width: unset;
        line-height: 43px;
        //height: 44px;
      }
    }

    .gigya-layout-row {
      width: 840px;
      display: flex !important;
      flex-direction: row !important;
      flex-wrap: wrap;
      .gigya-composite-control.gigya-composite-control-textbox.form-field.form-field-input {
        width: 345px;
      }
      .gigya-composite-control.gigya-composite-control-textbox.form-field.form-field-input.left {
        margin-right: 24px;
        width: 345px;
      }
      .gigya-composite-control-password-peek.gigya-composite-control.gigya-composite-control-password {
        width: 345px;
      }
      .gigya-composite-control.gigya-composite-control-password.gigya-composite-control-password-peek.form-field.form-field-input.left.gigya-reset {
        margin-right: 24px;
      }
      .gigya-composite-control.gigya-composite-control-password.gigya-composite-control-password-peek.form-field.form-field-input.left.old-password-field.gigya-reset {
        margin-right: 27px;
        width: 345px;
      }
      .gigya-composite-control.gigya-composite-control-header.name-update-title {
        font-family: $font-heading !important;
        width: 85%;
      }
      .gigya-composite-control.gigya-composite-control-label.description-small {
        margin-left: 0 !important;
      }
    }

    .gigya-layout-ro.ui-sortablew {
      .gigya-composite-control.gigya-composite-control-label.gigya-profile-update-warning {
        padding-top: 24px !important;
        border-top: 1px solid $color-gray-lighter;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
      }
    }
    .gigya-label-text {
      font-size: 18px !important;
      font-weight: 300;
      line-height: 27px;
    }
    // required for CTAs alignment
    .button input[type=submit] {
      margin-top: 0 !important;
    }
    .form-last-field {
      margin-bottom: 40px !important;
    }
    // password update
    .change-password-title {
      border-bottom: 0.5px solid $color-gray-lighter;
      font-family: $font-heading !important;
      font-weight: 700;
      line-height: 24px !important;
      padding: 6px 0 !important;
      text-transform: uppercase;
    }
    // current password field
    .field1 {
      margin-bottom: 40px !important;
      margin-right: 100px;
    }
    // new password field
    .field2 {
      margin-right: 24px;
    }
    .label-mandatory {
      font-size: 13px !important;
      line-height: 15px;
      margin-right: 100px;
      margin-left: 0;
      // margin-top: -16px !important;
      width: calc(50% - 12px) !important;
    }
  }
}

div#gigya-update-profile-screen.gigya-screen.v2.gigya-update-profile-screen.portrait,
div#gigya-change-password-screen.gigya-screen.v2.portrait {
  max-width: unset;
}

.gigya-screen .gigya-composite-control.gigya-composite-control-checkbox .gigya-input-checkbox {
  width: 18.5px;
  height: 18.5px;
}

//// Checkbox style imported from Gigya
/* checkbox text lowercase */
.gigya-screen .registration-checkbox .gigya-checkbox-text {
  text-transform: none;
  cursor: pointer;
}

/* checkbox  */
/* make original checkbox 0 opacity  */
.gigya-screen .registration-checkbox .gigya-input-checkbox {
  opacity: 0;
  position: absolute;
}

/* give checkbox space at the left of the text  */
.gigya-screen .registration-checkbox .gigya-label span {
  display: inline-flex;
  border-color: $color-primary;
  font-size: 13px !important;
  position: relative;
  text-transform: none !important;
}

@media screen and (min-width: 769px) {
  .gigya-screen .registration-checkbox .gigya-label span {
    min-height: 18px;
    padding: 0 0 0 28px;
  }
}
@media screen and (max-width: 768px) {
  .gigya-screen .registration-checkbox .gigya-label span {
    min-height: 32px;
    padding-left: 42px;
  }
}
/* checkbox before */
@media screen and (min-width: 769px) {
  .gigya-screen .registration-checkbox .gigya-label span::before {
    width: 18px;
    height: 18px;
  }
}
@media screen and (max-width: 768px) {
  .gigya-screen .registration-checkbox .gigya-label span::before {
    width: 30px;
    height: 30px;
    top: 0;
  }
}

.gigya-screen .registration-checkbox .gigya-label span::before {
  content: "";
  border: 1px solid $color-primary;
  position: absolute;
  left: 0;
  cursor: pointer;
  top: 5px;
}

.gigya-screen .registration-checkbox.grey-border .gigya-label span::before {
  border-color: #CDCDCD;
}

/* checkbox after */
@media screen and (min-width: 769px) {
  .gigya-screen .registration-checkbox .gigya-input-checkbox:focus + .gigya-label span::after,
  .gigya-screen .registration-checkbox .gigya-input-checkbox:hover + .gigya-label span::after {
    opacity: .2;
  }
}

.gigya-screen .registration-checkbox .gigya-input-checkbox:checked + .gigya-label span::after {
  opacity: 1;
}

.gigya-screen .registration-checkbox span::after {
  position: absolute;
  margin: -4px 0 0 -5px;
  text-align: center;
  opacity: 0;
  background-color: $black;
  content: "";
}

//login social post registration
.gigya-screen.v2.gigya-link-account-screen.portrait {
  padding: 15px !important;
  padding-top: 24px !important;
  //title
  .gigya-composite-control.gigya-composite-control-label.gigya-site-social-label-text {
    text-align: left;
    display: block;
    font-size: 18px;
    font-weight: 700;
    font-family: Roboto Condensed, Arial, Helvetica, sans-serif;
    margin-bottom: 0 !important;
    line-height: 21px;
  }
  //label
  .gigya-composite-control.gigya-composite-control-label.gigya-login-label-text {
    font-family: Roboto Condensed, Arial, Helvetica, sans-serif;
    letter-spacing: 0;
  }
}

@media screen and (min-width: 769px) {
  .gigya-screen .registration-checkbox span::after {
    top: 14px;
    left: 9.5px;
    width: 10px;
    height: 10px;
  }
}
@media screen and (max-width: 768px) {
  .gigya-screen .registration-checkbox span::after {
    left: 10.5px;
    width: 20px;
    height: 20px;
    top: 15px;
  }
}

.gigya-screen .registration-checkbox .gigya-label-text .gigya-checkbox-text::before,
.gigya-screen .registration-checkbox .gigya-label-text .gigya-checkbox-text::after {
  position: absolute;
  left: 0;
  cursor: pointer;
  transition: opacity .3s ease;
}

@media screen and (width: 1024px) {
  .gigya-screen.v2.portrait.gigya-profile-window-info,
  .gigya-update-profile-form .gigya-profile-form {
    width: 840px !important;
    margin: 0 auto !important;
    padding: 0 !important;
  }
  .gigya-screen.portrait#gigya-registration-one-column {
    max-width: 600px !important;
  }
}

@media screen and (min-width: 1023px) {
  .gigya-screen {
    //registration - birth date
    .gigya-composite-control.gigya-composite-control-textbox.form-field.form-field-input.gigya-birth-day {
      width: calc((50% - 50px) / 3);
      margin-right: 10px;
      display: inline-block;
    }
    .gigya-composite-control.gigya-composite-control-dropdown.form-field.form-field-input.gigya-birth-month {
      width: calc((50% - 12px) / 3);
      margin-right: 10px;
      display: inline-block;
      label {
        display: none !important;
      }
      select {
        margin: 0;
      }
      .gigya-error-code-400027 {
        display: none; // if the field is left empty
      }
    }
    .gigya-composite-control.gigya-composite-control-textbox.form-field.form-field-input.gigya-birth-year {
      width: calc((50% - 34px) / 3);
      margin-right: 24px;
      display: inline-block;
      label {
        display: none !important;
      }
      .gigya-error-code-400027 {
        display: none; // if the field is left empty
      }
    }
  }
  // profile page - birth field
  .gigya-profile-form {
    .gigya-layout-row {
      .gigya-composite-control.gigya-composite-control-textbox.form-field.form-field-input.gigya-birth-day {
        width: calc((50% - 60px) / 3);
      }
      .gigya-composite-control.gigya-composite-control-dropdown.form-field.form-field-input.gigya-birth-month {
        margin-top: 27px !important;
        width: calc((50% - 12px) / 3);
        .gigya-error-code-400027 {
          display: none; // if the field is left empty
        }
      }
      .gigya-composite-control.gigya-composite-control-textbox.form-field.form-field-input.gigya-birth-year {
        margin-top: 27px !important;
        width: calc((50% - 24px) / 3);
        .gigya-error-code-400027 {
          display: none; // if the field is left empty
        }
      }
    }
  }
  // complete registration - birth field
  #gigya-complete-registration-screen .gigya-profile-form {
    .gigya-layout-row {
      .gigya-composite-control.gigya-composite-control-textbox.form-field.form-field-input.gigya-birth-day {
        width: calc((50% - 50px) / 3);
      }
      .gigya-composite-control.gigya-composite-control-dropdown.form-field.form-field-input.gigya-birth-month {
        margin-top: 27px !important;
        width: calc((50% - 12px) / 3);
        .gigya-error-code-400027 {
          display: none; // if the field is left empty
        }
      }
      .gigya-composite-control.gigya-composite-control-textbox.form-field.form-field-input.gigya-birth-year {
        margin-top: 27px !important;
        width: calc((50% - 34px) / 3);
        .gigya-error-code-400027 {
          display: none; // if the field is left empty
        }
      }
    }
  }
}

//over 1440px
@media screen and (min-width: 1044px) {
  .section-community {
    display: flex;
    justify-content: center;
    .gigya-community {
      padding: 4.5rem 0 0 0;
      margin-right: 125px;
      width: fit-content;
    }
    .cell.large-5.medium-auto {
      width: fit-content !important;
    }

  }
}




