////////////////////////////////////////
// Swatches
.swatches {
  margin: auto;

  & > ul {
    display: flex;
    justify-content: $swatches-align;
    //align-items: center;
  }
}

////////////////////////////////////////
// Swatche color
.swatches-color {
  display: inline-block;
  margin: rem(1);
  padding: rem(2);
  position: relative;
  width: initial;
  height: initial;
  font-size: unset;

  .swatches-color__icon,
  .swatches-color__darken,
  .swatches-color__active {
    font-size: $swatches-color-icon-size;
    line-height: $swatches-color-icon-size;
  }

  &.swatches-color--l {
    .swatches-color__icon,
    .swatches-color__darken {
      font-size: $swatches-color-icon-size-l;
      line-height: $swatches-color-icon-size-l;
    }
  }

  &.swatches-color--xl {
    .swatches-color__icon,
    .swatches-color__darken {
      font-size: $swatches-color-icon-size-xl;
      padding: 0 3px;
      line-height: $swatches-color-icon-line-height-xl;
    }
  }

  .swatches-color__icon,
  .swatches-color__more {
    span {
      @extend .show-for-sr;
    }
  }

  @if $swatches-box-shadow == true {
    .swatches-color__icon:before {
      box-shadow: 1px 1px 6px $color-gray-lighter;
    }
  }

  .swatches-color__darken {
    @include cover;
    opacity: 1;
    color: rgba(gray, 0.2);
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    @if $swatches-color-darken == false {
      opacity: 0;
    }
  }

  .swatches-color__active {
    display: none;
    @include cover;
    font-size: 2.4rem;
    line-height: 2.4rem;
    color: $swatches-active-color;
  }

  .swatches-color__more {
    font-size: 1.1rem;
    line-height: 1.8rem;
    color: $text-color-black;
  }

  &.swatches-color--xl .swatches-color__active {
    font-size: $swatches-color-icon-size-xl-active;
    line-height: $swatches-color-icon-size-xl-active;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
  }
}

.active .swatches-color__active {
  display: flex;
}
