////////////////////////////////////////
// editorial horizontal - immerisive
.section.section-editorial-immersive {
  padding: 0 !important;
  .editorial-immersive__align-left, .editorial-immersive__align-right {
    padding: rem(16) 0 !important;

    @include breakpoint(large) {
      padding: rem(24) 0 !important;
    }

    &.editorial-immersive__grid-fullImage {
      padding: 0!important;
    }
  }

  .section__container {
    padding-right: 0;
    padding-left: 0;
  }
}

.editorial-immersive__grid {
  $padding-immersive: calc((100% / var(--aspect-ratio)) / 2);

  padding-top: 0;
  display: grid;
  grid-template-rows: 1fr;
  place-items: center;

  .editorial-immersive__figure {
    padding: $padding-immersive;
    @include breakpoint(xlarge) {
      padding: 0;
    }
    .image {
      width: 100%;
    }
  }

  .editorial-immersive__text {
    padding: $padding-immersive;
  }

  .editorial-immersive__grid-item {
    width: 100%;

    .text--body {
      margin-top: $button-m-padding-v;
    }
  }

  &__title {
    padding: rem(15) 0 0 0;
    color: inherit; //from section
  }

  &__subheading {
    margin-top: rem(08);
    color: inherit; //from section
  }

  &__subheading--upper {
    padding: 0;
    margin-bottom: 0;
  }

  &__cta {
    display: flex;
    flex-direction: column;

    .button {
      margin-right: 16px !important;

      &.button--text-white, &.button--text {
        &.button--medium {
          padding-left: 0;
          padding-right: 0;
        }
        &.button--medium::after {
          left: 0;
          right: 0;
        }
      }
    }

    &.margin-top__btn-m {
      margin-top: $button-m-padding-v;
    }

    &.margin-bottom__btn-m {
      margin-bottom: 45px;
    }

    span + span {
      padding-top: 8px;
    }
  }

  .editorial-immersive__content--align-center {
    text-align: center;
  }

  a:link:not(.button), a:visited:not(.button) {
    // color: $general-link-color;
    color: inherit;
    text-decoration: underline;
  }
}

.editorial-immersive__grid-fullImage {
  .editorial-immersive__figure {
    padding: 0;

    .image {
      border-radius: 0;
    }
  }
}

////////////////////////////////////////
// breakpoint medium
@include breakpoint(medium) {
  $padding-immersive: calc((100% / var(--aspect-ratio)) / 2);

  .section.section-editorial-immersive {
    .section__container {
      .editorial-immersive__align-left {
        .editorial-immersive__text {
          @include breakpoint(xlarge) {
            padding-right: 0;
          }
        }
      }

      .editorial-immersive__align-right {
        .editorial-immersive__text {
          @include breakpoint(xlarge) {
            padding-left: 0;
          }
        }
      }
    }

    .section-immersive__container {
      .editorial-immersive__align-left {
        .editorial-immersive__text {
          padding-right: calc((100vw - 111rem) / 2);
        }
      }

      .editorial-immersive__align-right {
        .editorial-immersive__text {
          padding-left: calc((100vw - 111rem) / 2);
        }
      }
    }
  }

  .editorial-immersive__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;

    &.editorial-immersive__align-left {
      .editorial-immersive__figure {
        order: 1;
      }

      .editorial-immersive__text {
        order: 2;
      }
    }

    &.editorial-immersive__align-right {
      .editorial-immersive__figure {
        order: 2;
      }

      .editorial-immersive__text {
        order: 1;
      }
    }

    .editorial-immersive__figure {
      padding: $padding-immersive;

      @include breakpoint(xlarge) {
        padding: 0;
      }
      /*
            .image {
              border-radius: rem(20);
            }*/
    }

    .editorial-immersive__text {
      padding: $padding-immersive;
    }
  }

  .editorial-immersive__grid-fullImage {
    .editorial-immersive__figure {
      padding: 0;

      .image {
        border-radius: 0;
      }
    }
  }
}

@include breakpoint(xlarge) {
  .section-immersive__container {
    .editorial-immersive__align-left,
    .editorial-immersive__align-right {
      .editorial-immersive__text {
        padding: 0 15% !important;
      }
    }
  }
}