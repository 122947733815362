.modal--panel {
  .modal-dialog {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    height: calc(100vh - var(--nav-height));
    width: 100%;
    margin: 0;
    transform: translateX(100%);
    transition: transform 0.25s linear;

    @include breakpoint(medium) {
      width: 500px;
    }
  }

  &.show {
    .modal-dialog {
      transform: translateX(0);
    }
  }
}
