@mixin font-heading-xl() {
  font-size: $font-heading-xl-font-size-small;
  line-height: $font-heading-xl-line-height-small;
  font-style: $font-heading-xl-font-style;
  font-weight: $font-heading-xl-font-weight;
  font-family: $font-heading-xl-font-family;
  letter-spacing: $font-heading-xl-letter-spacing;

  @include breakpoint(large) {
    font-size: $font-heading-xl-font-size-large;
    line-height: $font-heading-xl-line-height-large;
    letter-spacing: $font-heading-xl-letter-spacing-large;
  }
}

@mixin font-heading-hero-content-xl() {
  font-size: $font-heading-hero-content-xl-font-size-small;
  line-height: $font-heading-hero-content-xl-line-height-small;
  font-style: $font-heading-xl-font-style;
  font-weight: $font-heading-xl-font-weight;
  font-family: $font-heading-xl-font-family;
  letter-spacing: $font-heading-hero-content-xl-letter-spacing;

  @include breakpoint(large) {
    font-size: $font-heading-hero-content-xl-font-size-large;
    line-height: $font-heading-hero-content-xl-line-height-large;
    letter-spacing: $font-heading-hero-content-xl-letter-spacing-large;
  }
}

@mixin font-heading-ml() {
  font-size: $font-heading-ml-font-size-small;
  line-height: $font-heading-ml-line-height-small;
  font-style: $font-heading-ml-font-style;
  font-weight: $font-heading-ml-font-weight;
  font-family: $font-heading-ml-font-family;

  @include breakpoint(large) {
    font-size: $font-heading-ml-font-size-large;
    line-height: $font-heading-ml-line-height-large;
  }
}

@mixin font-heading-l() {
  font-size: $font-heading-l-font-size-small;
  line-height: $font-heading-l-line-height-small;
  font-style: $font-heading-l-font-style;
  font-weight: $font-heading-l-font-weight;
  font-family: $font-heading-l-font-family;
  letter-spacing: $font-heading-l-letter-spacing;

  @include breakpoint(large) {
    font-size: $font-heading-l-font-size-large;
    line-height: $font-heading-l-line-height-large;
    letter-spacing: $font-heading-l-letter-spacing-large;
  }
}

@mixin font-heading-m() {
  font-size: $font-heading-m-font-size-small;
  line-height: $font-heading-m-line-height-small;
  font-style: $font-heading-m-font-style;
  font-weight: $font-heading-m-font-weight;
  font-family: $font-heading-m-font-family;
  letter-spacing: $font-heading-m-letter-spacing;

  @include breakpoint(large) {
    font-size: $font-heading-m-font-size-large;
    line-height: $font-heading-m-line-height-large;
    letter-spacing: $font-heading-m-letter-spacing-large;
  }
}

@mixin font-heading-s() {
  font-size: $font-heading-s-font-size;
  line-height: $font-heading-s-line-height;
  font-style: $font-heading-s-font-style;
  font-weight: $font-heading-s-font-weight;
  font-family: $font-heading-s-font-family;
  letter-spacing: $font-heading-s-letter-spacing;
}

@mixin font-heading-ms() {
  font-size: $font-heading-ms-font-size;
  line-height: $font-heading-ms-line-height;
  font-style: $font-heading-ms-font-style;
  font-weight: $font-heading-ms-font-weight;
  font-family: $font-heading-ms-font-family;
  letter-spacing: $font-heading-ms-letter-spacing;
}

@mixin font-heading-xs() {
  font-size: $font-heading-xs-font-size;
  line-height: $font-heading-xs-line-height;
  font-style: $font-heading-xs-font-style;
  font-weight: $font-heading-xs-font-weight;
  font-family: $font-heading-xs-font-family;
  letter-spacing: $font-heading-xs-letter-spacing;
}

@mixin font-heading-xxs() {
  font-size: $font-heading-xxs-font-size;
  line-height: $font-heading-xxs-line-height;
  font-style: $font-heading-xxs-font-style;
  font-weight: $font-heading-xxs-font-weight;
  font-family: $font-heading-xxs-font-family;
  letter-spacing: $font-heading-xxs-letter-spacing;
}

@mixin font-body() {
  font-size: $font-body-font-size;
  line-height: $font-body-line-height;
  letter-spacing: $font-body-letter-spacing;
  //font-style: $font-body-font-style;
  font-weight: $font-body-font-weight;
  font-family: $font-body-font-family;
  letter-spacing: $font-body-letter-spacing;
}

@mixin font-body-s() {
  font-size: $font-body-s-font-size;
  line-height: $font-body-s-line-height;
  //font-style: $font-body-s-font-style;
  font-weight: $font-body-s-font-weight;
  font-family: $font-body-s-font-family;
  letter-spacing: $font-body-s-letter-spacing;
}

@mixin font-body-ms() {
  font-size: $font-body-ms-font-size;
  line-height: $font-body-ms-line-height;
  //font-style: $font-body-s-font-style;
  font-weight: $font-body-ms-font-weight;
  font-family: $font-body-ms-font-family;
  letter-spacing: $font-body-ms-letter-spacing;
}

@mixin font-body-xs() {
  font-size: $font-body-xs-font-size;
  line-height: $font-body-xs-line-height;
  //font-style: $font-body-xs-font-style;
  font-weight: $font-body-xs-font-weight;
  font-family: $font-body-xs-font-family;
  letter-spacing: $font-body-xs-letter-spacing;
}

@mixin font-quote() {
  font-size: $font-quote-font-size;
  line-height: $font-quote-line-height;
  font-style: $font-quote-font-style;
  font-weight: $font-quote-font-weight;
  font-family: $font-quote-font-family;
  letter-spacing: $font-quote-letter-spacing;
}

@mixin font-button-l() {
  font-size: $font-button-l-font-size;
  line-height: $font-button-l-line-height;
  font-style: $font-button-l-font-style;
  font-weight: $font-button-l-font-weight;
  font-family: $font-button-l-font-family;
  letter-spacing: $font-button-l-letter-spacing;
}

@mixin font-button-m() {
  font-size: $font-button-m-font-size;
  line-height: $font-button-m-line-height;
  font-style: $font-button-m-font-style;
  font-weight: $font-button-m-font-weight;
  font-family: $font-button-m-font-family;
  letter-spacing: $font-button-m-letter-spacing;
}

@mixin font-button-text-s() {
  font-size: $font-button-text-s-font-size;
  line-height: $font-button-text-s-line-height;
  font-style: $font-button-s-font-style;
  font-weight: $font-button-text-s-font-weight;
  font-family: $font-button-text-s-font-family;
  letter-spacing: $font-button-text-s-letter-spacing;
}

@mixin font-button-text-l() {
  font-size: $font-button-text-l-font-size;
  line-height: $font-button-text-l-line-height;
  font-style: $font-button-l-font-style;
  font-weight: $font-button-text-l-font-weight;
  font-family: $font-button-text-l-font-family;
  letter-spacing: $font-button-text-l-letter-spacing;
}

@mixin font-button-text-m() {
  font-size: $font-button-text-m-font-size;
  line-height: $font-button-text-m-line-height;
  font-style: $font-button-m-font-style;
  font-weight: $font-button-text-m-font-weight;
  font-family: $font-button-text-m-font-family;
  letter-spacing: $font-button-text-m-letter-spacing;
}
