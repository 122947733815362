.change-language-country {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  & > div {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  div:not(:last-child):after {
    content: "/";
  }

  .flag-icon-background {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
  }

  .flag-icon {
    @extend .flag-icon-background;
    position: relative;
    display: inline-block;
    width: rem(24);
    height: rem(17);

    &:before {
      content: "\00a0";
    }

    &.flag-icon-squared {
      width: 1em;
    }
  }

  $flag-map: (
      ad,
      ae,
      af,
      ag,
      ai,
      al,
      am,
      ao,
      aq,
      ar,
      as,
      at,
      au,
      aw,
      ax,
      az,
      ba,
      bb,
      bd,
      be,
      bf,
      bg,
      bh,
      bi,
      bj,
      bl,
      bm,
      bn,
      bo,
      bq,
      br,
      bs,
      bt,
      bv,
      bw,
      by,
      bz,
      ca,
      cc,
      cd,
      cf,
      cg,
      ch,
      ci,
      ck,
      cl,
      cm,
      cn,
      co,
      cr,
      cu,
      cv,
      cw,
      cx,
      cy,
      cz,
      de,
      dj,
      dk,
      dm,
      do,
      dz,
      ec,
      ee,
      eg,
      eh,
      er,
      es,
      et,
      fi,
      fj,
      fk,
      fm,
      fo,
      fr,
      sf,
      ga,
      gb,
      gd,
      ge,
      gf,
      gg,
      gh,
      gi,
      gl,
      gm,
      gn,
      gp,
      gq,
      gr,
      gs,
      gt,
      gu,
      gw,
      gy,
      hk,
      hm,
      hn,
      hr,
      ht,
      hu,
      id,
      ie,
      il,
      im,
      in,
      io,
      iq,
      ir,
      is,
      it,
      je,
      jm,
      jo,
      jp,
      ke,
      kg,
      kh,
      ki,
      km,
      kn,
      kp,
      kr,
      kw,
      ky,
      kz,
      la,
      lb,
      lc,
      li,
      lk,
      lr,
      ls,
      lt,
      lu,
      lv,
      ly,
      ma,
      mc,
      md,
      me,
      mf,
      mg,
      mh,
      mk,
      ml,
      mm,
      mn,
      mo,
      mp,
      mq,
      mr,
      ms,
      mt,
      mu,
      mv,
      mw,
      mx,
      my,
      mz,
      na,
      nc,
      ne,
      nf,
      ng,
      ni,
      nl,
      no,
      np,
      nr,
      nu,
      nz,
      om,
      pa,
      pe,
      pf,
      ta,
      pg,
      ph,
      pk,
      pl,
      pm,
      pn,
      pr,
      ps,
      pt,
      pw,
      py,
      qa,
      re,
      ro,
      rs,
      ru,
      ri,
      rw,
      sa,
      sb,
      sc,
      sd,
      se,
      sg,
      sh,
      si,
      sj,
      sk,
      sl,
      sm,
      sn,
      so,
      sr,
      ss,
      st,
      sv,
      sx,
      sy,
      sz,
      tc,
      td,
      tf,
      tg,
      th,
      tj,
      tk,
      tl,
      tm,
      tn,
      to,
      tr,
      tt,
      tv,
      tw,
      tz,
      ua,
      ug,
      um,
      us,
      uy,
      uz,
      va,
      vc,
      ve,
      vg,
      vi,
      vn,
      vu,
      wf,
      ws,
      ye,
      yt,
      za,
      zm,
      zw,
      es-ct,
      eu,
      gb-eng,
      gb-nir,
      gb-sct,
      gb-wls,
      un,
      en
  );

  @each $name in $flag-map {
    @include flag-icon($name);
  }

  .change-language-country_country {
    display: inline-block;
    padding: rem(15);
  }

  .change-language-country_language {
    @include font-body-xs();
    color: inherit;
    display: inline-block;
    padding: rem(15);
    text-decoration: none;
  }

  &.change-language-country--list {
    display: block;

    .button {
      border-radius: 0;
    }

  }

  .contry-list {
    background: $color-gray-lightest;
    padding: rem(10) rem(20);
    margin-bottom: rem(2);
    display: flex;
    flex-wrap: wrap;
  }

  .contry-list__continent-name {
    @include font-heading-s();
    flex-basis: 100%;
    padding: 1rem 2rem;
  }

  .contry-list__country-name {
    @include font-body-s();
    flex-basis: 50%;

    @include breakpoint(large) {
      flex-basis: 20%;
    }

    .button {
      color: inherit;
      text-transform: capitalize;
      letter-spacing: initial;
      padding: 0;
      min-height: 5px;
      text-align: left;
    }
  }
  .contry-list__continent-name,
  .contry-list__country-name {
    display: flex;
    align-items: center;
    padding: rem(10) 0;

    .flag-icon {
      margin-right: rem(10);
    }

    .button:after {
      background: transparent;
    }
  }
}

.contry-list__lang {
  padding: 0 rem(5);
  line-height: .9;

  &:not(:last-of-type) {
    border-right: 1px solid $color-gray-darker;
  }
}

.country-distributor a {
  color: inherit;
  text-decoration: none;
}

.country-distributor a,
.country-distributor p {
  display: flex;
  align-items: center;

  &:first-of-type {
    align-items: flex-start;
  }
}

.country-distributor [class^='icon-'] {
  color: $color-primary;
  font-size: rem(36);
  margin-right: rem(10);
  line-height: normal;
}

.navigation {
  .change-language-country_country.button.button--text.button--medium {
    margin: 0;
    font-size: 1rem;
  }
}

.footer {
  .change-language-country_country.button.button--text.button--medium {
    margin: 0;
    font-size: 1rem;
    color: $footer-primary-text-color;

    &:after {
      background-color: $footer-primary-text-color;
    }
  }
}