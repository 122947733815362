fieldset {
  border: none;
  display: block;
  position: static;
  width: 100%;
}

.form__current-value {
  @include font-body-s;
  text-align: right;
}

.form__field-description {
  @include font-body-s;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

input[type="range"] + .form__field-description{
  margin-top: rem(5);
}
