.filters-bar {
  margin-bottom: 2rem;

  &--warranty-extension {
    margin-top: rem(20);
    .d-block {
      font-weight: 400;
    }

    @include breakpoint(large) {
      .filters-bar__filters {
        justify-content: center;
        text-align: left;
        margin-top: rem(30);
      }
    }
  }


  .filters-bar__filters {
    @include xy-cell(12, $gutter-type: padding);


    @include breakpoint(large) {
      @include xy-grid;
      padding: 0;
    }

    & > .filters-bar__select {
      margin-bottom: 1.5rem;

      @include breakpoint(large) {
        @include xy-cell(2, $gutter-type: margin);
      }
    }
  }

  .filters-bar__sorting {
    display: flex;
    align-items: center;
    justify-content: center;

    @include breakpoint(large) {
      align-self: flex-end;
      @include xy-cell(auto, $gutters: 0);
      margin-right: -1rem;
      display: inline-flex;
      align-items: center;
      justify-content: flex-end;
      align-self: baseline;
    }

    & > .filters-bar__select {
      @include breakpoint(large) {
        @include xy-cell(1 of 2, $gutter-type: margin);
      }
    }
  }

  .warranty-prices {
    animation: content-opacity 1.6s linear;
  }

  .warranty-price {
    color: $color-primary;
    display: block;
    margin-top: rem(10);
    margin-bottom: rem(10);
  }

  .filter-no-results {
    text-align: center;
  }

  //Filters-bar for card-promo in listing
  &.promo-filters {
    .form-select {
      width: 100%;
      @include breakpoint(medium) {
        width: calc(25% - 30px);
      }

      &::after {
        width: rem(10);
        height: rem(10);
        top: rem(40);
        right: rem(12);
        border-top: rem(1) solid $black;
        border-right: rem(1) solid $black;
      }
      .custom-select {
        @include font-body;
        padding: $form-select-promo-padding-vertical $form-select-promo-padding-horizontal;
        border: $form-select-promo-border-width solid $form-select-promo-border-color;
      }
    }

    .form-select-label {
      @include font-body;
    }

    .filters-bar__sorting {
      width: 100%;
      @include breakpoint(medium) {
        width: calc((100% / 4) - 30px);
        flex: unset;
        justify-content: unset;
        .filters-bar__select {
          width: 100%;
          margin-left: rem(15);
          margin-right: rem(15);
        }
      }
    }
  }
}


@keyframes content-opacity {
  0% {
    opacity:0
  }
  50%{
    opacity: .5;
  }
  70%{
    opacity: .8;
  }
  100% {
    opacity:1
  }
}