#root > .card-article {
  margin: rem(20) auto;
  max-width: rem(220);
}

a.card {
  display: block;
  height: 100%;
}

.card-article {
  display: flex;
  position: relative;
  background: $card-article-bg;
  border-bottom: $card-article-border-bottom;
  border-bottom-left-radius: $border-radius-bottom;
  border-bottom-right-radius: $border-radius-bottom;
  // min-height: rem(328);
}

.card-article__category-icon {
  position: absolute;
  z-index: 2;
  right: rem(15);
  top: rem(15);
  font-size: rem(20);
  color: $white;

  i {
    text-shadow: 0 0 rem(30) $black;
  }
}

.card-article a::after {
  bottom: $card-article-content-after-bottom;
  top: $card-article-content-after-top;
  content: $card-article-content-after-content;
  background: $card-article-content-after-bg;
  position: absolute;
  height: $card-article-content-after-height;
  width: $card-article-content-after-width;
  left: $card-article-content-after-left;
  z-index: 0;
  transition: height 0.6s;
}

.card-article__figure {
  background: $card-article-figure-bg;
  border-bottom: $card-article-figure-border-bottom;
  position: relative;
  margin: 0;
  z-index: 2;

  &-wrap {
    overflow: $card-article-figure-overflow;
    border-radius: $card-article-figure-border-radius;
    border-top-left-radius: $border-radius-top;
    border-top-right-radius: $border-radius-top;
  }

  img {
    border-radius: $card-article-figure-border-radius;
    transition: transform 0.5s linear;
  }
}

.card-article__category {
  @include font-heading-xxs();
  background: $card-article-category-bg;
  background-size: 200% 100%;
  background-position: right bottom;
  bottom: $card-article-category-bottom;
  border-radius: $card-article-category-border-radius;
  border-top-right-radius: $border-radius-top;
  color: $card-article-category-color;
  padding: $card-article-category-padding;
  position: absolute;
  left: $card-article-category-left;
  text-transform: $card-article-category-text-transform;
  transform: $card-article-category-transform;
  right: $card-article-category-right;
  text-align: $card-article-category-text-align;
  z-index: 3;
  transition: all 1s ease;

  @if $card-article-featured-category-after {
    padding-left: 0;
    padding-right: 0;

    &:after {
      content: "";
      height: 2px;
      background: $color-primary;
      position: absolute;
      width: 100%;
      left: 0;
      bottom: rem(-3);
    }
  }
}

.card-article__date {
  display: none;
  color: $card-article-date-color;

  &::after {
    content: $card-article-date-after-content;
    display: block;
    height: rem(2);
    width: rem(18);
    transition: color 1s;
    background-color: $card-article-date-after-bg;
    margin-top: rem(6);
  }
}

.card-article__content {
  color: $card-article-content-color;
  padding: $card-article-content-padding;
  text-align: $card-article-content-text-align;
  position: relative;
}

.card-article__text {
  position: relative;
  z-index: 2;
  transition: color 0.6s;
}

.card-article__icon {
  display: none;
  font-size: rem(24);
  position: absolute;
  right: rem(8);
  bottom: rem(8);
  z-index: 2;
}

.card-article__title {
  @include font-heading-s();
  color: $card-article-title-color;
  margin-top: 0;
  transition: color 0.6s;
}

a.card-article__link {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  z-index: 2;
  @include breakpoint(large) {
    width: 100%;
  }
}

// on Hover
a.card-article__link,
a.card-article__link:hover {
  color: inherit;
  text-decoration: none;
}

.card-article a:hover,
.card-article a:focus {
  &::after {
    height: $card-article-hover-after-height;
  }
}

a.card-article__link:hover,
a.card-article__link:focus {
  @if $card-article-featured-category-after {
    .card-article__category {
      color: $white;
      transition: color 0.6s;
      &:after {
        background: $black;
      }
    }
  }

  .card-article__content,
  .card-article__title {
    color: $card-article-content-hover;
  }

  .card-article__category {
    background-position: left bottom;
  }

  .card-article__date {
    color: $card-article-date-hover-color;
  }

  .card-article__date::after {
    background-color: $card-article-date-hover-after-bg;
  }

  .card-article__figure img {
    transform: $card-article-hover-figure-img-transform;
  }

  .card-article__icon {
    animation: show-icon 5s ease-in-out;
    display: block;
    color: $card-article-hover-icon-color;
  }
}

@keyframes show-icon {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

////////////////////////////////////////
// Card Article Featured
#root > .card-article--featured {
  margin: rem(20) auto;
  width: rem(400);
  height: rem(300);

  &[data-layout="vertical"] {
    height: rem(300 * 2);
  }
}

.card-article--featured {
  position: relative;

  //@include aspect-ratio(16:9, ".editorial-cover__block");

  .card-article--featured__figure {
    width: 100%;
  }

  .card-article__category-icon {
    font-size: rem(30);
  }

  .card-article--featured__figure img {
    transform: scale(1.02);
    width: 100%;
    height: auto;
    display: block;
    -o-object-fit: cover;
    object-fit: cover;
  }

  &__content {
    position: absolute;
    z-index: $card-article-featured-content-z-index;
    background: transparent;
    padding: $card-article-featured-content-padding;
    bottom: $card-article-featured-bottom;
    width: $card-article-featured-content-width;
    text-align: $card-article-featured-content-text-align;
  }

  &__content-bg {
    background: $card-article-featured-content-bg;
    border-radius: $card-article-figure-border-radius;
    border-top-left-radius: $card-article-border-top-left-radius;
    border-top-right-radius: $card-article-border-top-right-radius;
    border-bottom-left-radius: $card-article-border-bottom-left-radius;
    border-bottom-right-radius: $card-article-border-bottom-right-radius;
    height: 100%;
    z-index: 2;
    position: absolute;
    width: 100%;
    mix-blend-mode: $card-article-featured-content-bg-mix-blend-mode;
    top: 0;
    left: 0;
  }

  &__figure-wrap {
    overflow: hidden;
    border-radius: $card-article-featured-figure-wrap-border-radius;
  }

  &__figure {
    border-bottom: 0;
  }

  &__category {
    background: $card-article-featured-category-bg;
    color: $card-article-featured-category-color;
    top: $card-article-featured-category-top;
    bottom: auto;
    left: $card-article-featured-category-left;
    right: auto;
    transition: none;
    transform: none;

    @if $card-article-featured-category-after {
      padding-left: 0;
      padding-right: 0;

      &:after {
        content: "";
        height: 2px;
        background: $color-primary;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
      }
    }
  }

  &__title {
    color: $card-article-featured-title-color;
    margin-top: rem(4);
  }

  &__text {
    width: $card-article-featured-text-width;
  }

  .icon-read-more {
    background-color: $card-article-featured-read-more-bg;
    color: $card-article-featured-read-more-color;
    position: absolute;
    padding: $card-article-featured-read-more-padding;
    bottom: $card-article-featured-read-more-bottom;
    right: $card-article-featured-read-more-right;
    font-size: $card-article-featured-read-more-font-size;
    z-index: 2;
  }

  // on Hover
  a.card-article--featured__link:hover,
  a.card-article--featured__link:focus {
    .card-article--featured__title {
      color: $card-article-featured-title-color;
    }

    .card-article--featured__figure img {
      transform: $card-article-featured-hover-figure-img-transform;
    }

    .card-article--featured__category:after {
      background: $color-primary;
    }
  }
}
