.card-promotion-small {
  text-decoration: none;
  &__img {
    picture {
      display: block;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  &__content {
    display: flex;
    bottom: 0;
    z-index: 1;
    width: 100%;
    background-color: $card-promotion-content-bg;
    color: $card-promotion-content-color;
    min-height: rem(100);
    height: rem(140);
    @include breakpoint(large) {
      height: rem(120);
    }

    > i {
      text-align: center;
      font-size: $font-heading-m-font-size-small;
      width: rem(24);
      height: rem(24);
      margin: auto rem(15) rem(15) rem(10);
    }

    > div {
      width: 90%;
      display: flex;
      flex-direction: column;
      padding: rem(12) 0 rem(15) rem(15);
      position: relative;
      height: fit-content;
    }
  }

  &.card-promotion-aftersales{
    .card-promotion-small__content {
      background-color: $card-promotion-content-bg-aftersale;
      color: $card-promotion-content-color-aftersale;
      border: 1px solid $card-promotion-content-border-aftersale;
    }
  }

  &__duration {
    font-size: $font-heading-xxs-font-size;
    font-weight: $font-body-font-weight;
    line-height: rem(24);
  }

  &__title {
    font-size: rem(15);
    font-weight: $font-heading-s-font-weight;
    line-height: rem(18);
  }

  &__cta-container {
    width: fit-content;
    margin: rem(15) auto;
    .card-promotion-small-cta-link {
      width: fit-content;
    }
  }
}