/**
 * Sassy functions to get the rem equivalent of a px value
 * preset-css-env will take care of fallback for older browsers
 * !NOTE: you only need to change the $rem-baseline value if your base size 
 * is different from 10px (use _var_typography.scss in case)
 * @param value is referred to px
 * Credits: Rowan Manning
 */
 @function rem-value($value) {
  $rem-base: 10 !default;

  /* If value is a number, do some magic rem stuff */
  @if type-of($value) == "number" {
    /* Slightly hacky way of removing unit */
    $unitless-value: $value / ($value * 0 + 1);
    /* Return rem value */
    @return #{$unitless-value / $rem-base}rem;
  }
  /* Non-numeric value, just return */
  @return $value;
}

@function rem($values) {
  /* Loop over pixel values to get rem values */
  $rem-values: unquote("");
  @each $value in $values {
    $rem-values: append($rem-values, rem-value($value));
  }

  @return $rem-values;
}

/* ===GETTING VALUE FROM GENERIC MAP=== */
@function map($map, $key) {
  @if not map-has-key($map, $key) {
    @warn "Key `#{$key}` not found in $map map.";
  }
  @return map-get($map, $key);
}

/* ===GET FONT FAMILY NAME FROM NESTED MAP=== */
@function font-family($font, $map: $fonts) {
  @if map-has-key($map, $font) {
    $fontprops: map-get($map, $font);

    @if map-has-key($fontprops, 'family') {
      @return map-get($fontprops, 'family');
    }
    @warn "Unknown family key in $font";
  }
  @warn "Unknown `#{$font}` in $fonts";
}