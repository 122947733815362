.family-showcase-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  @include breakpoint(large) {
    margin: 40px 0 60px 0;
  }
  .title {
    //font-size: rem(28);
    //line-height: rem(36);
    font-family: $font-heading;
    font-size: rem(34) !important;
    line-height: rem(52);
    @include breakpoint(large) {
      font-size: rem(36);
      line-height: rem(52);
    }
    font-weight: bold;
    width: 100%;
    text-align: center;
    font-style: normal;
  }
}

.family-showcase {
  display: flex;
  align-items: center;
  max-width: 100%;
  @include breakpoint(large) {
    max-width: 1110px;
  }

  .command-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50px;
    margin-bottom: -18px;
    .separator {
      width: 1px;
      height: 180px;
      background-color: #ADADAD;
    }
    .icon-arrow {
      font-size: 20px;
      color: $color-primary;
    }
    &--width-zero {
      width: 0;
    }
  }

  .swiper-button-prev {
    transform: translateX(-20px);
  }
  .swiper-button-next {
    transform: translateX(-40px);
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    color: black;
    font-size: 14px;
  }
  @media screen and (max-width: 640px) {
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }

  .swiper-pagination {
    transform: translateY(8px);
    text-align: center;
    visibility: hidden;
  }
  .swiper-pagination-bullet {
    background-color: black;
    margin: 0 2px;
    width: 10px;
    height: 10px;
    &-active {
      background-color: $color-primary;
    }
  }

  .swiper-slide-margin-left {
    margin-left: -200px !important;
  }

  .swiper-slide {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 0 10px 0 0;
    img {
      user-select: none;
      transition: all 100ms linear;
    }
    &:hover {
      .image-cover--label {
        text-decoration: underline;
      }
      img {
        transform: scale(1.1);
      }
    }

    .image-cover {
      &--label {
        width: 100%;
        text-align: center;
        margin-top: 8px;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 15px;
        color: $color-primary;
        text-transform: $font-button-text-m-font-style;
      }
    }

  }
  @media screen and (max-width: 640px) {
    .swiper-slide {
      margin: 0;
    }
  }

  /* mobile */
  .swiper {
    width: 100%;
    height: inherit;
  }
  .swiper-slide img {
    height: 100%;
    width: 100%;
    max-width: none !important;
    object-fit: cover;
    object-position: center;
  }
  /* desktop */
  @media screen and (min-width: 640px) {
    .swiper {
      max-width: 111rem;
      height: inherit;
    }
  }

  .swiper-wrapper {
    width: 100%;
    height: 100%;
    margin: 24px 0;
  }

}
@media screen and (max-width: 640px) {
  .family-showcase {
    width: 100%;
    max-width: 100%;
  }
}

.fs-progress-bar--container {
  width: 100%;
  display: flex;
  justify-content: center;

  .fs-progress-outer-bar {
    width: 148px;
    height: 6px;
    background-color: #C5C5C5;
    position: relative;
    border-radius: 40px;
    .fs-progress-inner-bar {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      height: 6px;
      background-color: $color-primary;
      transition: width 100ms linear;
      border-radius: 40px;
    }
  }

}


