.cards-wrapper.section__container {
    padding: 0 !important;
    height: fit-content;
    @include breakpoint(xlarge){
      padding: 0;
      width: rem(1110);
    }
    &.cards-wrapper-community {
      min-height: rem(350);
    }

  .cards-wrapper-container__one-one-flex, .cards-wrapper-container__one-two-grid,
  .cards-wrapper-container__two-one-grid {
    @include breakpoint(medium) {
      height: rem(720);
    }
  }
  .cards-wrapper-container__one-one-flex, .cards-wrapper-container__two-two-flex,
  .cards-wrapper-container__one-two-grid, .cards-wrapper-container__two-one-grid,
  .cards-wrapper-container__one-one-flex-small {
    &.mgnlOverflow {
      overflow-y: scroll;
    }
    @include breakpoint(xlarge) {
      width: rem(1110);
    }
  }

  .cards-wrapper-container__one-two-grid, .cards-wrapper-container__two-one-grid {
    @include breakpoint(xlarge) {
      max-height: rem(720);
      display: grid;
      grid-template-columns: 50% 50%;
      grid-template-rows: 50% 50%;
    }
    height: fit-content;
    display: flex;
    flex-direction: column;
  }

  .cards-wrapper-container__one-one-flex, .cards-wrapper-container__two-two-flex,
  .cards-wrapper-container__one-one-flex-small {
    display: flex;
    @include breakpoint(medium) {
      flex-direction: row;
      flex-wrap: wrap;
    }
    flex-direction: column;
  }

  .card-big, .card-small {
    @include breakpoint(small only) {
      width: 100%;
      margin: 0 0 rem(30) 0;
      padding: 0;
    }
  }

  //1x1
  .cards-wrapper-container__one-one-flex {
    justify-content: space-between;
    height: fit-content;
    .card-big {
      @include breakpoint(xlarge) {
        width: fit-content;
        margin: 0;
        padding: 0;
      }
    }
  }

  //1x1 small
  .cards-wrapper-container__one-one-flex-small {
    justify-content: space-between;
  }

  //4x4
  .cards-wrapper-container__two-two-flex {
    justify-content: space-between;
    .card-small {
      @include breakpoint(xlarge) {
        display: inline;
        grid-column-start: 1;
        max-width: 50%;
        margin: 0 0 rem(30) 0;
        padding: 0;
      }
    }
  }

  //1x2
  .cards-wrapper-container__one-two-grid {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    .card-big {
      @include breakpoint(xlarge) {
        display: inline;
        grid-column-start: 1;
        margin: 0;
        padding: 0;
      }
    }

    .card-small:nth-child(2) {
      @include breakpoint(xlarge) {
        display: inline;
        grid-column: 2;
        grid-row: 1;
        margin: 0 0 auto auto;
        padding: 0;
      }
    }

    .card-small:nth-child(3) {
      @include breakpoint(xlarge) {
        display: inline;
        grid-column: 2;
        grid-row-start: -2;
        margin: auto 0 0 auto;
        padding: 0;
      }
    }
  }

  //2x1
  .cards-wrapper-container__two-one-grid {
    .card-small:first-child {
      @include breakpoint(xlarge) {
        display: inline;
        grid-row-start: 1;
        margin: 0;
        padding: 0;
      }
    }

    .card-small:nth-child(2) {
      @include breakpoint(xlarge) {
        display: inline;
        grid-column-start: 1;
        margin: auto 0 0 0;
        padding: 0;
      }
    }

    .card-big {
      @include breakpoint(xlarge) {
        display: inline;
        grid-column-start: 2;
        grid-row-start: 1;
        margin: 0 0 0 auto;
        padding: 0;
      }
    }
  }

  .card-big-cta-link.button.button--text.button--medium.button--primary,
  .card-big-cta-link.button.button--text-white.button--medium.button--primary {
    width: auto !important;
  }
  .card-big__title {
    margin-bottom: 8px;
  }
  .card-big__paragraph {
    p {
      line-height: $font-heading-ms-line-height;
    }
  }
  .card-big__cta-links {
    button {
      margin-top: 24px;
    }
    a {
      margin-top: 24px;
    }
  }
};

.card-big__picture .card-big__cta-links .card-big-cta-link, {
  margin-bottom: 0;
}


