.checkbox-container {
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
}
.checkbox-square {
  width: 24px;
  height: 24px;
  border: solid 2px $color-primary;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-background;
}
.checkbox-check {
  color: $color-primary;
}
.checkbox-label {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
