.editorial-cover {

  @include aspect-ratio(3, 1, ".editorial-cover__block");

  height: 100vh;

  .editorial-cover__block {
    overflow: hidden;
  }

  a:link:not(.button), a:visited:not(.button) {
    // color: $general-link-color;
    color: inherit;
    text-decoration: underline;
  }

  img {
    height: 100vh;
  }

  @include breakpoint(medium) {
    img {
      height: 100%;
    }
  }

  @include breakpoint(large) {
    height: auto;
  }

  &__content {
    overflow: hidden;
    padding: $editorial-cover-content-padding-mobile;

    @include breakpoint(medium) {
      padding: $editorial-cover-content-padding-medium;
    }

    @include breakpoint(large) {
      padding: $editorial-cover-content-padding;
    }
  }

  &__title {
    @include font-heading-xl;
    color: $hero-heading-title-color;
    position: absolute;
    top: $hero-heading-title-bottom;
    bottom: auto;
    width: 100%;
    text-align: center;
  }

  .button {
    color: $editorial-cover-button-text-color;
  }

  @include breakpoint(large) {
    &__content {
      padding: 0;
    }
  }
}

.editorial-cover > .editorial-cover-wrap {
  top: auto;
  height: auto;
  bottom: 0;

  @include breakpoint(large) {
    @include xy-grid-container($global-width, $padding: 0);
    top: 0;
  }
}

.editorial-cover__container {
  @include xy-grid();
  z-index: 2;
  position: relative;

  &--left {
    @include flex-align(left);
  }

  &--right {
    @include flex-align(right);
  }

  @include breakpoint(large) {
    height: $editorial-cover-container-height;
    margin-top: $editorial-cover-container-margin-top;
  }
}

.editorial-cover__content-bg {
  background: $editorial-cover-content-bg;
  border-radius: $editorial-cover-content-border-radius;
  border-top-left-radius: $editorial-cover-content-border-top-left-radius;
  height: $editorial-cover-content-bg-height;
  position: absolute;
  width: $editorial-cover-content-bg-width-mobile;
  mix-blend-mode: $editorial-cover-content-mix-blend-mode;
  top: $editorial-cover-content-bg-top;
  left: $editorial-cover-content-bg-left-mobile;

  @include breakpoint(large) {
    width: 50%;
    left: 0;

    &--right {
      right: 0;
      left: auto;
    }
  }
}

.editorial-cover__content {
  //background: rgba($editorial-cover-content-bg, .9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: $editorial-cover-content-color;
  width: 100%;

  @if $editorial-cover-content-title-after {
  
    .editorial__title {
      position: relative;
      padding-bottom: rem(10);

      &:after {
        content: '';
        height: rem(2);
        background: $color-primary;
        position: absolute;
        width: 6%;
        left: 0;
        bottom: rem(5);
      }
    }
  }

  @include breakpoint(small) {
    .editorial__title {
      padding-top: 0;
    }
  }

  @include breakpoint(large) {
    @include xy-cell(6, $gutters: 80px, $gutter-type: padding)
  }
}

// PIAGGIO-8774: content visibility with zoom 400%
@media screen and (max-width: 320px) {
  .editorial-cover {
    height: auto;
    &:before {
      padding: 0;
    }
    &__block {
      position: relative !important;
    }
  }
}
