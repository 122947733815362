////////////////////////////////////////
// Footer
.footer {
  background-color: $footer-background;
  color: $footer-primary-text-color;
  line-height: 1.5;
  padding-top: 2rem;
  position: relative;
  z-index: 1;

  &.footer-custom-community {
    padding-top: 0 !important;
  }

  a,
  button {
    text-decoration: none;
    color: $footer-primary-text-color;
  }

  li {
    line-height: 1.5;
  }

  *:focus {
    outline-color: $footer-outline-color;
  }
}

////////////////////////////////////////
// Footer logo
.footer__logo {
  margin: 0 auto;
  display: block;
  max-width: 160px;
  aspect-ratio: auto 160 / 62;
}

////////////////////////////////////////
// Footer main
.footer__main {
  padding: 1.25rem 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  flex-direction: column;

  > span {
    display: contents;
  }

  @include breakpoint(large) {
    flex-direction: row;
  }
}

.footer__main-item > div > div {
  @include font-body-s;
  padding: 1.25rem 0;
  min-width: 12.5rem;
  border-bottom: 1px solid $white;
  &.font-size-ms {
    font-size: rem(14);
  }
  @include breakpoint(large) {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    border-bottom: none;
  }
}

.footer__main-item:last-child div:last-child {
  border: 0;
}

////////////////////////////////////////
// Footer legal
.footer__legal {
  @include font-body-s;
  @include application-font($font-body-s-font-family, 600);
  background-color: $footer-legal-background;
  padding: 1.2rem 3rem;
  text-align: center;
}

////////////////////////////////////////
// Footer social
.footer__social {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 1rem 2rem;
}

.ft-social-list {
  display: flex;

  a {
    display: inline-flex;
    width: 40px;
    height: 40px;
    align-items: center;
    font-size: 2rem;
    justify-content: center;
  }

  li {
    margin: 0.125rem 0;
    white-space: nowrap;
    font-size: rem(16);
    line-height: 1.5;
    display: inline-flex;
    align-items: center;
  }
}
